<template lang="pug">
div
  section.batch-options.panel.panel-default(v-if="storyBatchOptionsStore.anySelectedStories")
    .panel-body
      .row
        .col-md-3
          .form-group
            label Pub date
            DateField(
              input-class="form-control"
              :disabled-dates="{to: new Date()}"
              placeholder="Don't Change"
              v-model="storyBatchOptionsStore.pubDate"
            )

        .col-md-3
          .form-group
            label Owner
            SelectInput(
              v-model="storyBatchOptionsStore.userId"
              blank-option-name="Don't Change"
              :options="userOptions"
            )

        .col-md-2
          .form-group
            label Print section
            SelectInput(
              v-model="storyBatchOptionsStore.sectionId"
              blank-option-name="Don't Change"
              :options="sectionOptions"
            )

        .col-md-2
          .form-group
            label Status
            SelectInput(
              v-model="storyBatchOptionsStore.squeueId"
              blank-option-name="Don't Change"
              :options="squeueOptions"
            )

        .col-md-2
          .form-group
            label Web category
            SelectInput(
              v-model="storyBatchOptionsStore.webCategoryId"
              blank-option-name="Don't Change"
              :options="webCategoriesStore.allAsObject"
            )

      .row
        .col-md-12.text-right
          a.btn.btn-default(:href="downloadLink")
            i.fa.fa-cloud-download
            |
            | Download
          |
          |
          a.btn.btn-default(v-on:click="openCommunityQPublishModal()")
            i.fa.fa-cloud-upload
            |
            | Export to Web
          |
          |
          button.btn.btn-primary(
            type="button"
            :disabled="!storyBatchOptionsStore.anyChanges"
            v-on:click="submit()"
            v-text="buttonTitle('Update')"
          )
</template>

<script>
  import DateField from '../../shared/date_field.vue'
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import { mapStores } from 'pinia'
  import { useCommunityqPublishingStore } from '../../../stores/communityq_publishing'
  import { useWebCategoriesStore } from '../../../stores/web_categories'
  import { useStoryBatchOptionsStore } from '../../../stores/story_batch_options'
  import SelectInput from '../../shared/select_input'

  export default {
    components: {
      DateField,
      SelectInput
    },

    computed: {
      ...mapGetters('users', { userOptions: 'allAsObject' }),
      ...mapGetters('sections', { sectionOptions: 'enabledAsObject' }),
      ...mapGetters('squeues', { squeueOptions: 'enabledAsObject' }),
      ...mapStores(useCommunityqPublishingStore, useWebCategoriesStore, useStoryBatchOptionsStore),
      downloadLink() {
        return `/stories.zip?${jQuery.param({ids: this.storyBatchOptionsStore.selectedStoryIds})}`
      }
    },

    mounted() {
      const { dispatch } = this.$store;

      dispatch('users/fetchOnce');
      dispatch('sections/fetchOnce');
      dispatch('squeues/fetchOnce');
      this.webCategoriesStore.fetchOnce()
    },

    methods: {
      buttonTitle(action) {
        switch (this.storyBatchOptionsStore.selectedStoriesCount) {
          case 1: return `${action} Story`;
          case 2: return `${action} Both Stories`;
          default: return `${action} All ${this.storyBatchOptionsStore.selectedStoriesCount} Stories`;
        }
      },

      openCommunityQPublishModal() {
        this.communityqPublishingStore.storyIds = this.storyBatchOptionsStore.selectedStoryIds
      },

      submit() {
        if (!this.storyBatchOptionsStore.anyChanges) {
          alert('Please select a change to at least one field');
        } else {
          const data = { ids: this.storyBatchOptionsStore.selectedStoryIds, story: this.storyBatchOptionsStore.storyData };

          axios.patch('/api/internal/stories/batch_update', data).then(() => {
            this.$emit('change');
          }).catch(err => {
            this.$store.dispatch('messages/addError', err);
          })
        }
      }
    }
  }
</script>
