import axios from 'axios'
import { make } from 'vuex-pathify'


// initial state
// NOTE: on the backend, a Story can have many PullQuotes, but for simplicity,
// and since having more than one is an edge-case, a Story can only have one
// PullQuote on the frontend for now.
const getDefaultState = () => {
  return {
    id: null,
    headline: '',
    quote: '',
    attribution: ''
  }
}

const state = getDefaultState()

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state),

  SET(state, pullQuote) {
    Object.assign(state, pullQuote)
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  ...make.actions(state),

  async fetchForStory({ commit, dispatch }, { storyId }) {
    return axios
      .get(`/api/internal/stories/${storyId}/pull_quotes`)
      .then(response => {
        const pullQuote = response.data.pull_quotes[0]

        if(pullQuote) {
          commit('SET', pullQuote)
        }
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
