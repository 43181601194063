<template lang="pug">
.btn-group.saved-searches(v-if="anySavedSearches")
  button.btn.btn-prominent.btn-sm(type="submit")
    i.fa.fa-search
    |
    | Search
  button.btn.btn-prominent.btn-sm.dropdown-toggle(
    type="button"
    data-toggle="dropdown"
  )
    i.fa.fa-caret-down
  ul.dropdown-menu
    li.dropdown-header(v-if="storySavedSearchesStore.storySavedSearches.length > 0") Saved Searches
    li(v-for="search in storySavedSearchesStore.storySavedSearches")
      a(:href="search.query") {{ search.title }}
    li.text-right(v-if="storySavedSearchesStore.storySavedSearches.length > 0")
      a(:href="managePath")
        small
          i.fa.fa-cog.text-muted
          |
          |
          span.text-muted manage
    template(v-if="anyQueryConditions")
      li.divider
      li
        .navbar-form
          .input-group
            input.form-control.input-sm(
              placeholder="Save this search as"
              v-model="newSearchName"
              v-on:keydown.enter.stop.prevent="saveNewSearch()"
            )
            span.input-group-btn
              button.btn.btn-default.btn-sm(
                type="button"
                v-on:click.stop.prevent="saveNewSearch()"
              )
                i.fa.fa-plus

button.btn.btn-prominent.btn-sm(type="submit" v-else="")
  i.fa.fa-search
  |
  | Search
</template>

<script>
  import { mapStores } from 'pinia'
  import { useStorySavedSearchesStore } from '../../../stores/story_saved_searches'

  export default {
    props: {
      managePath: {
        type: String,
        default: '/story_saved_searches'
      }
    },

    data() {
      return {
        newSearchName: ''
      }
    },

    mounted() {
      this.storySavedSearchesStore.fetch()
    },

    computed: {
      ...mapStores(useStorySavedSearchesStore),

      anySavedSearches() {
        return this.storySavedSearchesStore.storySavedSearches.length > 0 || this.anyQueryConditions
      },

      anyQueryConditions() {
        return this.$store.getters['storySearch/anyQueries'];
      }
    },

    methods: {
      saveNewSearch() {
        this.storySavedSearchesStore.create(this.newSearchName).then(() => {
          this.resetForm()
        })
      },

      resetForm() {
        this.newSearchName = '';
      }
    }
  }
</script>
