import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useEmailExportingStore = defineStore('emailExporting', {
  state: () => ({
    storyId: null,
    to: null,
    subject: null,
    notes: null,
  }),

  actions: {
    async send() {
      const { to, subject, notes } = this

      return axios.post('/api/internal/emails', {
        email_export: {
          story_id: this.storyId,
          to,
          subject,
          notes,
        }
      }).then(({ data }) => {
        store.dispatch('messages/addNotice', data.message)
        this.$reset()

        return data
      }).catch(err => {
        store.dispatch('messages/addError', err)
      })
    }
  }
})
