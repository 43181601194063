<template lang="pug">
LoaderOverlay(:loading="loading")
  IssueForm
</template>

<script>
  import IssueForm from '../shared/form.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'

  export default {
    components: {
      IssueForm,
      LoaderOverlay
    },

    props: {
      issueId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        loading: true
      }
    },

    async mounted() {
      const { issueId } = this

      await this.$store.commit('issue/RESET')
      await this.$store.dispatch('issue/fetch', { issueId })
      this.loading = false
    }
  }
</script>
