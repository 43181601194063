<template lang="pug">
div
  h1 Saved Searches

  template(v-if="storySavedSearchesStore.storySavedSearches.length == 0")
    .alert.alert-info There aren't any saved searches yet
  template(v-else)
    Table

  hr

  p.text-center.text-muted
    a(href="/stories") Search for stories
    |
    | and click the
    |
    span.btn.btn-info.btn-xs
      i.fa.fa-search
      |
      | Search
      |
      i.fa.fa-caret-down
    |
    | dropdown button to save new searches
</template>

<script>
  import { mapStores } from 'pinia'
  import { useStorySavedSearchesStore } from '../../stores/story_saved_searches'

  import Table from '../../components/story_saved_searches/table.vue'

  export default {
    components: {
      Table
    },

    computed: {
      ...mapStores(useStorySavedSearchesStore),
    },

    created() {
      this.storySavedSearchesStore.fetch()
    }
  }
</script>
