<template lang="pug">
time(:datetime="formattedDate") {{ formattedDate }}
</template>

<script>
  import { toDateInLocalTZ, formatDate } from '../../utils/time_helpers'

  export default {
    props: {
      value: {
        default: null
      }
    },

    computed: {
      formattedDate() {
        const date = toDateInLocalTZ(this.value)

        return formatDate(date)
      }
    }
  }
</script>
