<template lang="pug">
.placements
  Dropdown(
    v-for="{ id, placement } in placementObjects"
    :key="id"
    :value="placement"
    @input="updatePlacement"
    :opened="openedId === id"
    @opened="openedId = ($event ? id : false)"
  )
  .buttons.text-right
    button.btn.btn-link(
      type="button"
      @click="addPlacement"
      :disabled="disabled"
    )
      i.fa.fa-plus
      |
      | Add issue
</template>

<script>
  import { sync } from 'vuex-pathify'
  import Dropdown from './dropdown.vue'

  export default {
    components: {
      Dropdown
    },

    props: {
      storyId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        openedId: false
      }
    },

    computed: {
      placements: sync('storyPlacements/storyPlacements'),

      placementObjects() {
        return this.placements.map((placement) => {
          return { id: placement.id, placement }
        })
      },

      disabled() {
        return this.$store.getters['storyPlacements/includesBlank']
      }
    },

    async mounted() {
      const { storyId } = this

      await this.$store.dispatch('publications/fetchOnce')
      await this.$store.dispatch('issues/fetchOnce')

      if(storyId) {
        await this.$store.dispatch('storyPlacements/fetch', { storyId })
      } else {
        this.$store.commit('storyPlacements/RESET')
      }
    },

    methods: {
      updatePlacement(event) {
        this.$store.dispatch(
          'storyPlacements/updateStoryPlacement',
          event
        )
      },

      async addPlacement() {
        const { storyId } = this
        await this.$store.commit('storyPlacements/ADD_BLANK', { storyId })

        // The new placement always has a null ID initially. And we currently only allow
        // one unsaved placement at a time (that's something to improve later, for sure).
        this.openedId = null
      }
    }
  }
</script>
