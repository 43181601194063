<template lang="pug">
.row
  .col-md-2

  .col-md-8
    h1 Edit {{squeue.name}}

    LoaderOverlay(:loading="loading")
      Form(:squeue="squeue")

  .col-md-2
</template>

<script>
  import { sync } from 'vuex-pathify'

  import Form from '../shared/form.vue'
  import LoaderOverlay from '../../../../components/shared/loader_overlay.vue'

  export default {
    components: {
      Form,
      LoaderOverlay
    },

    props: {
      squeueId: {
        type: Number,
        required: true
      }
    },

    computed: {
      squeue: sync('squeue/squeue')
    },

    data() {
      return {
        loading: true
      }
    },

    async mounted() {
      this.loading = true

      const { squeueId } = this
      await this.$store.dispatch('squeue/fetch', { squeueId })

      this.loading = false
    }
  }
</script>
