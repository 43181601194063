import axios from 'axios';

const base64UrlMatcher = /^data:[^;]*;base64,.+/;

export default {
  props: {
    contentFor: {
      type: String
    },
    value: {
      type: String
    }
  },

  data() {
    return {
      useFileContent: false,
      fileContent: "",
      // This wrapper around the content prop allows us to mutate the field
      // value externally without getting warnings
      inputValue: ''
    };
  },

  mounted() {
    this.inputValue = this.value;
  },

  computed: {
    storyMedium() {
      return this.$store.getters["storyMedia/byContentFor"](this.contentFor);
    },

    filename() {
      if(this.storyMedium && this.storyMedium.medium) {
        return this.storyMedium.medium.filename;
      }
    },

    perFieldFileMode() {
      return this.$store.getters["story/perFieldFileMode"];
    },

    readOnly() {
      return (this.useFileContent || !this.perFieldFileMode);
    },

    editHereActive() {
      return !this.useFileContent;
    },

    editFileActive() {
      return this.useFileContent;
    }
  },

  watch: {
    useFileContent(useFile) {
      if(this.storyMedium) {
        const key = this.contentFor;
        const storyMedium = Object.assign(
          {},
          this.storyMedium,
          {
            _destroy: !useFile,
            use_as_content_for: (useFile ? key : null)
          }
        );

        this.$store.commit("storyMedia/UPDATE", { key, storyMedium });
      } else if(!useFile) {
        this.fileContent = ""
      }
    },

    storyMedium(storyMedium) {
      // TODO: this should probably be moved to an action

      // *************************************************
      // TODO: figure out why going from edit file to edit
      // here back to edit file seems to delete the StoryMedia
      // *************************************************
      if (!storyMedium) {
        this.fileContent = "";
      } else {
        const medium = storyMedium.medium;
        let postParams = new FormData();

        postParams.append("filename", medium.filename);

        if (base64UrlMatcher.test(medium.url)) {
          postParams.append("base64_url", medium.url);
        } else if (medium.url) {
          postParams.append("path", medium.url);
        } else {
          postParams.append("file", medium.file);
        }

        // TODO: generate this URL from a rails helper, if possible
        axios
          .post("/api/internal/content_file", postParams)
          .then(({ data }) => {
            this.contentFileSuccessHandler(data);
          })
          .catch(err => {
            this.$store.dispatch("messages/addError", err);
          });
      }
    },

    value(val) {
      this.inputValue = val === null ? "" : val;
    },

    inputValue() {
      // If it's read-only and we shouldn't emit any content changes.
      if (!this.readOnly) {
        // For component v-model support
        this.$emit("input", this.inputValue)
      }
    }
  },

  methods: {
    editHere() {
      this.useFileContent = false;

      // If we had a file for the content, but we're choosing to no longer use it, then delete it.
      // This will actually leave the file, just to be safe, but will delete the DB record tying it to this story and field.
      if(this.storyMedium?.id) {
        this.$store.dispatch(
          'storyMedia/destroy',
          { storyMediumId: this.storyMedium.id }
        )
      }
    },

    editFile() {
      this.useFileContent = true;
    },

    fileChosen(event) {
      // TODO: add or replace
      const input = event.target;

      if (input.files && input.files[0]) {
        this.$store.dispatch(
          'storyMedia/create',
          { file: input.files[0], useAsContentFor: this.contentFor }
        )
      }
    }
  }
};
