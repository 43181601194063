<template lang="pug">
li.list-group-item(:class="styles")
  Checkbox.right-check(v-model="checked")
  i.fa.fa-file-o
  |
  | {{ page.title }}
</template>

<script>
  import Checkbox from '../../../shared/checkbox.vue'

  export default {
    components: {
      Checkbox
    },

    props: {
      value: {
        type: Object, // placement object
        required: true
      },
      page: {
        type: Object,
        required: true
      }
    },

    computed: {
      placement() { return this.value },
      styles() { return { active: this.checked } },
      checked: {
        get() { return this.page.id === this.placement.page_id },
        set(val) {
          this.$emit(
            'input',
            {
              ...this.placement,
              page_id: (val ? this.page.id : null)
            }
          )
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list-group-item {
    padding-left: 2em;
  }

  .right-check {
    float: right;
    cursor: pointer;
  }
</style>
