<template lang="pug">
label.btn.btn-info.btn-file(v-on:change="changeHandler")
  i.fa.fa-upload
  input(type="file" multiple)
  |
  | Upload
</template>

<script>
  export default {
    methods: {
      changeHandler(event) {
        const files = Array.from(event.target.files)

        this.$emit('change', files)
      }
    }
  }
</script>
