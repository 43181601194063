<template lang="pug">
a.btn.btn-default(rel="nofollow" :disabled="loading" @click="click")
  template(v-if="loading")
    Loader
    br
  template(v-if="!loading")
    i.fa.fa-trash.text-muted
</template>

<script>
  import Loader from './loader.vue'

  export default {
    components: {
      Loader
    },

    props: {
      loading: false
    },

    methods: {
      click() {
        if(!confirm('Are you sure you want to delete this?')) return

        this.$emit('click')
      }
    }
  }
</script>
