<template lang="pug">
.form-group
  label.control-label Web categories
  div
    DropdownCheckboxes.block(label="Web categories" v-model="webCategoryIds" :options="webCategoriesStore.allAsObject")
</template>

<script>
  import { mapStores } from 'pinia'
  import { useWebCategoriesStore } from '../../../stores/web_categories'
  import DropdownCheckboxes from '../../dropdown_checkboxes.vue'

  export default {
    components: {
      DropdownCheckboxes
    },

    props: {
      // Array of values from options
      // NOTE: be careful that the values match the options values. Number strings are a common issue.
      value: {
        type: Array,
        default() { return [] }
      }
    },

    data() {
      return {
        webCategoryIds: []
      }
    },

    watch: {
      value() {
        this.webCategoryIds = this.value
      },

      webCategoryIds() {
        // For component v-model support
        this.$emit('input', this.webCategoryIds);
      }
    },

    computed: {
      ...mapStores(useWebCategoriesStore)
    },

    async mounted() {
      await this.webCategoriesStore.fetchOnce()
    }
  }
</script>
