import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  components: {
    draggable,
  },

  props: {
    taxonomyName: {
      required: true,
      default: null // must be set in the component
    }
  },

  data() {
    return {
      taxonomies: []
    }
  },

  created() {
    this.loadAll()
  },

  methods: {
    checkboxClass(bool) {
      if(bool) {
        return 'fa fa-check-square-o'
      } else {
        return 'fa fa-square-o'
      }
    },

    loadAll() {
      axios.get(`/api/internal/${this.taxonomyName}`).then(({ data }) => {
        this.taxonomies = data[this.taxonomyName]
      }).catch(err => {
        this.$store.dispatch('messages/addError', err)
      })
    },

    save() {
      axios.put(`/api/internal/${this.taxonomyName}/reorder`, {
        ordered_ids: this.taxonomies.map(s => s.id)
      }).then(({ data }) => {
        this.$store.dispatch('messages/addNotice', data.message)
      }).catch(err => {
        this.$store.dispatch('messages/addError', err)
      })
    }
  }
}
