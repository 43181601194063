import { stateFingerprint } from '../../../../utils/state_helpers'
import TextInput from '../../../../components/shared/text_input.vue'
import Loader from '../../../../components/shared/loader.vue'
import TextareaInput from '../../../../components/shared/textarea_input.vue'
import TextOrCharacterInput from '../shared/text_or_character_input.vue'

export default {
  components: {
    TextInput,
    Loader,
    TextareaInput,
    TextOrCharacterInput
  },

  props: {
    rule: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      fingerprint: null,
      saving: false,
      deleting: false
    }
  },

  computed: {
    isDirty() {
      return this.fingerprint !== stateFingerprint(this.rule)
    }
  },

  mounted() {
    this.updateFingerprint()
  },

  methods: {
    async save() {
      const transform_rule = this.rule

      this.saving = true

      this.$store.dispatch('transform/saveRule', { transform_rule }).then(() => {
        this.saving = false
        this.updateFingerprint()
      })
      .catch(err => {
        this.saving = false
        console.error(err)
        this.$store.dispatch('messages/smartAdd', err, { root: true })
      })
    },

    async destroy() {
      const { id } = this.rule

      this.$store.dispatch('transform/destroyRule', { id })
    },

    async updateFingerprint() {
      this.fingerprint = stateFingerprint(this.rule)
    }
  }
}
