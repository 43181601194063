import Vue from 'vue';
import Vuex from 'vuex';
import ActionCableVue from 'actioncable-vue';
import Pathify from './pathify';

import issue from './modules/issue.js';
import issues from './modules/issues.js';
import media from './modules/media.js';
import medium from './modules/medium.js';
import messages from './modules/messages.js';
import page from './modules/page.js';
import pages from './modules/pages.js';
import publications from './modules/publications.js';
import pullQuote from './modules/pull_quote.js';
import sections from './modules/sections.js';
import settings from './modules/settings.js';
import squeue from './modules/squeue.js';
import squeues from './modules/squeues.js';
import story from './modules/story.js';
import stories from './modules/stories.js';
import storyMedia from './modules/story_media.js';
import storyPlacements from './modules/story_placements.js';
import storyPublications from './modules/story_publications.js';
import storySearch from './modules/story_search.js';
import styleMaps from './modules/style_maps';
import styleMappings from './modules/style_mappings';
import subsites from './modules/subsites';
import transform from './modules/transform';
import transforms from './modules/transforms';
import users from './modules/users.js';

Vue.use(Vuex);

Vue.use(
  ActionCableVue,
  {
    debug: true,
    debugLevel: 'error',
    connectionUrl: '/cable',
    connectImmediately: true,
  }
);

export default new Vuex.Store({
  plugins: [ Pathify.plugin ],
  modules: {
    issue,
    issues,
    media,
    medium,
    messages,
    page,
    pages,
    publications,
    pullQuote,
    sections,
    settings,
    squeue,
    squeues,
    story,
    stories,
    storyMedia,
    storyPublications,
    storyPlacements,
    storySearch,
    styleMaps,
    styleMappings,
    subsites,
    transform,
    transforms,
    users
  }
});
