<template lang="pug">
.grid
  header
    .pull-right
      NewStoryButton

    h1 Stories

  CommunityQPublishModal
  StoryEmailModal

  LoaderOverlay(:loading="loading")
    form(@submit.prevent="search()")
      StoryFilterBar(v-if="!currentUserStore.currentUser.role_is_limited")

      StoryFileDrop
        .alert.alert-info(v-if="stories.length === 0") No stories were found
        StoriesTable(v-else :columns="COLUMNS" @sort="sort()" @should-refresh="search()")

      StoryBatchOptions(
        @change="reload"
      )

      nav.text-center
        PaginationDetails(
          :current="page.current"
          :per="page.per"
          :total-records="page.total_records"
        )

        Pagination(
          :current="page.current"
          :per="page.per"
          :total-records="page.total_records"
          @change="changePage"
        )

        PerPageSelect(:value="page.per" @input="changeDefaultPerPage")
</template>

<script>
  import { pick, isEqual } from 'lodash'
  import { sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import { mapStores } from 'pinia'
  import { useCurrentUserStore } from '../../../stores/current_user'
  import { useExportFormatsStore } from '../../../stores/export_formats'
  import { useStoryBatchOptionsStore } from '../../../stores/story_batch_options'

  import CommunityQPublishModal from '../../../components/community_q/publish_modal.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import NewStoryButton from '../../../components/stories/index/new_story_button.vue'
  import Pagination from '../../../components/shared/pagination.vue'
  import PaginationDetails from '../../../components/shared/pagination_details.vue'
  import PerPageSelect from '../../../components/shared/per_page_select.vue'
  import StoriesTable from '../../../components/stories/index/stories_table.vue'
  import StoryBatchOptions from '../../../components/stories/index/story_batch_options.vue'
  import StoryEmailModal from '../../../components/stories/email_modal.vue'
  import StoryFileDrop from '../../../components/stories/index/story_file_drop.vue'
  import StoryFilterBar from '../../../components/stories/index/story_filter_bar.vue'

  import { COLUMNS } from '../../../components/stories/index/stories_table.vue'

  const CHANNEL = 'StoriesChannel'

  export default {
    components: {
      CommunityQPublishModal,
      LoaderOverlay,
      NewStoryButton,
      Pagination,
      PaginationDetails,
      PerPageSelect,
      StoriesTable,
      StoryBatchOptions,
      StoryEmailModal,
      StoryFileDrop,
      StoryFilterBar
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      ...mapGetters('stories', { story: 'byId' }),
      page: sync('stories/page'),
      stories: sync('stories/stories'),
      ...mapStores(
        useCurrentUserStore,
        useExportFormatsStore,
        useStoryBatchOptionsStore
      )
    },

    channels: {
      [CHANNEL]: {
        async received(data) {
          const { action, story } = data

          // update is currently the only action on this channel, but for future-proofing we'll check
          if(!action === 'update') return

          const stateStory = this.story(story.id)

          // If this is just some story we're not tracking, just ignore it
          if (!stateStory) return

          const changed = !isEqual(pick(stateStory, COLUMNS), pick(story, COLUMNS))

          await this.$store.dispatch('stories/updateStory', { story })

          if (changed) {
            this.$store.dispatch('messages/addInfo', `Story ${story.slug} updated`)
          }
        }
      }
    },

    async created() {
      this.COLUMNS = COLUMNS
    },

    async mounted() {
      // Load in the initial state from the query string
      await this.$store.dispatch('storySearch/parseOrLoad');
      await this.exportFormatsStore.fetchOnce()

      await this.fetchPage({});

      this.$cable.subscribe({ channel: CHANNEL })

      this.loading = false
    },

    methods: {
      search() {
        this.$store.dispatch('stories/search');
      },

      reload() {
        this.storyBatchOptionsStore.uncheckAll()
        this.$store.dispatch('stories/reload');
      },

      sort() {
        this.$store.dispatch('stories/search');
      },

      changePage(page) {
        this.fetchPage({ page, perPage: this.page.per });
      },

      changeDefaultPerPage(newDefaultPerPage) {
        this.fetchPage({ page: this.page.current, newDefaultPerPage});
      },

      async fetchPage({ page, perPage, newDefaultPerPage }) {
        await this.$store.dispatch('stories/searchPage', { page, perPage, newDefaultPerPage });
      }
    }
  }
</script>
