<template lang="pug">
  table.table.table-striped
    thead
      tr
        th Title
        th Address
        th City
        th State
        th Zip
        th Actions
    tbody
      Row(
        v-for="location in locations"
        :key="location.id"
        :location="location"
      )
</template>

<script>
  import Row from './row.vue'

  export default {
    components: {
      Row
    },

    props: {
      locations: {
        type: Array,
        required: true
      }
    }
  }
</script>
