<template lang="pug">
div
  template(v-if="singleFileMode")
    .input-group
      input.form-control(type="text" v-model="inputValue" :readonly="readOnlyOrSingleFileMode" :class="inputClass || ''")
      span.input-group-addon(ref="helpButton" data-toggle="tooltip" data-placement="top" title="Edit the content file linked to this story to change this field")
        i.fa.fa-question
  template(v-else)
    input.form-control(type="text" v-model="inputValue" :readonly="readOnlyOrSingleFileMode" :class="inputClass || ''")
</template>

<script>
  export default {
    props: {
      value: {
        type: String
      },
      inputClass: {
        type: String
      },
      readOnly: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        // This wrapper around the content prop allows us to mutate the field
        // value externally without getting warnings
        inputValue: ''
      }
    },

    mounted() {
      this.inputValue = this.value
    },

    updated() {
      if(this.$refs.helpButton) {
        jQuery(this.$refs.helpButton).tooltip()
      }
    },

    computed: {
      readOnlyOrSingleFileMode() {
        return (this.readOnly || this.singleFileMode)
      },

      singleFileMode() {
        return this.$store.getters['story/singleFileMode']
      }
    },

    watch: {
      value() {
        this.inputValue = this.value
      },

      inputValue() {
        // For component v-model support
        this.$emit('input', this.inputValue);
      }
    }
  }
</script>
