<template lang="pug">
div
  template(v-if="edit")
    MediumForm(@save="closeEditor")

  template(v-else="")
    LoaderOverlay(:loading="loading")
      figure(v-if="isImage")
        a(href="#" data-toggle="modal" data-target="#medium-expanded-modal")
          ImageLoader(imgClass="img-responsive" :src="url")
          i.expand.fa.fa-expand.fa-2x

      dl
        dt Filename
        dd.filename {{ mediaFileName }}

        dt Folder
        dd.filepath {{ filePath }}

        dt Status
        dd {{ squeueTitle }}

        dt Print Section
        dd {{ sectionTitle }}

        dt Cutline
        dd(v-html="cutline")

        dt Credit
        dd {{ credit }}

        dt Created
        dd
          DateValue(:value="createdAt")

        dt(v-if="stories.length > 0") Stories
        template(v-for="story in stories")
          dd
            a(:href="story.url")
              i.fa.fa-newspaper-o
              |
              | {{ story.slug }}

      .buttons.text-right
        .btn-group
          a.btn.btn-default(
            :href="downloadUrl"
            ref="downloadButton"
            data-toggle="tooltip"
            data-container="body"
            title="Download"
          )
            i.fa.fa-cloud-download
          button.btn.btn-default.dropdown-toggle(
            type="button"
            data-toggle="dropdown"
            v-if="exportFormatsStore.forMedia.length > 0"
          )
            i.fa.fa-caret-down
          ul.dropdown-menu(v-if="exportFormatsStore.forMedia.length > 0")
            li.dropdown-header Export as...
            li(v-for="exportFormat in exportFormatsStore.forMedia")
              a(:href="`/media/${id}/export_formats/${exportFormat.id}`" target="new")
                | {{ exportFormat.name }}
        |
        |
        button.btn.btn-default(type="button" @click="edit = true")
          i.fa.fa-pencil
          |
          | Edit
        |
        |
        button.btn.btn-danger(type="button" @click="destroy()")
          i.fa.fa-trash

    #medium-expanded-modal.modal.fade(tabindex="-1" v-if="isImage")
      .modal-dialog.modal-lg
        .modal-content
          img.img-responsive.img-thumbnail(:src="url" style="min-width: 100%")
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'
  import { mapStores } from 'pinia'
  import { mediaFileName, filePath } from '../../../utils/file_helpers'
  import { useExportFormatsStore } from '../../../stores/export_formats.js'

  import DateValue from '../../../components/shared/date_value.vue'
  import ImageLoader from '../../../components/shared/image_loader.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import MediumForm from './form.vue'

  export default {
    components: {
      DateValue,
      ImageLoader,
      LoaderOverlay,
      MediumForm
    },

    data() {
      return {
        edit: false,
        loading: false
      }
    },

    computed: {
      ...mapGetters('medium', [
        'isImage'
      ]),
      ...mapStores(useExportFormatsStore),
      ...sync('medium', [
        'id',
        'url',
        'cutline',
        'credit',
        'contentType',
        'filename',
        'relative_path',
        'squeueTitle',
        'sectionTitle',
        'createdAt',
        'stories'
      ]),

      downloadUrl() { return `/media/${this.id}` },

      mediaFileName() {
        return mediaFileName(this.url)
      },

      filePath() {
        return filePath(this.url)
      }
    },

    async mounted() {
      await this.exportFormatsStore.fetchOnce()
      this.registerTooltips()
    },

    async updated() {
      this.registerTooltips()
    },

    methods: {
      registerTooltips() {
        if(this.$refs.downloadButton) { jQuery(this.$refs.downloadButton).tooltip() }
      },

      closeEditor() {
        this.edit = false
      },

      destroy() {
        if (confirm(`Sure you want to delete ${this.filename}?`)) {
          this.loading = true

          this.$store.dispatch('medium/destroy', { id: this.id }).then(() => {
            this.closeEditor()
            this.loading = false
          })
        }
      }
    }
  }
</script>
