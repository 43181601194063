<template lang="pug">
tr
  td.original
    TextInput(v-model="rule.original")
  td.replacement
    TextOrCharacterInput(v-model="rule.replacement_start" :rows="1")
  td.actions
    button.btn.btn-success(v-if="isDirty" @click="save()")
      Loader(v-if="saving" :stacked="false")
      i.fa.fa-save(v-if="!saving")
    button.btn.btn-danger(v-if="!isDirty" @click="destroy()")
      Loader(v-if="deleting" :stacked="false")
      i.fa.fa-trash(v-if="!deleting")
</template>

<script>
  import TransformRuleRow from '../mixins/transform_rule_row'

  export default {
    mixins: [TransformRuleRow]
  }
</script>
