import axios from 'axios'
import { make } from 'vuex-pathify'
import { toString } from 'lodash'
import { camelKeyed } from '../../utils/params_helpers.js'
import { exifMediumContent } from '../../utils/exif_helpers.js'

// helper functions
const buildFormData = ({ file, squeueId, sectionId, credit, cutline }) => {
  let formData = new FormData()

  if (file) formData.append('medium[upload]', file)
  formData.append('medium[squeue_id]', toString(squeueId))
  formData.append('medium[section_id]', toString(sectionId))
  formData.append('medium[credit]', toString(credit))
  formData.append('medium[cutline]', toString(cutline))

  return formData
}

const DEFAULT_STATE = {
  id: null,
  url: null,
  smallThumbUrl: null,
  largeThumbUrl: null,
  contentType: null,
  file: null,
  filename: null,
  imageBase64: null,
  relativePath: null,
  squeueId: null,
  squeueTitle: null,
  sectionId: null,
  sectionTitle: null,
  cutline: '',
  credit: '',
  createdAt: null,
  stories: []
}

// initial state
const state = { ...DEFAULT_STATE }

// getters
const getters = {
  ...make.getters(state),

  isImage({ contentType }) {
    if(!contentType) return false

    return contentType.split('/')[0] === 'image'
  },

  bestUrl({ url, imageBase64 }, getters) {
    if (!getters['isImage']) return null

    return imageBase64 || url
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  REPLACE(state, newState) {
    Object.assign(state, { ...newState })
  },

  RESET(state) {
    Object.assign(state, { ...DEFAULT_STATE })
  }
}

// actions
const actions = {
  ...make.actions(state),

  processFile({ commit, getters }, { file }) {
    commit('SET_FILE', file)
    commit('SET_FILENAME', file.name)
    commit('SET_CONTENT_TYPE', file.type)
    if(getters['isImage']) commit('SET_IMAGE_BASE64', URL.createObjectURL(file))

    return exifMediumContent(file).then(({ cutline, credit }) => {
      if(cutline) commit('SET_CUTLINE', cutline)
      if(credit) commit('SET_CREDIT', credit)
    })
  },

  reset({ commit }) {
    commit('RESET')
  },

  pullSoleFromMedia({ commit, rootGetters }) {
    const medium = rootGetters['media/soleSelected']

    // Nothing to pull in
    if(!medium) return

    commit('REPLACE', camelKeyed(medium))
  },

  async create({ dispatch }, { file, squeueId, sectionId, credit, cutline }) {
    const formData = buildFormData({ file, squeueId, sectionId, credit, cutline })

    return axios.post('/api/internal/media', formData).then(() => {
      dispatch('media/search', { page: 1 }, { root: true })
      dispatch('medium/reset', null, { root: true })
    }).catch(err => {
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  },

  async update({ dispatch }, { id, file, squeueId, sectionId, credit, cutline }) {
    const formData = buildFormData({ file, squeueId, sectionId, credit, cutline })

    return axios.patch(`/api/internal/media/${id}`, formData).then(() => {
      dispatch('media/search', null, { root: true }).then(() => {
        dispatch('pullSoleFromMedia')
      })
    }).catch(err => {
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  },

  async destroy({ dispatch }, { id }) {
    return axios.delete(`/api/internal/media/${id}`).then(() => {
      dispatch('media/clearSelected', null, { root: true })
      dispatch('media/search', null, { root: true })
    }).catch(err => {
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
