<template lang="pug">
CheckboxIcon(:checked="value" @click.native="toggle" :size="size")
</template>

<script>
  import CheckboxIcon from './checkbox_icon.vue'

  export default {
    components: {
      CheckboxIcon
    },

    props: {
      value: {
        type: Boolean,
        required: false // required is false to allow null values
      },
      // This should map to the Font Awesome sizes: lg, 2x, 3x, 4x, 5x
      size: {
        type: String,
        required: false,
        default: () => { null }
      }
    },

    methods: {
      toggle() {
        this.$emit('input', !this.value);
      }
    }
  }
</script>
