import { defineStore } from 'pinia'
import axios from 'axios'
import { withIDKeys } from '../utils/state_helpers'
import store from '../store'
import { set, del } from 'vue'

const defaultCurrent = { id: null, path: '' }

export const useCustomFoldersStore = defineStore('customFolders', {
  state: () => ({
    current: { id: null, path: '' },
    customFolders: {}
  }),

  getters: {
    allPaths: ({ customFolders }) => Object.values(customFolders).map((folder) => folder.path),
  },

  actions: {
    resetCurrent() {
      this.current = Object.assign({}, defaultCurrent)
    },

    async fetchForStory(storyId) {
      return axios.get(`/api/internal/stories/${storyId}/custom_folders`)
        .then(response => {
          this.customFolders = withIDKeys(response.data.custom_folders)
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    },

    async create(storyId) {
      return axios.post(`/api/internal/stories/${storyId}/custom_folders`, {
        custom_folder: { path: '' }
      })
      .then(({ data: { custom_folder }}) => {
        set(this.customFolders, custom_folder.id, custom_folder)
      })
      .catch((err) => {
        store.dispatch('messages/smartAdd', err)
      })
    },

    async update(customFolderId) {
      return axios.patch(`/api/internal/custom_folders/${customFolderId}`, {
        custom_folder: this.customFolders[customFolderId]
      })
        .then(({ data: { custom_folder }}) => {
          set(this.customFolders, custom_folder.id, custom_folder)
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    },

    async delete(customFolderId) {
      return axios.delete(`/api/internal/custom_folders/${customFolderId}`)
        .then(() => {
          del(this.customFolders, customFolderId)
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    }
  }
})
