<template lang="pug">
tr
  template(v-if="editing")
    td
      input.form-control(type="text" v-model="form.name")
    td
      label
        input(type="checkbox" v-model="form.default")
    td.text-right
      button.btn.btn-success.btn-xs(@click="save()")
        i.fa.fa-save
        |
        | Save
      |
      |
      button.btn.btn-default.btn-xs(@click="editing = false")
        i.fa.fa-ban
        |
        | Cancel

  template(v-else)
    td
      router-link(:to="`/admin/style_maps/${styleMap.id}/edit`")
        | {{ styleMap.name }}
    td
      CheckboxIcon(:checked="styleMap.default")
    td.text-right
      button.btn.btn-default.btn-xs(@click="editing = true")
        i.fa.fa-pencil
      |
      |
      button.btn.btn-danger.btn-xs(@click="destroy()")
        i.fa.fa-trash
</template>

<script>
  import CheckboxIcon from '../../../components/shared/checkbox_icon.vue'

  export default {
    components: {
      CheckboxIcon
    },

    props: {
      styleMap: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        editing: false,
        form: {
          id: null,
          name: '',
          default: false
        }
      }
    },

    mounted() {
      this.$data.form.id = this.styleMap.id;
      this.$data.form.name = this.styleMap.name;
      this.$data.form.default = this.styleMap.default;
    },

    methods: {
      save() {
        this.$store.dispatch('styleMaps/update', this.form).then(() => {
          this.editing = false;

          // Re-fetch them all because we may have cleared another record's default value
          this.$store.dispatch('styleMaps/fetch');
        })
      },

      destroy() {
        if(!confirm(`Are you sure you want to delete ${this.styleMap.name}?`)) return;

        this.$store.dispatch('styleMaps/delete', this.styleMap.id);
      }
    }
  }
</script>
