import axios from 'axios'
import ExifReader from 'exifreader'

const exifFromFrontend = async (file) => {
  const isImage = file.type.split('/')[0] === 'image'

  const tags = isImage ? await ExifReader.load(file) : {}

  const get = (tag) => tags[tag]?.description || null

  return {
    cutline: get('description'),
    credit: get('By-line') || get('creator')
  }
}

const exifFromBackend = async (file) => {
  const postParams = new FormData()
  postParams.append('file', file)

  return await axios.post(
    '/api/internal/exif_data',
    postParams
  ).then(({ data: { exif_data } }) => {
    return exif_data
  })
}

const exifMediumContent = async (file) => {
  const frontendData = await exifFromFrontend(file)

  // If the JS library gave us all the data we're looking for, return it withouth going
  // through the extra overhead of calling the backend with a potentially large file.
  if(frontendData.cutline && frontendData.credit) return frontendData

  // Otherwise, call the backend to get the data
  const backendData = await exifFromBackend(file)

  // The backend uses exiftool, which is widely used and likely more reliable than the JS library.
  // So we prefer it if it's available.
  return {
    cutline: backendData.cutline || frontendData.cutline,
    credit: backendData.credit || frontendData.credit
  }
}

export { exifMediumContent }
