import { defineStore } from 'pinia'
import axios from 'axios'
import { toKeyValObject } from '../utils/state_helpers'
import store from '../store'

export const useWebCategoriesStore = defineStore('webCategories', {
  state: () => ({
    webCategories: [],
    fetchInitiated: false
  }),

  getters: {
    enabled({ webCategories }) {
      return webCategories.filter(
        ({ enabled }) => enabled === true
      )
    },

    allAsObject() {
      return toKeyValObject(this.enabled, 'title', 'id')
    },

    forIds: ({ webCategories }) => (ids) => {
      return webCategories.filter(({ id }) => ids.includes(id))
    }
  },

  actions: {
    async fetch() {
      this.fetchInitiated = true

      return axios.get('/api/internal/web_categories')
        .then(response => {
          this.webCategories = response.data.web_categories
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    },

    async fetchOnce() {
      if (this.fetchInitiated) return Promise.resolve()

      return this.fetch()
    }
  }
})
