<template lang="pug">
div
  header
    .pull-right
      a.btn.btn-default(href="/admin/web_categories/new") New Web Category
    h1 Web Categories

  table.table.table-striped
    thead
      tr
        th Title
        th Code
        th System?
        th Enabled?
        th.text-right Sort
    draggable(tag="tbody" v-model="taxonomies" v-on:change="save" handle=".handle")
      template(v-for="webCategory in taxonomies")
        tr(v-bind:key="webCategory.key")
          td
            a(:href="`/admin/web_categories/${webCategory.id}/edit`" v-text="webCategory.title")
          td
            code(v-text="webCategory.slug")
          td
            i(:class="checkboxClass(webCategory.system_record)")
          td
            i(:class="checkboxClass(webCategory.enabled)")
          td.text-right
            button.handle.btn.btn-default.btn-sm(style="cursor: move")
              i.fa.fa-sort
</template>

<script>
import OrderedTaxonomy from '../../mixins/ordered_taxonomy'

export default {
  mixins: [OrderedTaxonomy],

  props: {
    taxonomyName: {
      default: 'web_categories'
    }
  }
}
</script>
