<template lang="pug">
.communityq-story-template-field
  select.form-control(v-model="selectedId")
    option
    option(
      v-for="storyTemplate in communityqStoryTemplatesStore.storyTemplates"
      :value="storyTemplate.id"
      :key="storyTemplate.id"
    )
      | {{ storyTemplate.title }}
</template>

<script>
import { mapStores } from 'pinia'
import { useCommunityqStoryTemplatesStore } from '../../../stores/communityq_story_templates'

export default {
  props: {
    publicationId: {
      required: true,
      type: Number
    },
    value: {
      type: Number,
      required: false
    }
  },

  computed: {
    ...mapStores(useCommunityqStoryTemplatesStore),

    selectedId: {
      get() { return this.value },
      // Emits the value to support usage with v-model. The change will then
      //flow back down through the value prop, so we don't need to set it here.
      set(val) {
        const intVal = parseInt(val);
        // Convert non-id values to null i.e. 0 or NaN
        const idValue = (intVal > 0 ? intVal : null);
        this.$emit('input', idValue);
      }
    }
  },

  async mounted() {
    await this.communityqStoryTemplatesStore.fetchOnce(this.publicationId)
  }
}
</script>
