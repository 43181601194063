import { keyBy } from 'lodash'
import objectHash from 'object-hash'

// Just produces a random string that can be used as a key.
// This is just to avoid duplicate value errors from Vue.
const randomKey = () => (Math.random() + 1).toString(36).substring(7)

const withIDKeys = (objects) => keyBy(objects, 'id')

const stateFingerprint = (state) => {
  return objectHash(state)
}

const toKeyValObject= (array, key, val) => {
  return array.reduce(
    (obj, item) => Object.assign(obj, { [item[key]]: item[val] }),
    {}
  )
}

export {
  randomKey,
  withIDKeys,
  stateFingerprint,
  toKeyValObject
}
