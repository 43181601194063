import axios from 'axios'
import { make } from 'vuex-pathify'
import { pick } from 'lodash'

// initial state
const state = {
  styleMappings: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ styleMappings }) {
    return styleMappings
  }
}

// mutations
const mutations = {
  ...make.mutations(state)
}

// actions
const actions = {
  async fetch({ commit, dispatch }, styleMapId) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get(`/api/internal/style_maps/${styleMapId}/style_mapping`)
      .then(response => {
        commit('SET_STYLE_MAPPINGS', response.data.style_mappings)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  },

  async update({ commit, dispatch }, { styleMapId, styleMappings }) {
    const permittedStyleMappings =
      styleMappings.map((mapping) => {
        return pick(mapping, ['id', 'style_map_id', 'style', 'custom_name'])
      })

    return axios.patch(
      `/api/internal/style_maps/${styleMapId}/style_mapping`,
      { style_mappings: permittedStyleMappings }
    ).then(response => {
      commit('SET_STYLE_MAPPINGS', response.data.style_mappings)
      dispatch('messages/addNotice', response.data.message, { root: true })
      return response
    }).catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  async fetchOnce({ dispatch, state: { fetchInitiated } }, styleMapId) {
    if (fetchInitiated) return Promise.resolve()

    return dispatch('fetch', styleMapId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
