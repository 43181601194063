<template lang="pug">
.col-sm-6.col-md-4.col-sg-3
  .thumbnail
    template(v-if="!isDirectory")
      button.btn.btn-link.btn-lg.show-on-hover.download-file(v-on:click="downloadFile()")
        i.fa.fa-arrow-down
    button.btn.btn-link.btn-lg.show-on-hover.delete-file(v-on:click="deleteFile()")
      i.fa.fa-trash
    template(v-if="isDirectory")
      .thumb.file.text-center.text-muted
        a(@click="goToDirectory")
          i.fa.fa-folder-o
    template(v-if="isImage")
      img.thumb(:src="thumbUrl")
    template(v-if="!isDirectory && !isImage")
      .thumb.file.text-center.text-muted
        i.fa.fa-file-o
        .extension
          | {{ extension }}

    .caption
      span.filename.text-muted
        | {{ name }}
</template>

<script>
  import { mapStores } from 'pinia'
  import { useFilesStore } from '../../stores/files'

  const DIRECTORY_TYPE = 'directory'
  const IMAGE_EXTENSIONS = [ '.gif', '.jpg', '.jpeg', '.png', '.tif', '.tiff', '.heic', '.HEIC' ]

  export default {
    props: {
      file: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapStores(useFilesStore),
      extension() { return this.file.extension },
      name() { return this.file.name },
      type() { return this.file.type },
      thumbUrl() { return this.file.large_thumb_url },
      relativePathFromResource() { return this.file.relative_path_from_resource },

      isDirectory() {
        return this.type === DIRECTORY_TYPE;
      },

      isImage() {
        return IMAGE_EXTENSIONS.includes(this.extension)
      }
    },

    methods: {
      goToDirectory() {
        this.filesStore.goDownTo(this.name)
      },

      downloadFile() {
        const base64ResourceGlobalId = this.filesStore.base64ResourceGlobalId
        if(!base64ResourceGlobalId) {
          this.store.$dispatch(
            'messages/addAlert',
            "Missing resource ID. Can't download file."
          )
        }
        const currentPath = this.filesStore.currentPath

        window.location = `/api/internal/resources/${base64ResourceGlobalId}/files/${currentPath}/${this.name}/download`
      },

      deleteFile() {
        if(confirm(`Delete ${this.name}`)) {
          this.filesStore.destroy(this.relativePathFromResource)
        }
      }
    }
  }
</script>


<style scoped>
  button.delete-file {
    color: #a94442;
  }

  .thumb.file a {
    cursor: pointer;
  }

  .show-on-hover {
    visibility: hidden;
  }

  .thumbnail:hover .show-on-hover {
    visibility: visible;
  }
</style>
