<template lang="pug">
.custom-folders
  CustomFolder(
    v-for="folder in customFoldersStore.customFolders"
    :custom-folder-id="folder.id"
    :key="folder.id"
  )
  .text-right
    button.btn.btn-link(type="button" @click="createNew()")
      i.fa.fa-plus
      |
      | Add file storage location
</template>

<script>
  import { mapStores } from 'pinia'
  import { useCustomFoldersStore } from '../../../stores/custom_folders'
  import CustomFolder from './custom_folder.vue'

  export default {
    components: {
      CustomFolder
    },

    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    computed: {
      ...mapStores(useCustomFoldersStore),
    },

    mounted() {
      this.customFoldersStore.fetchForStory(this.storyId)
    },

    methods: {
      createNew() {
        this.customFoldersStore.create(this.storyId)
      }
    }
  }
</script>
