import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useCommunityqContentTypesStore = defineStore('communityqContentTypes', {
  state: () => ({
    contentTypes: {}
  }),

  actions: {
    async fetch(publicationId) {
      return axios.get(`/api/internal/publications/${publicationId}/community_q/content_types`)
        .then(response => {
          this.contentTypes = response.data.content_types
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    }
  }
})
