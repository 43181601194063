<template lang="pug">
.custom-folder.input-group
  .input-group-addon
    i.fa.fa-hdd-o
  Input(v-model="customFolder.path" @blur="updateCustomFolder()")
  span.input-group-btn
    button.btn.btn-danger(type="button" @click="deleteCustomFolder()")
      i.fa.fa-times
</template>

<script>
  import { mapStores } from 'pinia'
  import { useCustomFoldersStore } from '../../../stores/custom_folders'
  import Input from '../../shared/input.vue'

  export default {
    components: {
      Input
    },

    props: {
      customFolderId: {
        type: Number,
        required: false
      }
    },

    computed: {
      ...mapStores(useCustomFoldersStore),

      customFolder() {
        return this.customFoldersStore.customFolders[this.customFolderId]
      }
    },

    methods: {
      updateCustomFolder() {
        this.customFoldersStore.update(this.customFolderId)
      },

      deleteCustomFolder() {
        this.customFoldersStore.delete(this.customFolderId)
      }
    }
  }
</script>
