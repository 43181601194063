<template lang="pug">
IssueForm
</template>

<script>
  import IssueForm from '../shared/form.vue'

  export default {
    components: {
      IssueForm
    },

    async mounted() {
      await this.$store.commit('issue/RESET')
    }
  }
</script>
