<template lang="pug">
span
  a.sort_link(
    v-if="!disabled"
    @click="sort"
    :class="{asc: asc, desc: desc}"
  ) {{ text }}
  span.sort_link(
    v-if="disabled"
  ) {{ text }}
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        required: true
      },

      text: {
        type: String,
        required: true
      },

      column: {
        type: String,
        required: true
      },

      disabled: {
        type: Boolean,
        default: () => false
      }
    },

    computed: {
      currentColumn() {
        return this.value.column
      },

      currentDirection() {
        return this.value.direction
      },

      thisColumn() {
        return this.column === this.currentColumn
      },

      asc() {
        return this.isSortedBy('asc')
      },

      desc() {
        return this.isSortedBy('desc')
      },

      // The direction it should be sorted when the link is clicked
      newDirection() {
        // If changing sort columns, always do ascending
        if(!this.thisColumn) return 'asc'

        // Otherwise, toggle the direction
        return this.asc ? 'desc' : 'asc'
      }
    },

    methods: {
      sort() {
        const newValue = {
          column: this.column,
          direction: this.newDirection
        }

        this.$emit('input', newValue)
      },

      isSortedBy(direction) {
        if(this.disabled) return false
        if(!this.thisColumn) return false

        return this.currentDirection === direction
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sort_link {
    color: black;

    // Append FontAwesome sort icons to links
    &:after, &:after{
      padding-left: 3px;
      font-family: FontAwesome;
      font-size: 85%;
      color: #cdcdcd;
    }

    &:after {
      content: '\f160'
    }

    &.asc:after {
      color: #333;
    }

    &.desc:after {
      color: #333;
      content: '\f161'
    }
  }

  a.sort_link {
    cursor: pointer;

    &:hover {
      text-decoration: none;

      &:after{
        color: #333;
      }
    }
  }

  span.sort_link {
    cursor: not-allowed;
  }
</style>
