<template lang="pug">
.panel.panel-default.pull-quotes
  .panel-heading
    .row
      .col-md-6
        h4(style="margin-top: 7px") Pull Quotes
      .col-md-6.text-right
        ShowHideButton.btn-sm(v-model="showPullQuotes")

  .panel-body(v-show="showPullQuotes")
    .form-group
      label.control-label Headline
      input.form-control(type="text" v-model="headline")

    .form-group
      label.control-label Quote
      textarea.form-control(v-model="quote")

    .form-group
      label.control-label Attribution
      input.form-control(type="text" v-model="attribution")
</template>

<script>
  import { sync } from 'vuex-pathify'
  import ShowHideButton from '../../show_hide_button.vue'

  export default {
    components: {
      ShowHideButton
    },

    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        showPullQuotes: true
      }
    },

    computed: sync('pullQuote/*'),

    watch: {
      headline(val) { if(val) { this.showPullQuotes = true; } },
      quote(val) { if(val) { this.showPullQuotes = true; } },
      attribution(val) { if(val) { this.showPullQuotes = true; } },
    },

    mounted() {
      this.$store.commit('pullQuote/RESET')
      this.$store.dispatch('pullQuote/fetchForStory', { storyId: this.storyId });
    }
  };
</script>
