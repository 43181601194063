<template lang="pug">
div
  header
    .pull-right
      router-link.btn.btn-default(to="/admin/transforms/new")
        i.fa.fa-plus
        |
        | New Document Transformation
    h1 Document Transformations

  LoaderOverlay(:loading="loading")
    table.table.table-striped
      thead
        tr
          th Name
          th Rules
          th.actions
      tbody
        template(v-for="transform in transforms")
          Row(:transform="transform" :key="transform.id")
</template>

<script>
  import { sync } from 'vuex-pathify';

  import LoaderOverlay from '../../../../components/shared/loader_overlay.vue'
  import Row from './row.vue'

  export default {
    components: {
      LoaderOverlay,
      Row
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      transforms: sync('transforms/transforms')
    },

    async mounted() {
      this.loading = true

      await this.$store.dispatch('transforms/fetch');

      this.loading = false
    }
  }
</script>
