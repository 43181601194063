import { set } from 'vue'
import { make } from 'vuex-pathify'
import { get } from 'lodash'
import { randomKey } from '../../utils/state_helpers'

// initial state
const state = {
  messages: {}
}

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state),

  ADD({ messages }, { type, text }) {
    set(messages, randomKey(), { type, text })
  }
}

// actions
const actions = {
  ...make.actions(state),

  addAlert({ commit }, text) {
    commit('ADD', { type: 'alert', text })
  },

  addNotice({ commit }, text) {
    commit('ADD', { type: 'notice', text })
  },

  addInfo({ commit }, text) {
    commit('ADD', { type: 'info', text })
  },

  async addError({ dispatch }, err) {
    const message = get(
      err,
      'response.data.message',
      get(err, 'message', 'An unknown error occurred')
    )

    return dispatch('addAlert', message)
  },

  addFlash({ commit }, flash) {
    let type

    if (flash.alert) {
      type = 'alert'
    } else if (flash.notice) {
      type = 'notice'
    } else if (flash.info) {
      type = 'info'
    }

    if (type) {
      commit('ADD', { type: type, text: flash[type] })
    }
  },

  smartAdd({ dispatch }, obj) {
    const json = obj.response?.data

    if (json?.flash) {
      dispatch('addFlash', json.flash)
    } else if (json?.message) {
      if( json?.status === 'error') {
        dispatch('addAlert', json.message)
      } else {
        dispatch('addNotice', json.message)
      }
    } else if (obj instanceof Error) {
      dispatch('addAlert', obj.message)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
