<template lang="pug">
button.btn.btn-default(type="button" v-on:click="toggle()")
  | {{ label }}
  |
  i.fa(:class="iconClass")
</template>

<script>
  module.exports = {
    props: {
      showLabel: {
        type: String,
        default: () => 'Show'
      },

      hideLabel: {
        type: String,
        default: () => 'Hide'
      },

      value: {
        type: Boolean,
        default() { return false }
      }
    },

    data() {
      return {shown: false}
    },

    watch: {
      value() {
        this.shown = this.value
      },

      shown() {
        // For component v-model support
        this.$emit('input', this.shown)
      }
    },

    computed: {
      label() {
        return this.shown ? this.hideLabel : this.showLabel
      },
      iconClass() {
        return { "fa-angle-up": this.shown, "fa-angle-down": !this.shown }
      }
    },

    mounted() {
      this.shown = this.value
    },

    methods: {
      toggle() {
        this.shown = !this.shown
      }
    }
  }
</script>
