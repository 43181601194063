<template lang="pug">
div
  h1 Location

  LoaderOverlay(:loading="loading")
    form
      .form-group
        Label(text="Title")
        Input(v-model="location.title")
      .row
        .col-md-6
          .form-group
            Label(text="Address 1")
            Input(v-model="location.address_street1")
        .col-md-3
          .form-group
            Label(text="City")
            Input(v-model="location.city")
        .col-md-1
          .form-group
            Label(text="State")
            Input(v-model="location.state")
        .col-md-2
          .form-group
            Label(text="Zip")
            Input(v-model="location.zip" type="number")
      .row
        .col-md-4
          .form-group
            Label(text="Address 2")
            Input(v-model="location.address_street2")
        .col-md-4
          .form-group
            Label(text="Phone 1")
            Input(v-model="location.phone1" type="tel")
        .col-md-4
          .form-group
            Label(text="Phone 2")
            Input(v-model="location.phone2" type="tel")
      .row
        .col-md-4
          .form-group
            Label(text="Email")
            Input(v-model="location.email" type="email")
        .col-md-4
          .form-group
            Label(text="Fax")
            Input(v-model="location.fax" type="tel")
        .col-md-4
          .form-group
            Label(text="Website")
            Input(v-model="location.website")
      .form-group
        Label(text="Description")
        TextareaInput(v-model="location.description" type)
      .row.text-nowrap
        .col-sm-12
          button.btn.btn-primary(@click.prevent="save") Save
          router-link.btn.btn-link.text-muted(to="/locations/") Cancel
</template>

<script>
  import { mapStores, mapState } from 'pinia'
  import { useLocationStore } from '../../../stores/location'
  import Input from '../../../components/shared/input.vue'
  import Label from '../../../components/shared/label.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import TextareaInput from '../../../components/shared/textarea_input.vue'

  export default {
    components: {
      Input,
      Label,
      LoaderOverlay,
      TextareaInput
    },

    data() {
      return {
        loading: false
      }
    },

    computed: {
      ...mapStores(useLocationStore),
      ...mapState(useLocationStore, ['location']),
    },

    methods: {
      async save() {
        this.loading = true

        await this.locationStore.save().then((resp) => {
          this.$router.push('/locations/')
        }).catch(() => {})

        this.loading = false
      }
    }
  }
</script>
