import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useBylinePresetsStore = defineStore('bylinePresets', {
  state: () => ({
    bylinePresets: []
  }),

  actions: {
    async fetch() {
      return axios.get('/api/internal/community_q/byline_presets')
        .then(response => {
          this.bylinePresets = response.data.byline_presets
        })
        .catch((err) => {
          // We still add the presets. Because the endpoint returns bylines from multiple Publications.
          // Which meanst one could fail and the others could succeed. So we can be in a half successful half failed state.
          this.bylinePresets = err?.response?.data?.byline_presets || []

          store.dispatch('messages/smartAdd', err)
        })
    }
  }
})
