import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  transforms: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  forId: ({ transforms }) => (id) => {
    return transforms.find(transform => transform.id === id)
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  ADD_TRANSFORM(state, transform) {
    return state.transforms.push(transform)
  },

  UPDATE_TRANSFORM(state, transform) {
    state.transforms = state.transforms.map(tform => {
      return tform.id === transform.id ? transform : tform
    })
  },

  DELETE_TRANSFORM(state, transformId) {
    state.transforms = state.transforms.filter(tform => {
      return tform.id !== transformId
    })
  }
}

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get('/api/internal/transforms')
      .then(response => {
        commit('SET_TRANSFORMS', response.data.transforms)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  },

  async destroy({ commit, dispatch }, transformId) {
    return axios.delete(
      `/api/internal/transforms/${transformId}`
    ).then(response => {
      commit('DELETE_TRANSFORM', transformId)
      return response
    }).catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  async fetchOnce({ dispatch, state: { fetchInitiated } }) {
    if (fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
