<template lang="pug">
.row
  .col-md-2

  .col-md-8
    h1 New Transform

    form(@submit.prevent="save")
      .row
        .col-md-12
          .form-group
            Label(text="Name")
            Input(v-model="transform.name")

      .row
        .col-md-12
          hr

      .row
        .col-md-7
          button.btn.btn-primary(type="submit") Save
          router-link.btn.btn-link.text-muted(to="/admin/transforms") Cancel

  .col-md-2
</template>

<script>
  import { sync } from 'vuex-pathify'

  import Input from '../../../../components/shared/input.vue'
  import Label from '../../../../components/shared/label.vue'

  export default {
    components: {
      Input,
      Label
    },

    computed: {
      transform: sync('transform/transform')
    },

    data() {
      return {
        loading: true
      }
    },

    async mounted() {
      await this.$store.commit('transform/RESET')
    },

    methods: {
      async save() {
        this.$store.dispatch('transform/create').then(() => {
          this.loading = false
          this.$router.push({ name: 'adminTransforms' });
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
