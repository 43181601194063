import axios from 'axios'
import { make } from 'vuex-pathify'

// helpers
const getDefaultState = () => {
  return {
    transform: {
      id: null,
      name: '',
      transform_rules: []
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  hasNewRecord({ transform: { transform_rules }}) {
    return transform_rules.some(({ id }) => !id)
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  ADD_BLANK_RULE({ transform: { id, transform_rules }}, { type }) {
    // Only allow one new record at a time
    if(transform_rules.some(({ id }) => !id)) return

    transform_rules.push(
      {
        id: null,
        transform_id: id,
        type ,
        original: '',
        replacement_start: '',
        replacement_end: ''
      }
    )
  },

  ADD_RULE({ transform: { transform_rules }}, { transform_rule }) {
    transform_rules.push(transform_rule)
  },

  UPDATE_RULE(state, { transform_rule }) {
    const { transform: { transform_rules }} = state

    state.tarnsform_rules = transform_rules.map(rule => {
      if(transform_rule.id === rule.id) {
        return transform_rule
      } else {
        return rule
      }
    })
  },

  REMOVE_RULE(state, { id }) {
    const { transform: { transform_rules }} = state

    state.transform.transform_rules = transform_rules.filter(rule => rule.id !== id)
  },

  REMOVE_NEW_RULE(state) {
    const { transform: { transform_rules }} = state

    state.transform.transform_rules = transform_rules.filter(({ id }) => id)
  },

  RESET(state) {
    Object.assign(state, { ...getDefaultState() })
  }
}

// actions
const actions = {
  ...make.actions(state),

  async fetch({ dispatch }, { transformId }) {
    return axios
      .get(`/api/internal/transforms/${transformId}`)
      .then(response => {
        dispatch('setTransform', response.data.transform)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  },

  async create({ state: { transform }, commit, dispatch }) {
    return axios
      .post('/api/internal/transforms/', { transform })
      .then(({ data }) => {
        commit('SET_TRANSFORM', data.transform)
        dispatch('messages/addNotice', 'Document transform created', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  async update({ state: { transform }, commit, dispatch }) {
    return axios
      .patch(
        `/api/internal/transforms/${transform.id}`,
        { transform }
      )
      .then(({ data }) => {
        commit('SET_TRANSFORM', data.transform)
        dispatch('messages/addNotice', 'Document transform saved', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  async addRule({ commit }, { type }) {
    commit('ADD_BLANK_RULE', { type })
  },

  async saveRule({ dispatch }, { transform_rule }) {
    const action = transform_rule.id ? 'updateRule' : 'createRule'

    return dispatch(action, { transform_rule })
  },

  async createRule({ dispatch, commit }, { transform_rule }) {
    return axios.post(
      '/api/internal/transform_rules',
      { transform_rule }
    )
    .then(({ data: { transform_rule, message} }) => {
      commit('ADD_RULE', { transform_rule })
      commit('REMOVE_NEW_RULE')

      dispatch('messages/addNotice', message, { root: true })
    })
    .catch(err => {
      console.error(err)
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  async updateRule({ dispatch, commit }, { transform_rule }) {
    const { id } = transform_rule

    return axios.patch(
      `/api/internal/transform_rules/${id}`,
      { transform_rule }
    )
    .then(({ data: { transform_rule, message } }) => {
      commit('UPDATE_RULE', { transform_rule })

      dispatch('messages/addNotice', message, { root: true })
    })
    .catch(err => {
      console.error(err)
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  async destroyRule({ commit }, { id }) {
    if(id) {
      return axios.delete(
        `/api/internal/transform_rules/${id}`
      ).then(({ data }) => {
        commit('REMOVE_RULE', { id })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/smartAdd', err, { root: true })
      })
    } else {
      return commit('REMOVE_NEW_RULE')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
