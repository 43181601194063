<template lang="pug">
component.toolbar-button(
  :is="tagType"
  type="button"
  @click="runAction()"
  :class="classObject"
  :disabled="inactive"
  :title="title"
)
  slot
</template>

<script>
  export default {
    props: {
      editor: {
        type: Object,
        required: false
      },

      title: {
        type: String,
        required: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      // Args to pass to editor.isActive
      activeCheck: {
        type: [String, Object, Array],
        required: false
      },

      // The function will recive editor.chain().focus() as an argument.
      // run() willbe called on the return value.
      action: {
        type: Function,
        required: true
      },

      tagType: {
        type: String,
        default: 'button'
      },

      classes: {
        type: Object,
        default: () => ({ btn: true, 'btn-sm': true })
      }
    },

    computed: {
      inactive() {
        return this.disabled || !this.editor
      },

      active() {
        if(this.inactive) return false
        if(!this.activeCheck) return false

        // Some specific calls need multiple arguments, so convert an Array into multiple arguments.
        if(Array.isArray(this.activeCheck)) {
          return this.editor.isActive(...this.activeCheck)
        } else {
          return this.editor.isActive(this.activeCheck)
        }
      },

      classObject() {
        return {...this.classes, 'btn-primary': this.active }
      }
    },

    methods: {
      runAction() {
        if(this.inactive) return

        const chain = this.editor.chain().focus()

        this.action(chain)?.run()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .toolbar-button {
    cursor: pointer;
  }
</style>
