<template lang="pug">
tr
  td.text-center
    a.toggle-button(@click="toggleFavorite()")
      i.fa.fa-lg(:class="{'fa-star': favorite, 'fa-star-o': !favorite}")
  td {{ title }}
  td
    a(:href="query" :title="query") {{ truncatedQuery }}
  td
    a(@click="toggleDefault()")
      i.fa(:class="{ 'fa-check-square-o': userDefault, 'fa-square-o': !userDefault }")
  td(v-if="hotQueues")
    a(@click="toggleHotQueue()")
      i.fa(:class="{ 'fa-check-square-o': hotQueue, 'fa-square-o': !hotQueue }")
  td
    a.btn.btn-danger.btn-xs(@click="destroy()")
      i.fa.fa-trash-o
</template>

<script>
  import { sync } from 'vuex-pathify';
  import { mapStores } from 'pinia'
  import { useCurrentUserStore } from '../../stores/current_user';
  import { useStorySavedSearchesStore } from '../../stores/story_saved_searches'

  const maxQueryLength = 120;

  export default {
    props: {
      storySavedSearch: {
        requried: true,
        type: Object
      }
    },

    computed: {
      hotQueues: sync('settings/hotQueues'),
      id() { return this.storySavedSearch.id },
      title() { return this.storySavedSearch.title },
      query() { return this.storySavedSearch.query },
      ...mapStores(
        useCurrentUserStore,
        useStorySavedSearchesStore
      ),
      truncatedQuery() {
        const query = this.storySavedSearch.simplified_query;

        if(query.length < maxQueryLength) {
          return query;
        } else {
          return query.slice(0, maxQueryLength) + '...';
        }
      },
      hotQueue() { return this.storySavedSearch.hot_queue },
      favorite() { return this.storySavedSearch.favorite },
      userDefault() {
        return this.id == this.currentUserStore.currentUser.default_story_saved_search_id
      },
    },

    methods: {
      toggleFavorite() {
        this.storySavedSearchesStore.toggleFavorite(this.id)
      },

      toggleDefault() {
        const defaultId = this.userDefault ? null : this.id;

        this.currentUserStore.update({ default_story_saved_search_id: defaultId });
      },

      toggleHotQueue() {
        this.storySavedSearchesStore.toggleHotQueue(this.id)
      },

      destroy() {
        if(confirm(`Delete ${this.title}?`)) {
          this.storySavedSearchesStore.destroy(this.id)
        }
      }
    }
  }
</script>

<style lang="scss">
  .toggle-button {
    cursor: pointer;
  }
</style>
