import axios from 'axios'
import { defineStore } from 'pinia'
import store from '../store'

export const useCurrentUserStore = defineStore('currentUser',{
  state: () => ({
    currentUser: {
      id: null,
      name: '',
      first_name: '',
      last_name: '',
      email: '',
      ext: null,
      remote_phone: '',
      byline: '',
      role_is_limited: true,
      can_set_squeue: false,
      can_change_ownership: false,
      can_access_google_picker: false,
      show_advert_fields: false,
      local_file_path: '',
      assignment_notifications: false,
      default_publication_id: null,
      default_story_saved_search_id: null,
    },
    fetchInitiated: false
  }),

  actions: {
    async fetch() {
      this.fetchInitiated = true

      return axios.get('/api/internal/current_user')
        .then(({ data: { user } }) => {
          this.currentUser = user
          return user
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
          throw err
        })
    },

    async fetchOnce() {
      if (this.fetchInitiated) return Promise.resolve()

      return this.fetch()
    },

    async update(params) {
      return axios
        .patch('/api/internal/current_user', {
          user: params
        })
        .then(({ data: { user, message }}) => {
          this.currentUser = user
          store.dispatch('messages/addNotice', message)
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    },
  },
})
