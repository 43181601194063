<template lang="pug">
.row
  .col-md-12
    h1 Edit Document Transform

    LoaderOverlay(:loading="loading")
      .row
        .col-md-12
          form.form-group(@submit.prevent="updateName")
            Label(text="Name")
            .input-group
              Input(v-model="transform.name")
              span.input-group-btn
                button.btn.btn-success(type="submit" :disabled="savingName")
                  Loader(v-if="savingName" :stacked="false")
                  i.fa.fa-save(v-if="!savingName")

      .row
        .col-md-12
          hr

      .row.tag-rules
        .col-md-12
          h2 Tag Rules
          table.table.table-striped
            thead
              tr
                th.original Tag
                th.replacement Replacement
                th.actions
            tbody
              TagRow(v-for="rule in transform.transform_rules" v-if="rule.type == 'tag'" :key="rule.id" :rule="rule")

          .actions.text-right
            button.btn.btn-default(type="button" @click="addRule('tag')" :disabled="hasNewRecord")
              i.fa.fa-plus
              |
              | Add Rule

          hr

      .row.character-rules
        .col-md-12
          h2 Character Rules
          table.table.table-striped
            thead
              tr
                th.original Chraracter
                th.replacement Replacement
                th.actions
            tbody
              CharacterRow(v-for="rule in transform.transform_rules" v-if="rule.type == 'character'" :key="rule.id" :rule="rule")

          .actions.text-right
            button.btn.btn-default(type="button" @click="addRule('character')" :disabled="hasNewRecord")
              i.fa.fa-plus
              |
              | Add Rule

      .row
        .col-md-12
          hr

      .row
        .col-md-12
          router-link.btn.btn-default(to="/admin/transforms")
            i.fa.fa-arrow-left
            |
            | Back

  .col-md-2
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  import CharacterRow from './character_row.vue'
  import Input from '../../../../components/shared/input.vue'
  import Label from '../../../../components/shared/label.vue'
  import Loader from '../../../../components/shared/loader.vue'
  import LoaderOverlay from '../../../../components/shared/loader_overlay.vue'
  import TagRow from './tag_row.vue'

  export default {
    components: {
      CharacterRow,
      Input,
      Label,
      Loader,
      LoaderOverlay,
      TagRow
    },

    props: {
      transformId: {
        type: Number,
        required: true
      }
    },

    computed: {
      ...mapGetters('transform', ['hasNewRecord']),

      transform: sync('transform/transform')
    },

    data() {
      return {
        loading: true,
        savingName: false
      }
    },

    async mounted() {
      this.loading = true

      const { transformId } = this
      await this.$store.dispatch('transform/fetch', { transformId })

      this.loading = false
    },

    methods: {
      async updateName() {
        this.savingName = true

        await this.$store.dispatch('transform/update')

        this.savingName = false
      },

      async addRule(type) {
        this.$store.dispatch('transform/addRule', { type })
      },
    }
  }
</script>

<style lang="scss">
  .tag-rules, .character-rules {
    table {
      thead tr {
        th {
          white-space: nowrap;

          &.original {
            width: 15%;
          }
          &.replacement {
            width: 80%;
          }
          &.actions {
            width: 5%;
          }
        }
      }
    }
  }
</style>
