<template lang="pug">
.story-publications
  StoryPublication(
    v-for="publication in publications"
    :story-id="storyId"
    :publication="publication"
    :key="publication.id"
    v-if="!storyPubsLoading"
  )
</template>

<script>
import { sync } from 'vuex-pathify'
import StoryPublication from './story_publication.vue'

export default {
  components: {
    StoryPublication
  },

  props: {
    storyId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      // The StoryPublication component needs Publication and StoryPublication
      // data, so we need to wait until both are fetched before rendering
      storyPubsLoading: true
    }
  },

  computed: {
    publications: sync('publications/publications')
  },

  async mounted() {
    await this.$store.dispatch('publications/fetch')

    await this.$store.dispatch('storyPublications/fetch', { storyId: this.storyId }).then(() => {
      this.storyPubsLoading = false
    })
  }
}
</script>
