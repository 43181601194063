import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useStorySavedSearchesStore = defineStore('storySavedSearches', {
  state: () => ({
    storySavedSearches: []
  }),

  actions: {
    async update(storySavedSearch) {
      this.storySavedSearches = this.storySavedSearches.map((search) => {
        if(search.id == storySavedSearch.id) {
          return Object.assign(search, storySavedSearch)
        } else {
          return search
        }
      })
    },

    async fetch() {
      return axios
        .get('/api/internal/story_saved_searches')
        .then(response => {
          this.storySavedSearches = response.data.story_saved_searches
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    },

    async create(title) {
      const newSearch = {
        title,
        query: `${window.location.pathname}${window.location.search}`
      }

      return axios.post('/api/internal/story_saved_searches', {
        story_saved_search: newSearch
      }).then(response => {
        this.storySavedSearches = [...this.storySavedSearches, newSearch]
        store.dispatch('messages/addNotice', response.data.message)
      }).catch(err => {
        store.dispatch('messages/addError', err)
      })
    },

    async toggleFavorite(storySavedSearchId) {
      const search = this.storySavedSearches.find(srch => srch.id == storySavedSearchId)
      const value = !search.favorite
      const httpMethod = value ? 'post' : 'delete'

      return axios({
        method: httpMethod,
        url: `/api/internal/story_saved_searches/${storySavedSearchId}/favorite`
      })
      .then(response => {
        this.update({ id: storySavedSearchId, favorite: value })
        store.dispatch('messages/addNotice', response.data.message)
      })
      .catch(err => {
        console.error(err)
        store.dispatch('messages/addError', err)
      })
    },

    async toggleHotQueue(storySavedSearchId) {
      const search = this.storySavedSearches.find(srch => srch.id == storySavedSearchId)

      return axios
        .patch(`/api/internal/story_saved_searches/${storySavedSearchId}`, {
          story_saved_search: {
            hot_queue: !search.hot_queue
          }
        })
        .then(response => {
          this.storySavedSearches = response.data.story_saved_search
          store.dispatch('messages/addNotice', response.data.message)
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    },

    async destroy(storySavedSearchId) {
      return axios
        .delete(`/api/internal/story_saved_searches/${storySavedSearchId}`)
        .then(response => {
          this.storySavedSearches = this.storySavedSearches.filter(search => search.id != storySavedSearchId)
          store.dispatch('messages/addNotice', response.data.message)
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    }
  }
})
