<template lang="pug">
.btn-toolbar
  .btn-group
    ToolbarButton(
      :editor="editor"
      title="Undo"
      :action="(chain) => chain.undo()"
      :disabled="!editor || !editor.can().undo()"
    )
      i.fa.fa-rotate-left

    ToolbarButton(
      :editor="editor"
      title="Redo"
      :action="(chain) => chain.redo()"
      :disabled="!editor || !editor.can().redo()"
    )
      i.fa.fa-rotate-right

  .btn-group
    ToolbarButton(
      :editor="editor"
      title="Bold"
      activeCheck="bold"
      :action="(chain) => chain.toggleBold()"
    )
      i.fa.fa-bold

    ToolbarButton(
      :editor="editor"
      title="Italic"
      activeCheck="italic"
      :action="(chain) => chain.toggleItalic()"
    )
      i.fa.fa-italic

    ToolbarButton(
      :editor="editor"
      title="Underline"
      activeCheck="underline"
      :action="(chain) => chain.toggleUnderline()"
    )
      i.fa.fa-underline

  .btn-group
    button.btn.btn-sm.dropdown-toggle(
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      title="Style"
    )
      i.fa.fa-magic
      span.caret
    ul.dropdown-menu
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Quote"
          activeCheck="blockquote"
          :action="(chain) => chain.toggleBlockquote()"
        )
          | Quote

      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Paragraph"
          activeCheck="paragraph"
          :action="(chain) => chain.setParagraph()"
        )
          | Paragraph

      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Heading 1"
          :activeCheck="['heading', { level: 1 }]"
          :action="(chain) => chain.toggleHeading({ level: 1 })"
        )
          | Heading 1

      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Heading 1"
          :activeCheck="['heading', { level: 2 }]"
          :action="(chain) => chain.toggleHeading({ level: 2 })"
        )
          | Heading 2
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Heading 1"
          :activeCheck="['heading', { level: 3 }]"
          :action="(chain) => chain.toggleHeading({ level: 3 })"
        )
          | Heading 3
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Heading 1"
          :activeCheck="['heading', { level: 4 }]"
          :action="(chain) => chain.toggleHeading({ level: 4 })"
        )
          | Heading 4
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Heading 1"
          :activeCheck="['heading', { level: 5 }]"
          :action="(chain) => chain.toggleHeading({ level: 5 })"
        )
          | Heading 5
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Heading 1"
          :activeCheck="['heading', { level: 6 }]"
          :action="(chain) => chain.toggleHeading({ level: 6 })"
        )
          | Heading 6

  ToolbarButton(
    :editor="editor"
    title="Clear formatting"
    :action="(chain) => chain.unsetAllMarks()"
  )
    i.fa.fa-eraser

  .btn-group
    ToolbarButton(
      :editor="editor"
      title="Bulleted list"
      activeCheck="bulletList"
      :action="(chain) => chain.toggleBulletList()"
    )
      i.fa.fa-list-ul

    ToolbarButton(
      :editor="editor"
      title="Ordered list"
      activeCheck="orderedList"
      :action="(chain) => chain.toggleOrderedList()"
    )
      i.fa.fa-list-ol

    button.btn.btn-sm.dropdown-toggle(
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      title="Paragraph"
    )
      i.fa.fa-align-left
      span.caret
    .dropdown-menu
      .justify-center
        .btn-group
          ToolbarButton(
            :editor="editor"
            title="Align left"
            :activeCheck="{ textAlign: 'left' }"
            :action="(chain) => chain.setTextAlign('left')"
          )
            i.fa.fa-align-left

          ToolbarButton(
            :editor="editor"
            title="Align center"
            :activeCheck="{ textAlign: 'center' }"
            :action="(chain) => chain.setTextAlign('center')"
          )
            i.fa.fa-align-center

          ToolbarButton(
            :editor="editor"
            title="Align right"
            :activeCheck="{ textAlign: 'right' }"
            :action="(chain) => chain.setTextAlign('right')"
          )
            i.fa.fa-align-right

          ToolbarButton(
            :editor="editor"
            title="Align justify"
            :activeCheck="{ textAlign: 'justify' }"
            :action="(chain) => chain.setTextAlign('justify')"
          )
            i.fa.fa-align-justify

  .btn-group
    ToolbarButton(
      :editor="editor"
      title="Create or edit hyperlink"
      activeCheck="link"
      :action="setLink"
    )
      i.fa.fa-chain

    ToolbarButton(
      :editor="editor"
      title="Remove hyperlink"
      activeCheck="link"
      :action="(chain) => chain.unsetLink()"
    )
      i.fa.fa-chain-broken

  .btn-group
    ToolbarButton(
      :editor="editor"
      title="Create a table"
      :action="(chain) => chain.insertTable({ rows: 3, cols: 3, withHeaderRow: false })"
    )
      i.fa.fa-table
    button.btn.btn-sm.dropdown-toggle(
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      title="Table options"
      :disabled="!this.editor.isActive('table')"
    )
      span.caret
      span.sr-only
        | Toggle Dropdown
    ul.dropdown-menu
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Toggle header row"
          :action="(chain) => chain.toggleHeaderRow()"
        )
          | Header row
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Toggle header column"
          :action="(chain) => chain.toggleHeaderColumn()"
        )
          | Header col
      li
        ToolbarButton(
          tag-type="a"
          :classes="{}"
          :editor="editor"
          title="Toggle header cell"
          :action="(chain) => chain.toggleHeaderCell()"
        )
          | Header cell
</template>

<script>
  import ToolbarButton from './toolbar_button.vue'

  export default {
    components: {
      ToolbarButton
    },

    props: {
      editor: {
        type: Object,
        required: false
      }
    },

    methods: {
      setLink() {
        const previousUrl = this.editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
          return
        }

        // empty
        if (url === '') {
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

          return
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .justify-center {
    display: flex;
    justify-content: center;
  }
</style>
