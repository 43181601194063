<template lang="pug">
bubble-menu(:editor="editor" v-if='editor' :shouldShow='showBubbleMenu' :updateDelay="0" )
  .bubble-menu.btn-toolbar
    .btn-group
      ToolbarButton(
        :editor="editor"
        title="Add column after"
        :classes="btnClasses"
        :action="(chain) => chain.addColumnAfter()"
      )
        Icon(icon="mdi:table-column-plus-after")
      ToolbarButton(
        :editor="editor"
        title="Add column before"
        :classes="btnClasses"
        :action="(chain) => chain.addColumnBefore()"
      )
        Icon(icon="mdi:table-column-plus-before")
      ToolbarButton(
        :editor="editor"
        title="Add row after"
        :classes="btnClasses"
        :action="(chain) => chain.addRowAfter()"
      )
        Icon(icon="mdi:table-row-plus-after")
      ToolbarButton(
        :editor="editor"
        title="Add row before"
        :classes="btnClasses"
        :action="(chain) => chain.addRowBefore()"
      )
        Icon(icon="mdi:table-row-plus-before")
    .btn-group
      ToolbarButton(
        :editor="editor"
        title="Delete row"
        :classes="btnClasses"
        :action="(chain) => chain.deleteRow()"
      )
        Icon(icon="mdi:table-row-remove")
      ToolbarButton(
        :editor="editor"
        title="Delete column"
        :classes="btnClasses"
        :action="(chain) => chain.deleteColumn()"
      )
        Icon(icon="mdi:table-column-remove")
      ToolbarButton(
        :editor="editor"
        title="Delete table"
        :classes="btnClasses"
        :action="(chain) => chain.deleteTable()"
      )
        Icon(icon="mdi:table-large-remove")
</template>

<script>
import { BubbleMenu } from '@tiptap/vue-2'
import { Icon } from '@iconify/vue2';
import ToolbarButton from './toolbar_button.vue'

export default {
  components: {
    BubbleMenu,
    Icon,
    ToolbarButton
  },

  props: {
    editor: {
      type: Object,
      required: false
    },

    changeBus: {
      required: true
    }
  },

  data() {
    return {
      btnClasses: {'btn': true},
      showMenu: true,
    }
  },

  mounted() {
    this.changeBus.$on('change', ({ type }) => {
      this.showMenu = (type === 'click')
    })
  },

  methods: {
    showBubbleMenu() {
      return this.editor.isActive('table') && this.showMenu
    }
  }
}
</script>
