const DEFAULT_PAGINATION_STATE = {
  page: {
    current: 1,
    per: null,
    total: null,
    total_records: null,
    next: null,
    previous: null
  }
}

const getDefaultPaginationState = () => {
  return { ...DEFAULT_PAGINATION_STATE }
}

export { getDefaultPaginationState }
