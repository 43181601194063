<template lang="pug">
div#admin-style-maps-edit
  header
    router-link.btn.btn-info.pull-right(to="/admin/style_maps")
      i.fa.fa-arrow-left
      |
      | Back

    h1 {{ styleMap && styleMap.name }} Style Map

  form(@submit.prevent="save()")
    table.table.table-striped
        col(style="width: 45%;")
        col(style="width: 10%;")
        col(style="width: 45%;")

        thead
          tr
            th Style
            th
            th ICML Character Style

        tbody
          tr(v-for="mapping in styleMappings" :key="mapping.style")
            td
              i.fa(:class="STYLE_ICONS[mapping.style]")
              |
              |
              | {{ mapping.style_name }}
            td
              i.fa.fa-exchange
            td
              input.form-control(v-model="mapping.custom_name")
    footer.text-right
      button.btn.btn-success(type="submit")
        i.fa.fa-save
        |
        | Save
</template>

<script>
  import { sync } from 'vuex-pathify';

  const STYLE_ICONS = {
    bold: 'fa-bold',
    italic: 'fa-italic',
    underlined: 'fa-underline',
    superscript: 'fa-superscript',
    quote: 'fa-quote-right',
    header_1: 'fa-header',
    header_2: 'fa-header',
    header_3: 'fa-header',
    header_4: 'fa-header',
    header_5: 'fa-header',
    header_6: 'fa-header'
  }

  export default {
    props: {
      styleMapId: {
        type: Number,
        required: true
      }
    },

    data() {
      return({
        STYLE_ICONS
      })
    },

    computed: {
      styleMappings: sync('styleMappings/styleMappings'),
      styleMap() {
        return this.$store.getters['styleMaps/forId'](this.styleMapId)
      }
    },

    mounted() {
      this.$store.dispatch('styleMaps/fetchOnce');
      this.$store.dispatch('styleMappings/fetchOnce', this.styleMapId);
    },

    methods: {
      save() {
        const { styleMapId, styleMappings } = this;

        this.$store.dispatch(
          'styleMappings/update',
          { styleMapId, styleMappings }
        );

        this.$router.push({ name: 'adminStyleMaps' });
      }
    }
  }
</script>

<style scoped>
  #admin-style-maps-edit td {
    vertical-align: middle;
  }
</style>
