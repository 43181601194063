<template lang="pug">
span.btn-group
  button.btn.btn-default(
    type="button"
    :class="{ active: isActive('_score') }"
    :disabled="!relevanceSortAvailable"
    @click="sortBy('_score')"
  )
    i.fa.fa-sort-amount-desc
    |
    |
    | Relevance
  button.btn.btn-default(
    type="button"
    :class="{ active: isActive('pub_date') }"
    @click="sortBy('pub_date')"
  )
    i.fa.fa-sort-numeric-desc
    |
    |
    | Pub date
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import { sortParamsForSearch } from '../../../store/shared/sorting'

  // For both options we want higher values to come first
  const SORT_DIR = 'desc'

  export default {
    computed: {
      ...mapGetters('storySearch', ['relevanceSortAvailable']),

      ...sync('storySearch/search@', [
        'text_cont',
        'sort_column',
        'sort_direction'
      ])
    },

    methods: {
      sortBy(column) {
        this.sort_column = column
        this.sort_direction = SORT_DIR
        this.$store.dispatch('stories/search')
      },

      isActive(column) {
        const { sort_column, sort_direction } = sortParamsForSearch(this)

        return sort_column === column && sort_direction === SORT_DIR
      }
    }
  }
</script>
