import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useLocationStore = defineStore('location', {
  state: () => ({
    location: {
      id:  null,
      title: null,
      address_street1: null,
      address_street2: null,
      city: null,
      state: null,
      zip: null,
      phone1: null,
      phone2: null,
      email: null,
      fax: null,
      website: null,
      description: null,
    }
  }),

  actions: {
    async fetch({ locationId }) {
      return axios.get(`/api/internal/locations/${locationId}`)
        .then(({ data: { location } }) => {
          this.location = location
        })
    },

    async save() {
      return this.location.id ? this.update() : this.create()
    },

    async create() {
      const { location } = this

      return axios
        .post('/api/internal/locations', {
          location
        })
        .then((resp) => {
          const { data: { success, location } } = resp

          if (success) {
            this.location = location
            store.dispatch('messages/addNotice', `${location.title} location created`)
          }

          return resp
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)

          throw err
        })
    },

    async update() {
      const { location } = this

      return axios
        .patch(`/api/internal/locations/${location.id}`, {
          location
        })
        .then(({ data: { location }}) => {
          this.location = location
          store.dispatch('messages/addNotice', `${location.title} location saved`)
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    }
  }
})
