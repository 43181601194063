<template lang="pug">
aside
  .panel.panel-default
    template(v-if="selectionType.single")
      .panel-heading
        h4 Details
      .panel-body
        Details

    template(v-if="selectionType.multiple")
      .panel-heading
        h4 Batch Actions
      .panel-body
        BatchForm

    template(v-if="selectionType.none")
      .panel-heading
        h4 Upload a File
      .panel-body
        MediumForm
</template>

<script>
  import { mapGetters } from 'vuex'

  import BatchForm from './batch_form.vue'
  import Details from './details.vue'
  import MediumForm from './form.vue'

  export default {
    components: {
      BatchForm,
      Details,
      MediumForm
    },

    computed: {
      ...mapGetters('media', {
        selectedMedia: 'selected'
      }),

      selectionType() {
        const count = this.selectedMedia.length

        // This may not be a good way to do this, but it reads nice in the template
        return { multiple: count > 1, single: count === 1, none: count === 0 }
      }
    }
  }
</script>
