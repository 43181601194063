<template lang="pug">
tr
  td {{ location.title }}
  td {{ location.address_street1 }}
  td {{ location.city }}
  td {{ location.state }}
  td {{ location.zip }}
  td.col-min
    .btn-group
      router-link.btn.btn-default(:to="`/locations/${ location.id }/edit`")
        i.fa.fa-pencil

      button.btn.btn-danger(@click="destroy()" :disabled="deleting")
        Loader(v-if="deleting" :stacked="false")
        i.fa.fa-trash(v-if="!deleting")
</template>

<script>
  import { mapStores } from 'pinia'
  import { useLocationsStore } from '../../../stores/locations'

  import Loader from '../../../components/shared/loader.vue'

  export default {
    components: {
      Loader
    },

    props: {
      location: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        deleting: false
      }
    },

    computed: {
      ...mapStores(useLocationsStore)
    },

    methods: {
      async destroy() {
        const { location: { id } } = this

        this.deleting = true

        if(confirm('Are you sure you want to delete this location?')) {
          await this.locationsStore.destroy({ id })
        }

        this.deleting = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .col-min {
    width: 1%;
    > .btn-group {
      display: flex;
    }
  }
</style>
