<template lang="pug">
tr
  td.original
    .input-group
      span.input-group-addon <
      TextInput(v-model="rule.original")
      span.input-group-addon >
  td.replacement
    TextOrCharacterInput.start(v-model="rule.replacement_start")
    span.placeholder
      i.fa.fa-ellipsis-h.fa-lg
    TextOrCharacterInput.end(v-model="rule.replacement_end")
  td.actions
    button.btn.btn-success(v-if="isDirty" @click="save()")
      Loader(v-if="saving" :stacked="false")
      i.fa.fa-save(v-if="!saving")
    button.btn.btn-danger(v-if="!isDirty" @click="destroy()")
      Loader(v-if="deleting" :stacked="false")
      i.fa.fa-trash(v-if="!deleting")
</template>

<script>
  import Checkbox from '../../../../components/shared/checkbox.vue'
  import TransformRuleRow from '../mixins/transform_rule_row'

  export default {
    mixins: [TransformRuleRow],

    components: {
      Checkbox
    }
  }
</script>

<style lang="scss">
  td.replacement {
    display: flex;
    justify-content: center;
    align-content: center;

    span.input-group {
      flex-grow: 4;

      &.start {
        text-align: right;
      }
    }

    span.placeholder {
      flex-grow: 1;
      margin-right: 1em;
      margin-left: 1em;
      text-align: center;
    }
  }
</style>
