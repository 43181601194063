<template lang="pug">
aside
  LabelledValue(label="Publication" :value="issue.publication.name")
  LabelledValue(label="Slug" :value="issue.slug")
  LabelledValue(label="Publish date" :value="issue.publish_on")
  LabelledEditableValue(label="Notes" v-model="notes" :multi-line="true" @blur="save")
</template>

<script>
import { sync } from 'vuex-pathify'
import LabelledValue from '../../../components/shared/labelled_value.vue'
import LabelledEditableValue from '../../../components/shared/labelled_editable_value.vue'

export default {
  components: {
    LabelledValue,
    LabelledEditableValue
  },

  computed: {
    issue: sync('issue/issue'),
    notes: sync('issue/issue@notes')
  },

  methods: {
    save() {
      this.$store.dispatch('issue/update')
    }
  }
}
</script>
