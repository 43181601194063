<template lang="pug">
span.input-group
  template(v-if="isCharValue")
    SelectInput(v-model="inputValue", :options="characterOptions")
    span.input-group-btn
      button.btn.btn-default(
        type="button"
        v-on:click="switchToText"
        ref="textModeButton"
        data-toggle="tooltip"
        data-placement="right"
        title="Switch to text input"
      )
        i.fa.fa-align-left
  template(v-if="!isCharValue")
    TextInput(v-model="inputValue")
    span.input-group-btn
      button.btn.btn-default(
        type="button"
        v-on:click="switchToChar"
        ref="charModeButton"
        data-toggle="tooltip"
        data-placement="right"
        title="Switch to special character input"
      )
        i.fa.fa-paragraph
</template>

<script>
  import { head, includes } from 'lodash'
  import SelectInput from '../../../../components/shared/select_input.vue'
  import TextInput from '../../../../components/shared/text_input.vue'

  const NEWLINE = "\n"
  const TAB = "\t"
  const CHAR_NAME_MAP = {
    'Line break': NEWLINE,
    'Tab': TAB
  }
  const CHARS = Object.values(CHAR_NAME_MAP)

  export default {
    components: {
      SelectInput,
      TextInput
    },

    props: {
      type: {
        type: String,
        default: 'text'
      },
      value: {
        type: String
      }
    },

    computed: {
      inputValue: {
        get() { return this.value },
        set(val) { this.$emit('input', val) }
      },

      characterOptions() {
        return CHAR_NAME_MAP
      },

      isCharValue() {
        return includes(CHARS, this.inputValue)
      }
    },

    mounted() {
      this.registerTooltips()
    },

    updated() {
      this.registerTooltips()
    },

    methods: {
      registerTooltips() {
        if(this.$refs.textModeButton) { jQuery(this.$refs.textModeButton).tooltip() }
        if(this.$refs.charModeButton) { jQuery(this.$refs.charModeButton).tooltip() }
      },

      blur() {
        this.$emit('blur')
      },

      switchToChar() {
        this.inputValue = head(CHARS)
      },

      switchToText() {
        this.inputValue = ''
      }
    }
  }
</script>
