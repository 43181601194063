<template lang="pug">
.vue-labelled-value(v-if="hasValue")

  Label(tag="div" :text="label")

  -// TODO: look into displaying children

  Value(
    v-for="val in values"
    :value="val"
    :multi-line="multiLine"
    :html="html"
    :key="val"
  )
</template>

<script>
  import Label from './label.vue'
  import Value from './value.vue'
  import { isEmpty } from 'lodash'

  export default {
    components: {
      Label,
      Value
    },

    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        required: false // not required to allow null
      },
      multiLine: {
        type: Boolean,
        default: false
      },
      html: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      hasValue() {
        return !isEmpty(this.value)
      },

      // Forces values into an array
      values() {
        return [].concat(this.value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vue-labelled-value {
    margin-bottom: 15px;
  }
</style>
