import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useCommunityqStoryTemplatesStore = defineStore('communityqStoryTemplates', {
  state: () => ({
    storyTemplates: [],
    fetchInitiatedPublications: []
  }),

  getters: {
    fetchInitiatedFor: ({ fetchInitiatedPublications }) => ({ publicationId }) => {
      return fetchInitiatedPublications.includes(Number(publicationId))
    },

    byId: ({ storyTemplates }) => (story_template_id) => {
      return storyTemplates.find(({ id }) => id == story_template_id)
    }
  },

  actions: {
    addPublicationToFetchInitiated({ publicationId }) {
      const id = Number(publicationId)

      // Avoid duplicates
      if (this.fetchInitiatedPublications.includes(id)) return

      this.fetchInitiatedPublications.push(id)
    },

    async fetch(publicationId) {
      this.addPublicationToFetchInitiated({ publicationId })

      return axios.get(`/api/internal/publications/${publicationId}/community_q/story_templates`)
        .then(response => {
          this.storyTemplates = response.data.story_templates
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    },

    async fetchOnce(publicationId) {
      if (this.fetchInitiatedFor({ publicationId })) return Promise.resolve()

      return this.fetch(publicationId)
    }
  }
})
