<template lang="pug">
.btn-group
  button.btn.btn-primary.dropdown-toggle(type="button" data-toggle="dropdown")
    Loader(v-if="loading" :stacked="false")
    i.fa.fa-plus(v-if="!loading")
    |
    | New story
    |
    span.caret

  ul.dropdown-menu
    li
      a(@click="createAndGoToStory")
        i.fa.fa-align-right
        |
        | Empty Form
    li
      a
        label.file-label(v-on:change="fileUploadHandler")
          i.fa.fa-desktop
          input.file-input(type="file")
          |
          | File Upload
    li
      GooglePickerButton(tag="a" v-model="storyFileDropStore.file" :mime-types="mimeTypes")
        i.fa.fa-google
        |
        | Google Drive File
  .hidden
</template>

<script>
  import { mapStores } from 'pinia'
  import { useStoryFileDropStore } from '../../../stores/story_file_drop.js'
  import { storyRoute } from '../../../utils/route_helpers.js'
  import GooglePickerButton from '../../../components/shared/google_picker_button.vue'
  import Loader from '../../../components/shared/loader.vue'

  export default {
    components: {
      GooglePickerButton,
      Loader
    },

    props: {
      mimeTypes: {
        type: Array,
        default() {
          return [
            'application/icml',
            'application/pdf',
            'application/rtf',
            'application/msword',
            'application/vnd.google-apps.document',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain'
          ]
        }
      }
    },

    data() {
      return {
        loading: false
      }
    },

    watch: {
      'storyFileDropStore.file': function (val) {
        if(!val) return

        this.googleFilePicked()
      }
    },

    computed: {
      ...mapStores(useStoryFileDropStore)
    },

    methods: {
      async withLoader(func) {
        this.loading = true
        await func()
        this.loading = false
      },

      async googleFilePicked() {
        this.withLoader(async () => {
          const { file } = this.storyFileDropStore
          this.filename = file.name

          const { id } = await this.$store.dispatch(
            'story/create',
            { ...this.routeParams, file, filename: file.name }
          )

          this.goToStory({ id })
        })
      },

      async fileUploadHandler({ target: { files } }) {
        this.withLoader(async () => {
          const file = files[0]
          const { params: { issueId, pageId } } = this.$router.currentRoute

          const { id } = await this.$store.dispatch(
            'story/create',
            {
              issueId, pageId,
              filename: file.name, file
            }
          )

          this.goToStory({ id })
        })
      },

      async createAndGoToStory() {
        this.withLoader(async () => {
          const { id } = await this.$store.dispatch('story/create', this.routeParams())

          this.goToStory({ id })
        })
      },

      routeParams() {
        const { params: { issueId, pageId } } = this.$router.currentRoute

        return { issueId, pageId }
      },

      goToStory({ id }) {
        this.$router.push(storyRoute({
          storyId: id,
          storyRoute: 'editStory',
          issueRoute: 'editIssueStory',
          pageRoute: 'editPageStory',
          router: this.$router
        }))
      }
    }
  }
</script>

<style lang="scss" scoped>
  a, .file-label {
    cursor: pointer;
  }

  .file-label {
    display: block;
    margin-bottom: 0;
    font-weight: normal;
  }

  .file-input {
    display: none;
  }
</style>
