<template lang="pug">
span.vue-labelled-checkbox-root(@click="toggle")
  CheckboxIcon.checkbox(:checked="value")
  |
  |
  Label(tag="div" :text="label" @click="toggle")
</template>

<script>
  import CheckboxIcon from './checkbox_icon.vue'
  import Label from './label.vue'

  export default {
    components: {
      CheckboxIcon,
      Label
    },

    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        type: Boolean,
        required: false // not required to allow null
      }
    },

    methods: {
      toggle() {
        this.$emit('input', !this.value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vue-labelled-checkbox-root {
    margin-left: 5px;
    margin-right: 5px;
    cursor: default;

    .checkbox {
      display: inline-block;
      width: 15px;
      text-align: left;
    }
  }
</style>
