<template lang="pug">
FileDropOverlay(@drop="fileDropHandler")
  slot
  template(v-slot:description)
    p To create a new story from the file's contents.
</template>

<script>
  import { storyRoute } from '../../../utils/route_helpers.js'
  import FileDropOverlay from '../../../components/shared/file_drop_overlay.vue'

  export default {
    components: {
      FileDropOverlay
    },

    methods: {
      async fileDropHandler(files) {
        const file = files[0]
        const { params: { issueId, pageId } } = this.$router.currentRoute

        if(files.length !== 1) {
          this.$store.dispatch('messages/addAlert', 'A story cannot be created from more than one file')
          return
        }

        const { id } = await this.$store.dispatch(
          'story/create',
          {
            issueId, pageId,
            filename: file.name, file
          }
        )

        this.$router.push(storyRoute({
          storyId: id,
          storyRoute: 'editStory',
          issueRoute: 'editIssueStory',
          pageRoute: 'editPageStory',
          router: this.$router
        }))
      }
    }
  }
</script>
