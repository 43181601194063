<template lang="pug">
.vue-labelled-editable-value
  Label(tag="div" :text="label")

  template(v-if="editing && multiLine")
    TextareaInput(v-model="inputValue" @blur="blur" ref="textarea")
  template(v-if="editing && !multiLine" @blur="blur" ref="text")
    TextInput(v-model="inputValue")
  template(v-if="!editing")
    Value(
      :value="value"
      action-icon="pencil"
      @action-clicked="edit"
    )
</template>

<script>
  import Label from './label.vue'
  import Value from './value.vue'
  import TextInput from './text_input.vue'
  import TextareaInput from './textarea_input.vue'

  export default {
    components: {
      Label,
      Value,
      TextInput,
      TextareaInput
    },

    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        type: String,
        required: false // not required to allow null
      },
      multiLine: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        editing: false
      }
    },

    computed: {
      inputValue: {
        get() { return this.value },
        set(val) { this.$emit('input', val) }
      }
    },

    methods: {
      edit() {
        this.editing = true

        this.$nextTick(() => {
          if(this.multiLine) {
            this.$refs.textarea.$el.focus()
          } else {
            this.$refs.text.$el.focus()
          }
        })
      },

      blur() {
        this.editing = false
        this.$emit('blur')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vue-labelled-editable-value {
    margin-bottom: 15px;
  }
</style>
