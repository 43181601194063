<template lang="pug">
form(@submit.prevent="save")
  .row
    .col-md-7
      .form-group
        Label(text="Title")
        Input(v-model="squeue.title")

      .form-group
        Label(text="Code")
        Input(v-model="squeue.slug" :disabled="squeue.system_record")

      LabelledCheckbox(label="Enabled" v-model="squeue.enabled")

    .col-md-5
      .form-group
        Label(text="Color")
        button.color-button.btn.btn-block(type="button" :style="{ 'background-color': color }" @click="toggleColorPicker")
          | &nbsp;
          span.text-muted(v-if="isDefaultColor") click to set
        ColorPicker.color-picker(v-model="color" v-if="pickingColor")

      .form-group
        Label(text="Auto-triggered web export")
        SelectInput(v-model="squeue.trigger_web_export" :options="WEB_EXPORT_OPTIONS")

  .row
    .col-md-12
      hr

  .row
    .col-md-7
      button.btn.btn-primary(type="submit") Save
      router-link.btn.btn-link.text-muted(to="/admin/squeues") Cancel

    .col-md-5.text-right
      a.btn.btn-default(v-if="squeue.id && !squeue.system_record" type="button" @click="destroy")
        i.fa.fa-trash.text-muted
</template>

<script>
  import { Swatches } from 'vue-color'

  import Input from '../../../../components/shared/input.vue'
  import Label from '../../../../components/shared/label.vue'
  import LabelledCheckbox from '../../../../components/shared/labelled_checkbox.vue'
  import SelectInput from '../../../../components/shared/select_input.vue'

  // Set the default color to being transparent.
  const DEFAULT_COLOR = '#FFFFFF00'
  // NOTE: these values need to match exactly what's in the Squeue model
  const WEB_EXPORT_OPTIONS = {
    'Off': 'off',
    'Publish immediately': 'publish_immediately',
    'Export unpublished': 'export_unpublished'
  }

  export default {
    components: {
      Input,
      Label,
      LabelledCheckbox,
      ColorPicker: Swatches,
      SelectInput
    },

    props: {
      squeue: {
        required: true
      }
    },

    data() {
      return {
        pickingColor: false
      }
    },

    computed: {
      color: {
        // vue-color doesn't like null values. So we use a transparent default instead.
        // See https://github.com/xiaokaike/vue-color/pull/234
        get() { return this.squeue.color || DEFAULT_COLOR },
        // vue-color returns an object with the color in various formats, but we just want the hex8 one.
        set(val) {
          this.squeue.color = val?.hex8
          this.pickingColor = false
        }
      },

      isDefaultColor() {
        return this.color === DEFAULT_COLOR
      }
    },

    created() {
      this.WEB_EXPORT_OPTIONS = WEB_EXPORT_OPTIONS
    },

    methods: {
      toggleColorPicker() {
        this.pickingColor = !this.pickingColor
      },

      async save() {
        await this.$store.dispatch('squeue/save')

        this.$router.push({ name: 'adminSqueues' })
      },

      async destroy() {
        const { title } = this.squeue

        if(confirm(`Sure you want to delete ${title}?`)) {
          await this.$store.dispatch('squeue/destroy')

          this.$router.push({ name: 'adminSqueues' })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .color-button {
    border: 1px solid #ccc;
  }

  .color-picker {
    position: absolute;
    z-index: 100;
  }
</style>
