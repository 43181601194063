<template lang="pug">
tr(:class="{ 'changed': changed }" :key="story.updated_at")
  td(v-if="showCol('color')" :style="colorColColor")
  td(v-if="showCol('checkbox') && !currentUserStore.currentUser.role_is_limited" :style="checkboxColColor")
    i.fa.fa-lock(v-if="locked")
    CheckBox(:unique-key="story.id" v-model="storySelected" v-if="!locked")

  td(v-if="showCol('id')")
    | {{ story.id }}

  td(v-if="showCol('pub_date')" :title="story.pub_date")
    | {{ story.pub_date }}

  td(v-if="showCol('slug')")
    a(@click="goToShow()")
      | {{ story.slug }}

  td(v-if="showCol('name')" v-html="story.name")

  td(v-if="showCol('sections')")
    | {{ story.sections.join(', ') }}

  td(v-if="showCol('web_categories')")
    | {{ story.web_categories.join(', ') }}

  td.text-center(v-if="showCol('words')")
    | {{ story.words }} / {{ story.inches }}

  td.text-center(v-if="showCol('media')")
    a(@click="goToShow({ hash: 'media' })" v-if="story.media_count > 0")
      span(v-if="story.image_count > 0")
        i.fa.fa-file-image-o
        |
        |
        | {{ story.image_count }}
      |
      |
      span(v-if="nonImageCount > 0")
        i.fa.fa-file-o
        |
        |
        | {{ nonImageCount }}

  td(v-if="showCol('exported')")
    CheckboxIcon(:checked="story.exported")

  td(v-if="showCol('squeue')")
    SqueueField(
      :value="story.squeue_id"
      @input="squeueIdChanged"
      :required="true"
      :show-label="false"
      :disabled="locked"
    )

  td.actions.text-center(v-if="showCol('actions')")
    .btn-group.action-buttons
      EditButton(:story-id="story.id")
      MoreButton(
        :story-id="story.id"
        :story-name="story.name"
        :locked="locked"
        v-on="$listeners"
      )
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { mapStores } from 'pinia'
  import { useCurrentUserStore } from '../../../stores/current_user.js'
  import { useStoryBatchOptionsStore } from '../../../stores/story_batch_options.js'
  import { pick, isEqual } from 'lodash'

  import CheckBox from './check_box.vue'
  import CheckboxIcon from '../../../components/shared/checkbox_icon.vue'
  import EditButton from '../actions/edit_button.vue'
  import MoreButton from '../actions/more_button.vue'
  import SqueueField from '../../shared/squeue_field.vue'
  import { storyRoute } from '../../../utils/route_helpers.js'
  import { COLUMNS, WATCHED_PROPERTIES } from './stories_table.vue'

  // This time should match the backgroundPulse delay in the <style> section below
  const ROW_FADE_TIME = 2000

  export default {
    components: {
      CheckBox,
      CheckboxIcon,
      EditButton,
      MoreButton,
      SqueueField
    },

    props: {
      story: {
        type: Object,
        required: true
      },
      columns: {
        type: Array,
        default: () => COLUMNS
      }
    },

    data() {
      return {
        changed: false,
        previousStory: null
      }
    },

    watch: {
      story(updatedStory) {
        const newStory  = pick(updatedStory, WATCHED_PROPERTIES)

        // Nothing we're tracking actually changed, so just ignore it
        if (isEqual(pick(this.previousStory, COLUMNS), pick(newStory, COLUMNS))) return

        this.changed = true
        this.previousStory = newStory

        setTimeout(() => this.changed = false, ROW_FADE_TIME)
      },

      locked() {
        this.storySelected = false
      }
    },

    computed: {
      ...mapStores(
        useCurrentUserStore,
        useStoryBatchOptionsStore
      ),

      storySelected: {
        get() { return this.storyBatchOptionsStore.checked(this.story.id) },
        set(selected) { this.storyBatchOptionsStore.set(this.story.id, selected) }
      },

      nonImageCount() {
        return this.story.media_count - this.story.image_count;
      },

      // If there is an explicit color column, color its cells
      colorColColor() {
        if (!this.showCol('color')) return {}

        return { 'background-color': this.story.color }
      },

      // If there is no explicit color column, then color the checkbox cells
      checkboxColColor() {
        if (this.showCol('color')) return {}

        return { 'background-color': this.story.color }
      },

      locked() {
        if(!this.story.locker) return false

        return this.story.locker.id !== this.currentUserStore.currentUser.id
      }
    },

    mounted () {
      this.previousStory = pick(this.story, WATCHED_PROPERTIES)
    },

    methods: {
      showCol(column) {
        return this.columns.includes(column)
      },

      goToShow(additionalRouteArgs = {}) {
        this.$router.push({
          ...storyRoute({
            storyRoute: 'story',
            issueRoute: 'issueStory',
            pageRoute: 'pageStory',
            storyId: this.story.id,
            router: this.$router
          }),
          ...additionalRouteArgs
        })
      },

      squeueIdChanged(squeueId) {
        if(this.story.squeue_id === squeueId) return

        const { id } =  this.story
        this.$store.dispatch('stories/updateSqueue', { id, squeueId })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /*
    Links without an href have a text select pointer by default,
    but we want them to act just like links.
  */
  a {
    cursor: pointer;
  }

  td {
    vertical-align: middle !important;
  }

  td.actions {
    // Prevent the buttons from wrapping
    min-width: 100px;
  }

  tr td .form-group {
    margin: 0;
  }

  tr.changed {
    // This time should match ROW_FADE_TIME in the JS
    animation: backgroundPulse 2s;
  }

  @keyframes backgroundPulse {
    30%, 70% { background-color: #d9edf7; }
  }
</style>
