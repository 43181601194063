<template lang="pug">
ul.list-group.placement-dropdown(:class="{ opened: opened }")
  Placement(:placement="placement")
    a.open(@click="openedValue = !openedValue")
      i.fa(:class="{ 'fa-angle-down': !opened, 'fa-angle-up': opened }")
  .placement-dropdown-dropdown
    template(v-for="pub in publications" v-if="opened && publicationIssues(pub).length > 0")
      li.list-group-item.disabled
        strong {{ pub.name }}
      template(v-for="issue in publicationIssues(pub)")
        IssueRow(v-model="inputValue" :issue="issue")
        PageRow(
          v-for="page in issuePages(issue)"
          v-if="issueId === issue.id"
          v-model="inputValue"
          :page="page"
          :key="issue.id"
        )
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import Placement from '../../placement/index.vue'
  import IssueRow from './issue_row.vue'
  import PageRow from './page_row.vue'

  export default {
    components: {
      Placement,
      IssueRow,
      PageRow
    },

    props: {
      value: {
        type: Object,
        required: true
      },
      opened: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      publications: sync('publications/publications'),
      ...mapGetters('issues', { publicationIssues: 'forPublication' }),
      ...mapGetters('pages', { issuePages: 'forIssue' }),
      placement() { return this.value },
      issueId() { return this.placement.issue_id },
      pageId() { return this.placement.page_id },
      inputValue: {
        get() { return this.value },
        set(val) { this.$emit('input', val) }
      },
      openedValue: {
        get() { return this.opened },
        set(val) { this.$emit('opened', val) }
      }
    },

    watch: {
      open(opened) {
        if(opened && this.issueId) {
          this.$store.dispatch('pages/fetchOnce', { issueId: this.issueId })
        }
      },

      value(newVal, oldVal) {
        const issueId = newVal.issue_id

        if(issueId && issueId !== oldVal.issue_id) {
          this.$store.dispatch('pages/fetchOnce', { issueId })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .placement-dropdown {
    position: relative;

    a.open {
      float: right;
      cursor: pointer;
    }

    &.opened .placement-dropdown-dropdown {
      position: absolute;
      width: 100%;
      z-index: 100;
      box-shadow: 3px 3px 5px #ddd;
    }
  }
</style>
