import { defineStore } from 'pinia'
import axios from 'axios'
import { withIDKeys } from '../utils/state_helpers'
import { snakeKeyed } from '../utils/params_helpers'
import { del } from 'vue'
import store from '../store'
import PaginationStorage from '../utils/pagination_storage'

import { getDefaultPaginationState } from '../store/shared/pagination'

export const useLocationsStore = defineStore('locations', {
  state: () => ({
    ...getDefaultPaginationState(),
    searchResults: {},
  }),

  actions: {
    async search({ search, page, perPage, newDefaultPerPage }) {
      let pageStorage = new PaginationStorage({ page, perPage, newDefaultPerPage })
      pageStorage.save()

      return axios.get('/api/internal/locations', {
        params: { search, ...snakeKeyed(pageStorage.queryParams) }
      }).then(({ data: { locations, meta: { page }} }) => {
        this.searchResults = withIDKeys(locations)
        this.page = page
      })
    },

    async destroy({ id }) {
      return axios.delete(`/api/internal/locations/${id}`)
        .then(() => {
          del(this.searchResults, id)
          store.dispatch('messages/addNotice', 'Location deleted')
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    }
  }
})
