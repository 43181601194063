<template lang="pug">
div
  // TODO: this probably needs a list of locations, with a search bar and a new button that would pop up the location form in a dialog.
  // Or maybe just the /locations/new page if we're in a rush for now.
</template>

<script>
  export default {
    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    // TODO: everything else
  }
</script>
