<template lang="pug">
.loader-overlay
  div(v-if="loading" :class="cssClasses")
    Loader.loader(size="5x")
  slot
</template>

<script>
  import Loader from './loader.vue'

  export default {
    components: {
      Loader
    },

    props: {
      loading: {
        type: Boolean,
        default: () => false
      }
    },

    computed: {
      cssClasses() {
        if(!this.loading) return {}

        return { "show-loading-overlay": true }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .loader-overlay {
    position: relative;

    .show-loading-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
    }

    .loader {
      opacity: 0.7;
    }
  }

</style>
