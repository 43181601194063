<template lang="pug">
.locations
  .row
    .col-md-12
      header
        .pull-right
          router-link.btn.btn-primary(to="/locations/new")
            i.fa.fa-plus
            |
            | New Location
        h1 Locations

  .row
    .col-md-12
      section.well.well-sm
        form.inline-form(v-on:submit.prevent="fetchSearchResults")
          .input-group
            input.form-control(type="search" placeholder="Search" v-model="search" title="Search")
            span.input-group-btn
              button.btn.btn-prominent(type="button" v-on:click="fetchSearchResults()")
                | Search

  LoaderOverlay(:loading="loading")
    div(v-if="locations.length < 1")
      .alert.alert-info No locations were found

    template(v-else)
      Table(:locations="locations")

      nav.text-centernav.text-center
        Pagination(
          :current="locationsStore.page.current"
          :per="locationsStore.page.per"
          :total-records="locationsStore.page.total_records"
          @change="changePage"
        )

        PerPageSelect(:value="locationsStore.page.per" @input="changeDefaultPerPage")
</template>

<script>
  import { mapStores } from 'pinia'
  import { useLocationsStore } from '../../../stores/locations'

  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import Pagination from '../../../components/shared/pagination.vue'
  import PerPageSelect from '../../../components/shared/per_page_select.vue'
  import Table from './table.vue'

  export default {
    components: {
      LoaderOverlay,
      Pagination,
      PerPageSelect,
      Table
    },

    data() {
      return {
        search: '',
        loading: false
      }
    },

    computed: {
      ...mapStores(useLocationsStore),

      locations() {
        return Object.values(this.locationsStore.searchResults)
      }
    },

    async mounted() {
      this.loading = true

      await this.fetchSearchResults()

      this.loading = false
    },

    methods: {
      async fetchSearchResults({ page = 1, newDefaultPerPage } = {}) {
        const { search } = this

        this.loading = true

        await this.locationsStore.search({ search, page, newDefaultPerPage })

        this.loading = false
      },

      changePage(page) {
        this.fetchSearchResults({ page })
      },

      changeDefaultPerPage(newDefaultPerPage) {
        this.fetchSearchResults({ page: this.locationsStore.page.current, newDefaultPerPage});
      },
    }
  }
</script>
