<template lang="pug">
.modal.fade(tabindex="-1" data-backdrop="static")
  .modal-dialog(style="width: 550px")
    .modal-content
      form(v-on:submit.prevent="send")
        .modal-header
          button.close(type="button" @click="hide")
            i.fa.fa-times
          h3 Email This Story
        .modal-body
          .form-group
            label(for="to_field") To
            input#to_field.form-control(type="email" v-model="emailExportingStore.to")

          .form-group
            label(for="subject_field") Subject
            input#subject_field.form-control(type="text" v-model="emailExportingStore.subject")

          .form-group
            label(for="notes_field") Additional Notes
            textarea#notes_field.form-control(v-model="emailExportingStore.notes")

        .modal-footer
          button.btn.btn-link.text-muted(@click="hide" type="button" :disabled="submitting")
            | Cancel
          button.btn.btn-primary(type="submit" v-if="!submitting") Send Email
          button.btn.btn-default(type="button" disabled v-else)
            i.fa.fa-circle-o-notch.fa-spin
            |
            | Sending Email
</template>

<script>
  import { mapStores } from 'pinia'
  import { useEmailExportingStore } from '../../stores/email_exporting'

  export default {
    data() {
      return {
        submitting: false
      }
    },

    computed: {
      ...mapStores(useEmailExportingStore),
    },

    watch: {
      'emailExportingStore.storyId': function (id) {
        if(id) {
          $(this.$el).modal({show: true})
        } else {
          $(this.$el).modal('hide')
        }
      }
    },

    methods: {
      hide() {
        this.emailExportingStore.storyId = null
      },

      send() {
        this.submitting = true;

        this.emailExportingStore.send().then(() => {
          this.submitting = false
        }).catch(() => {
          this.submitting = false
        })

        this.submitting = true
      }
    }
  }
</script>
