import axios from 'axios'
import { make } from 'vuex-pathify'
import { isEmpty, omit } from 'lodash'
import { stateFingerprint } from '../../utils/state_helpers'
import { useCurrentUserStore } from '../../stores/current_user'
import { useCustomFoldersStore } from '../../stores/custom_folders'
import { useExportFormatsStore } from '../../stores/export_formats'
import { useUnrecognizedFilesStore } from '../../stores/unrecognized_files'
import { useWebCategoriesStore } from '../../stores/web_categories'


const FINGERPRINT_OMITTED_FIELDS = ['cleanFingerprint', 'editingAttachments', 'locker']

function update({ state, rootGetters }) {
  let promise
  promise = axios.patch(
    `/api/internal/stories/${state.id}`, stateToFormData(state, rootGetters)
  )
  return promise
}

// NOTE: this was originally setup to use formData to support file uploads, but at the moment
// we're not even using it for that, so JSON may be a better option.
function stateToFormData(state, rootGetters) {
  let formData = new FormData()

  // Simple Params
  const simpleParams = [
    'user_id', 'squeue_id', 'slug', 'tag', 'keywords', 'headline',
    'drop_head', 'web_head', 'overline', 'infobox_headline', 'byline', 'content',
    'web_summary', 'infobox_text', 'pub_date', 'featured', 'comments_disabled',
    'freelance', 'ispublic', 'notes', 'credits', 'endnote', 'file_mode', 'twitter_feed',
    'tweet', 'google_search_ad', 'outbrain_ad', 'web_body', 'web_slug'
  ]

  simpleParams.forEach( param => {
    if(state.hasOwnProperty(param)) {
      let value = state[param]

      if(value === null || value === undefined) { value = "" }

      formData.append(`story[${param}]`, value)
    }
  })

  // Arrays //
  state.web_category_ids.forEach((webCategoryId) => {
    formData.append("story[web_category_ids][]", webCategoryId)
  })
  if(isEmpty(state.web_category_ids)) {
    formData.append("story[web_category_ids][]", '')
  }

  state.section_ids.forEach((sectionId) => {
    formData.append("story[section_ids][]", sectionId )
  })
  if (isEmpty(state.section_ids)) {
    formData.append("story[section_ids][]", '')
  }

  state.export_format_ids.forEach((exportFormatId) => {
    formData.append("story[export_format_ids][]", exportFormatId)
  })
  if (isEmpty(state.export_format_ids)) {
    formData.append("story[export_format_ids][]", '')
  }

  // Objects //

  // Pull quote
  const headline = rootGetters['pullQuote/headline']
  const quote = rootGetters['pullQuote/quote']
  const attribution = rootGetters['pullQuote/attribution']

  formData.append('story[pull_quote_attributes][headline]', headline)
  formData.append('story[pull_quote_attributes][quote]', quote)
  formData.append('story[pull_quote_attributes][attribution]', attribution)

  // Story placements
  const storyPlacements = rootGetters['storyPlacements/storyPlacements']
  storyPlacements.forEach((placement, i) => {
    const { issue_id, page_id, _destroy } = placement

    // These are just new blank records we can ignore
    if (!issue_id && !page_id && !_destroy) return

    ['id', 'issue_id', 'page_id', '_destroy'].forEach(param => {
      formData.append(
        `story[story_placements_attributes][${i}][${param}]`,
        placement[param] || ''
      )
    })
  })

  return formData
}

const getDefaultState = () => {
  return {
    id: null,
    squeue_id: null,
    user_id: null,
    slug: null,
    tag: null,
    keywords: null,
    headline: null,
    drop_head: null,
    web_head: null,
    overline: null,
    infobox_headline: null,
    byline: null,
    content: null,
    web_summary: null,
    web_slug: null,
    web_body: null,
    infobox_text: null,
    pub_date: null,
    featured: null,
    comments_disabled: null,
    ispublic: null,
    notes: null,
    credits: null,
    endnote: null,
    freelance: null,
    section_ids: [],
    web_category_ids: [],
    export_format_ids: [],
    content_path: null,
    open_folder_url: null,
    file_mode: 'per_field',
    twitter_feed: null,
    tweet: null,
    google_search_ad: null,
    outbrain_ad: null,
    saved: false,
    locker: {
      id: null,
      name: null
    },
    updated_at: null,

    // NON-DB STATE //

    editingAttachments: false,

    // Unique finger print of the object
    cleanFingerprint: null
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  exceptFingerprintOmitted: state => omit(state, FINGERPRINT_OMITTED_FIELDS),

  isDirty: ({ cleanFingerprint }, getters) => {
    const currentPrint = stateFingerprint(getters['exceptFingerprintOmitted'])
    return (currentPrint !== cleanFingerprint)
  },

  lockedToUser({ locker }) {
    const currentUserStore = useCurrentUserStore()

    return locker?.id && locker?.id !== currentUserStore.currentUser?.id
  },

  // TODO: these camel cased getters don't match the snake-cased ones
  // vuex-pathify would make. So we need to work that out on one side
  // or another.
  pubDate: state => state.pub_date,
  fileMode: state => state.file_mode,

  // Read-only getters
  spiked: state => state.spiked,
  contentPath: state => state.content_path,
  openFolderUrl: state => state.open_folder_url,
  updatedAt: state => state.updated_at,
  singleFileMode: state => state.file_mode == 'single',
  perFieldFileMode: state => state.file_mode == 'per_field'
}

// mutations
const mutations = {
  ...make.mutations(state),

  SET(state, story) { Object.assign(state, story) },
  UPDATE_PUB_DATE(state, pubDate) { state.pub_date = pubDate || null },
  UPDATE_FILE_MODE(state, fileMode) { state.file_mode = fileMode || null },
  RESET(state) { Object.assign(state, getDefaultState()) },
  RESET_DIRTY(state) {
    state.cleanFingerprint = stateFingerprint(omit(state, FINGERPRINT_OMITTED_FIELDS))
  },
  PATCH(state, partialData) {
    Object.assign(state, partialData)
  },

}

// actions
const actions = {
  ...make.actions(state),

  async fetch({ commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      axios.get(
        `/api/internal/stories/${id}`
      ).then(response => {
        commit('SET', response.data.story)
        commit('RESET_DIRTY')
        resolve(response.data.story)
      }).catch(err => {
        dispatch('messages/smartAdd', err, {root: true})
        reject(err)
      })
    })
  },

  async fetchDependentData({ state: { id }, dispatch}) {
    const customFoldersStore = useCustomFoldersStore()
    const exportFormatsStore = useExportFormatsStore()
    const unrecognizedFilesStore = useUnrecognizedFilesStore()
    const webCategoriesStore = useWebCategoriesStore()
    const storyId = id
    const root = true

    return Promise.all([
      // Story associations
      customFoldersStore.fetchForStory(id),
      dispatch('storyMedia/fetchForStory', id, { root }),
      unrecognizedFilesStore.fetchForStory(id),
      dispatch('storyPublications/fetch', {storyId }, { root }),
      dispatch('storyPlacements/fetch', {storyId }, { root }),
      dispatch('pullQuote/fetchForStory', { storyId }, { root }),

      // General, global data dependencies
      exportFormatsStore.fetchOnce(),
      dispatch('exportFormats/fetchOnce', null, { root }),
      dispatch('publications/fetchOnce', null, { root }),
      dispatch('sections/fetchOnce', null, { root }),
      dispatch('squeues/fetchOnce', null, { root }),
      dispatch('users/fetchOnce', null, { root }),
      webCategoriesStore.fetchOnce(),
      dispatch('subsites/fetchOnce', null, { root }),
    ])
  },

  async create({ commit }, { issueId, pageId, path, filename, file, base64Url }) {
    let formData = new FormData()
    if (issueId) formData.append('issue_id', issueId)
    if (pageId) formData.append('page_id', pageId)
    if (path) formData.append('path', path)
    if (filename) formData.append('filename', filename)
    if (file) formData.append('file', file)
    if (base64Url) formData.append('base64_url', base64Url)

    // Sending an empty form post will result in a Rack::Multipart::EmptyContentError exception.
    // So instead, we'll pass null which will tell Axios to do a post with no body.
    if(formData.entries().next().done === true ) {
      formData = null
    }

    return axios.post(
      '/api/internal/stories/', formData
    ).then(response => {
      commit('SET_ID', response.data.story.id)
      return response.data.story
    })
  },

  async save({ state, commit, dispatch, rootGetters }) {
    const promise = update({ commit, state, rootGetters })

    return promise.then(({ data }) => {
      commit('SET', data.story)
      commit('RESET_DIRTY')
      dispatch('fetchDependentData', { dispatch, response: { data } })
      dispatch('messages/smartAdd', data, { root: true })
    }).catch(err => {
      dispatch('messages/smartAdd', err, {root: true})
      throw err
    })
  },

  async spike({ state, commit }) {
    return axios.delete(
      `/api/internal/stories/${state.id}`
    ).then((response) => {
      commit('RESET')

      return response
    })
  },

  async unlock({ state: { id }, commit }) {
    return axios.delete(
      `/api/internal/stories/${id}/lock`
    ).then(({ data: { locker } }) => {
      commit('SET_LOCKER', locker)
    })
  },

  // Use this on a page unload. sendBeacon is generally meant for analytics, so the browser gives it
  // special treatment by letting it complete, where it would otherwise cancel a normal request.
  unlockOnLeave({ state: { id } }) {
    const csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')

    const data = new FormData()
    data.append('_method', 'delete')
    data.append('authenticity_token', csrfToken)

    navigator.sendBeacon(`/api/internal/stories/${id}/lock`, data)
  },

  async overrideLock({ state: { id }, commit }) {
    return axios.post(
      `/api/internal/stories/${id}/lock`
    ).then(({ data: { locker } }) => {
      commit('SET_LOCKER', locker)
    })
  },

  async autoSetPubDateFromIssue({ commit, dispatch, rootGetters }, { issueId }) {
    // Might as well see if we alread have it loaded
    let issue = rootGetters['issues/forId'](issueId)

    // If it's not loaded, fetch it individually
    if (!issue) {
      issue = await dispatch('issue/fetch', { issueId }, { root: true })
    }

    commit('UPDATE_PUB_DATE', issue.publish_on)
  },

  update
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
