import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  publications: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ publications }) {
    return publications
  },

  allAsObject({ publications }) {
    const entries = publications.map((pub) => {
      return [pub.name, pub.id]
    })

    return Object.fromEntries(entries)
  },

  multiple({ publications }) {
    return publications.length > 1
  },

  forId: ({ publications }) => (id) => {
    return publications.find(pub => pub.id === id)
  },

  forStoryPublication: ({ publications }) => (storyPublication) => {
    return publications.find(
      pub => pub.id === storyPublication.publication_id
    )
  }
}

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  ...make.actions(state),

  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios
      .get('/api/internal/publications')
      .then(response => {
        dispatch('setPublications', response.data.publications)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  },

  fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
