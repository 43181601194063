import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useCommunityqPublishingStore = defineStore('communityqPublishing', {
  state: () => ({
    storyIds: [],
    when: 'dont',
    pubTime: new Date()
  }),

  actions: {
    async submit() {
      // Accommodate for a bug where the state isn't correctly being set as the modal loads
      if( this.when == 'dont' ){
        this.pubTime = null
      }

      return axios.patch('/api/internal/communityq_stories/batch_update', {
        community_q_stories: {
          ids: this.storyIds,
          web_publish_on: this.pubTime
        }
      }).then(({ data }) => {
        store.dispatch('messages/addNotice', data.message)
        this.$reset()
        return data
      }).catch(err => {
        store.dispatch('messages/addError', err)
      })
    }
  }
})
