const TabStorage = {
  fetch(key) {
    const storeValue = window.sessionStorage.getItem(key)

    if (storeValue) {
      return JSON.parse(storeValue)
    } else {
      return null
    }
  },

  set(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
}

export default TabStorage
