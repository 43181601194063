import { mapKeys, snakeCase, camelCase } from 'lodash'

const snakeKeyed = (object) => {
  return mapKeys(object, (_val, key) => {
    return snakeCase(key)
  })
}

const camelKeyed = (object) => {
  return mapKeys(object, (_val, key) => {
    return camelCase(key)
  })
}

export {
  snakeKeyed,
  camelKeyed
}
