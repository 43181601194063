import qs from 'qs'
import { pickBy, identity, omit } from 'lodash'
import TabStorage from './tab_storage'

// For now this is hard-coded but if we need pagination storage for other
// pages in the future, this should probably have a dynamic prefix.
const PAGINATION_SETTINGS_KEY = 'pagination-settings'

class PaginationStorage {
  constructor({ page, perPage, newDefaultPerPage }) {
    this._settings = { page, perPage, newDefaultPerPage }
  }

  get page() {
    return this._findSetting('page')
  }

  get perPage() {
    return this.newDefaultPerPage || this._findSetting('perPage')
  }

  get newDefaultPerPage() {
    return this._findSetting('newDefaultPerPage')
  }

  get params() {
    return pickBy(this._normalized, identity)
  }

  get queryParams() {
    const prms = this.params

    if(prms.newDefaultPerPage) prms.perPage = null

    return pickBy(prms, identity)
  }

  save() {
    TabStorage.set(PAGINATION_SETTINGS_KEY, omit(this.params, ['newDefaultPerPage']))
  }

  // Private

  _findSetting(settingName) {
    return this._settings[settingName] || this._querySettings[settingName] || this._storedSettings[settingName] || null
  }

  _normalizeNumber(number) {
    return (parseInt(number) || null)
  }

  get _normalized() {
    return {
      page: this._normalizeNumber(this.page),
      perPage: this._normalizeNumber(this.perPage),
      newDefaultPerPage: this._normalizeNumber(this.newDefaultPerPage)
    }
  }

  get _querySettings() {
    const {page, per_page } = qs.parse(
      window.location.search,
      { ignoreQueryPrefix: true }
    )

    return { page, perPage: per_page }
  }

  get _storedSettings() {
    return TabStorage.fetch(PAGINATION_SETTINGS_KEY) || {}
  }
}

export default PaginationStorage
