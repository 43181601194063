import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useUnrecognizedFilesStore = defineStore('unrecognizedFiles', {
  state: () => ({
    unrecognizedFiles: []
  }),

  actions: {
    async fetchForStory(storyId) {
      return axios.get(`/api/internal/stories/${storyId}/unrecognized_files`)
        .then(response => {
          this.unrecognizedFiles = response.data.story_files
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    }
  }
})
