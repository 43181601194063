<template lang="pug">
.form-group
  label.control-label Print Section
  select.form-control(v-model="sectionId")
    option(v-for="section in sections" :value="section.id") {{ section.title }}
</template>

<script>
  export default {
    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      }
    },

    data() {
      return {
        sectionId: null
      }
    },

    computed: {
      sections() { return this.$store.getters['sections/enabled'] }
    },

    watch: {
      value() {
        this.sectionId = this.value
      },

      sectionId() {
        // For component v-model support
        this.$emit('input', this.sectionId);
      }
    },

    mounted() {
      this.$store.dispatch('sections/fetch')
      this.sectionId = this.value
    }
  }
</script>
