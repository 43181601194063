const SCHEME = 'gid'
const DEFAULT_APP = 'newsroom-q'

class GlobalId {
  constructor({ app = DEFAULT_APP, model, id }) {
    this.app = app
    this.model = model
    this.id = id
  }

  toString() {
    return `${SCHEME}://${this.app}/${this.model}/${this.id}`
  }
}

export { GlobalId }
