<template lang="pug">
span
  template(v-if="loading")
    .input-group
      input.form-control(
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :disabled="true"
        :readonly="true"
        @change="change"
        @blur="blur"
      )
      .input-group-addon
        Loader(:stacked="false")
  template(v-if="!loading")
    input.form-control(
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      @change="change"
      @blur="blur"
    )
</template>

<script>
  import Loader from './loader'

  export default {
    components: {
      Loader
    },

    props: {
      value: null,
      type: {
        type: String,
        default: () => 'text'
      },
      placeholder: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        default: () => false
      },
      required: {
        type: Boolean,
        default: () => false
      },
      readonly: {
        type: Boolean,
        default: () => false
      },
      loading: {
        type: Boolean,
        default: () => false
      },
      stopChangePropagation: {
        type: Boolean,
        default: () => false
      }
    },

    methods: {
      change(event) {
        if(this.stopChangePropagation) event.stopPropagation()

        this.$emit('input', event.target.value);
      },
      blur(event) {
        this.$emit('blur', event.target.value);
      }
    }
  }
</script>
