<template lang="pug">
LoaderOverlay(:loading="loading")
  LocationForm
</template>

<script>
  import { mapStores } from 'pinia'
  import { useLocationStore } from '../../../stores/location'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import LocationForm from '../shared/form.vue'

  export default {
    components: {
      LoaderOverlay,
      LocationForm
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      ...mapStores(useLocationStore)
    },

    async mounted() {
      this.loading = true

      await this.locationStore.$reset()

      this.loading = false
    }
  }
</script>
