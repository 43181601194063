<template lang="pug">
tr(v-bind:key="transform.id")
  td
    router-link(:to="`/admin/transforms/${transform.id}/edit`")
      | {{ transform.name }}
  td
    | {{ transform.transform_rules.length }}
  td.text-right
    .btn-group
      router-link.btn.btn-default(:to="`/admin/transforms/${transform.id}/edit`")
        i.fa.fa-pencil

      button.btn.btn-danger(@click="destroy()")
        Loader(v-if="deleting" :stacked="false")
        i.fa.fa-trash(v-if="!deleting")
</template>

<script>
  import Loader from '../../../../components/shared/loader.vue'

  export default {
    components: {
      Loader
    },

    props: {
      transform: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        deleting: false
      }
    },

    methods: {
      async destroy() {
        this.deleting = true

        await this.$store.dispatch('transforms/destroy', this.transform.id);

        this.deleting = false
      }
    }
  }
</script>
