<template lang="pug">
i.fa(:class="{ 'fa-check-square-o': checked, 'fa-square-o': !checked, ...sizeObject }")
</template>

<script>
  export default {
    props: {
      checked: {
        type: Boolean,
        required: false // required is false to allow null values
      },

      // This should map to the Font Awesome sizes: lg, 2x, 3x, 4x, 5x
      size: {
        type: String,
        required: false,
        default: () => { null }
      }
    },

    computed: {
      sizeObject() {
        if(!this.size) return {}

        return { [`fa-${this.size}`]: true }
      }
    }
  }
</script>
