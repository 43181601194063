<template lang="pug">
.form-group(v-if="currentUserStore.currentUser.can_change_ownership")
  label.control-label Owner
  select.form-control(v-model="userId")
    option(v-for="user in $store.getters['users/all']" :value="user.id") {{ user.name }}
</template>

<script>
  import { mapStores } from 'pinia'
  import { useCurrentUserStore } from '../../../stores/current_user'

  export default {
    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      },
      default: {
        type: Number,
        default() { return null }
      }
    },

    data() {
      return {
        userId: null
      }
    },

    computed: {
      ...mapStores(useCurrentUserStore),
    },

    watch: {
      value(val) {
        this.userId = val
      },

      default(val) {
        if(this.userId === null) this.userId = val
      },

      userId(val) {
        // For component v-model support
        this.$emit('input', val)
      }
    },

    async mounted() {
      await this.$store.dispatch('users/fetchOnce')
    }
  }
</script>
