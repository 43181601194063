<template lang="pug">
input.form-control(:type="type" v-model="inputValue" @blur="blur")
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'text'
      },
      value: {
        type: String
      }
    },

    computed: {
      inputValue: {
        get() { return this.value },
        set(val) { this.$emit('input', val) }
      }
    },

    methods: {
      blur() {
        this.$emit('blur')
      }
    }
  }
</script>
