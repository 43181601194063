import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  squeues: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ squeues }) {
    return squeues
  },

  enabled({ squeues }) {
    return squeues.filter(squeue => squeue.enabled)
  },

  enabledAsObject({ squeues }) {
    let obj = {}
    squeues.filter(squeue => {
      return squeue.enabled
    }).forEach(squeue => {
      obj[squeue.title] = squeue.id
    })
    return obj
  },

  forId: ({ squeues }) => (id) => {
    return squeues.find(squeue => squeue.id === id)
  }
}

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get('/api/internal/squeues')
      .then(response => {
        commit('SET_SQUEUES', response.data.squeues)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true})
      })
  },

  async fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
