<template lang="pug">
div
  table.table.table-striped
    thead
      tr
        th Title
        th Slug
        th Notes
        th Stories
        th Sort
        th Delete
    draggable(tag="tbody" v-model="pages" v-on:change="reorder" handle=".handle")
      template(v-for="(page, index) in pages")
        tr(v-if="!page._destroy")
          td
            input.form-control(type="text" v-model="page.title")
          td
            input.form-control(type="text" v-model="page.slug")
          td
            input.form-control(type="text" v-model="page.notes")
          td
            | {{ page.story_count }}
          td
            button.handle.btn.btn-default.btn-sm(style="cursor: move")
              i.fa.fa-sort
          td
            button.btn.btn-danger.btn-sm(v-on:click="deletePage(index)")
              i.fa.fa-trash
  .row
    .col-md-4.text-left
      button.btn.btn-default(v-on:click="addBlankPage")
        i.fa.fa-plus
        |
        | Add Page
    .col-md-8.text-right
      button.btn.btn-link.text-muted(v-on:click="cancel")
        | Cancel
      |
      |
      button.btn.btn-success(v-on:click="save")
        i.fa.fa-save
        |
        | Save
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters, mapMutations } from 'vuex'
import { sync } from 'vuex-pathify'

export default {
  components: {
    draggable,
  },

  props: {
    issueId: {
      required: true
    }
  },

  computed: {
    ...mapGetters('pages', { issuePages: 'forIssue' }),
    pages() { return this.issuePages({ id: this.issueId }) },
    edit: sync('pages/edit'),
  },

  created() {
    this.fetchPages()
  },

  methods: {
    ...mapMutations('pages', { addPage: 'ADD' }),

    fetchPages() {
      this.$store.dispatch('pages/fetch', { issueId: this.issueId })
    },

    addBlankPage() {
      this.addPage(
        {
          issue: { id: this.issueId },
          list_order: this.pages.length + 1
        }
      )
    },

    deletePage(index) {
      const page = this.pages[index]

      if(page.id) {
        page._destroy = true
      } else {
        this.pages.splice(index, 1)
      }
    },

    cancel() {
      this.fetchPages()
      this.edit = false
    },

    save() {
      this.$store.dispatch('pages/save', { issueId: this.issueId }).then(({ data }) => {
        this.edit = false
        this.$store.dispatch('messages/addNotice', data.message)
      }).catch(err => {
        this.$store.dispatch('messages/addError', err)
      })
    },

    reorder() {
      this.pages.forEach((page, i) => {
        // Add one because we want to start at 1, like Rails does, not at zero
        // like forEach does
        page.list_order = i + 1
      })
    }
  }
}

</script>
