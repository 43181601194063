<template lang="pug">
ol.breadcrumb
  li(:class="{ active: atRoot }")
    template(v-if="atRoot")
      | {{ resourceSlug }}
    template(v-else)
      a(@click="goToIndex(0)") {{ resourceSlug }}

  li(v-for="(part, index) in currentPathParts" :key="part" :class="{ active: current(index) }")
    template(v-if="current(index)")
      | {{ part }}
    template(v-else)
      a(@click="goToIndex(index + 1)") {{ part }}
</template>

<script>
  import { mapStores, mapState } from 'pinia'
  import { useFilesStore } from '../../stores/files'

  export default {
    props: {
      resourceSlug: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapStores(useFilesStore),
      ...mapState(useFilesStore, ['currentPathParts']),

      atRoot() {
        return this.currentPathParts.length === 0
      },

      lastIndex() {
        return this.currentPathParts.length - 1
      }
    },

    methods: {
      current(index) {
        return index === this.lastIndex
      },

      goToIndex(index) {
        let newPathParts = this.currentPathParts.slice(0, index)

        this.filesStore.goTo(newPathParts)
      }
    }
  }
</script>

 <style scoped>
  a {
    cursor: pointer;
  }
</style>
