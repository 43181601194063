<template lang="pug">
.media-manager
  .row
    .col-md-9
      FilterBar

      hr

      .row
        FileDropOverlay(@drop="dropHandler")
          LoaderOverlay(:loading="loading")
            .file-grid(v-for="medium in media" v-on:click="toggle(medium.id)")
              Medium(:medium="medium" :selected="selectedMediaIds.includes(medium.id)")

            .col-sm-12.text-center(v-if="media.length == 0")
              .alert.alert-info No results were found

            .clearfix

      nav.text-center
        Pagination(
          :current="page.current"
          :per="page.per"
          :total-records="page.total_records"
          @change="changePage"
        )

    Sidebar.col-md-3
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { sync } from 'vuex-pathify'
  import pLimit from 'p-limit'

  import FileDropOverlay from '../../../components/shared/file_drop_overlay.vue'
  import FilterBar from './filter_bar.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import Medium from '../../../components/media/medium.vue'
  import Pagination from '../../../components/shared/pagination.vue'
  import Sidebar from './sidebar.vue'

  export default {
    components: {
      FileDropOverlay,
      FilterBar,
      LoaderOverlay,
      Medium,
      Pagination,
      Sidebar
    },

    data() {
      return {
        unsubscribe: null
      }
    },

    computed: {
      media: sync('media/media'),
      loading: sync('media/loading'),
      selectedMediaIds: sync('media/selectedMediaIds'),
      page: sync('media/page'),
      ...mapGetters('media', {
        selectedMedia: 'selected'
      })
    },

    async mounted() {
      this.$store.dispatch('media/search')

      // When just one medium is selected from the media list, set it as the "medium"
      // in the medium store so that it tracks with the sidebar view of a single medium.
      this.unsubscribe = this.$store.subscribe(( mutation, { media } ) => {
        if(!['media/TOGGLE', 'media/CLEAR_SELECTED'].includes(mutation.type)) return

        if(media.selectedMediaIds.length === 1) {
          this.$store.dispatch('medium/pullSoleFromMedia')
        } else {
          this.$store.dispatch('medium/reset')
        }
      })
    },

    async unmounted() {
      this.unsubscribe()
    },

    methods: {
      ...mapActions('media', ['toggle']),

      changePage(page) {
        this.$store.dispatch('media/search', { page })
      },

      // TODO: figure out why this sometimes doesn't properly update media on the page.
      // There is probably a race condition going on here, although I'm not sure where.
      // Especially in the case of a single file drop.
      dropHandler(files) {
        // Only allow one file upload at a time to avoid deadlocks in the database
        const limit = pLimit(1)

        return Promise.all(
          files.map(file =>
            limit(() => this.$store.dispatch('media/create', { file }))
          )
        ).then(() => {
          const msg = (files.length === 1) ? 'File uploaded' : `${files.length} files uploaded`
          this.$store.dispatch('messages/addNotice', msg)
        })
      }
    }
  }
</script>
