<template lang="pug">
ul.list-group
  li.list-group-item
    label
      input(type="radio" value="now" v-model="whenChoice")
      |
      | Publish now
  li.list-group-item
    label
      input(type="radio" value="custom" v-model="whenChoice")
      |
      | Publish at
    span
      select(v-model="publishAtMonth" :disabled="dateDisabled")
        option(v-for="(month, index) in months" :value="index + 1") {{ month }}
      select(v-model="publishAtDay" :disabled="dateDisabled")
        option(v-for="day in days" :value="day") {{ day }}
      select(v-model="publishAtYear" :disabled="dateDisabled")
        option(v-for="year in years" :value="year") {{ year }}
      | —
      select(v-model="publishAtHour" :disabled="dateDisabled")
        option(v-for="hour in hours" :value="hour") {{ hourLabel(hour) }}
      | :
      select(v-model="publishAtMinute" :disabled="dateDisabled")
        option(v-for="fiveMinuteCount in 12" :value="fiveMinuteCountToMinutes(fiveMinuteCount)")
          | {{ minuteLabel(fiveMinuteCountToMinutes(fiveMinuteCount)) }}
  li.list-group-item
    label
      input.do-not-publish(type="radio" value="dont" v-model="whenChoice")
      |
      | Do not publish
</template>

<script>
  // TODO: it would be good if this could use the standard DateField component

  // TODO: we should get rid of this moment dependency as I believe it's our only remaining use of it
  // and it's somewhat deprecated.
  var moment = require('moment');

  module.exports = {
    props: {
      now: {
        type: Object,
        default() { return moment() }
      },
      years: {
        type: Array,
        default() {
          // TODO: this was generted by the coffeescipt to JS conversion come up
          // with a better solution
          let left = moment().year();
          let right = (moment().year() + 3);
          let range = [];
          let ascending = left < right;
          let end = ascending ? right + 1 : right - 1;
          for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
            range.push(i);
          }
          return range;
        }
      },
      value: {
        type: Date,
        default() { return new Date() }
      },
      when: {
        type: String,
        default() { return 'dont' }
      }
    },

    data() {
      return {
        publishAtMonth: this.value.getMonth() + 1,
        publishAtDay: this.value.getDate(),
        publishAtYear: this.value.getFullYear(),
        publishAtHour: this.value.getHours(),
        // Next 5 minute block
        publishAtMinute: this.currentFiveMinutes(),
        months: moment.months(),
        // TODO: find a better way to do these. This was a simple range in CoffeeScript but didn't convert very nicely
        days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      };
    },

    computed: {
      whenChoice: {
        get() {
          return this.when
        },
        set(val) {
          this.$emit('whenInput', val)
        }
      },

      dateDisabled() {
        this.when != 'custom'
      },

      selectedTime() {
        if(this.when == 'now') {
          return this.now.toDate();
        } else if(this.when == 'custom') {
          return new Date(
            this.publishAtYear,
            this.publishAtMonth - 1,
            this.publishAtDay,
            this.publishAtHour,
            this.publishAtMinute
          );
        } else {
          return null;
        }
      }
    },

    watch: {
      value(val) {
        if(!val) return null;

        this.publishAtMonth = val.getMonth() + 1
        this.publishAtDay = val.getDate()
        this.publishAtYear = val.getFullYear()
        this.publishAtHour = val.getHours()
        // Next 5 minute block
        this.publishAtMinute = this.currentFiveMinutes()
      },

      selectedTime() {
        // For component v-model support
        this.$emit('input', this.selectedTime)
      }
    },

    methods: {
      currentFiveMinutes() {
        var fiveMinutes = Math.ceil(this.value.getMinutes() / 5) * 5;

        if(fiveMinutes == 60) { return 0; } else { return fiveMinutes; }
      },

      hourLabel(hour) {
        return moment().hour(hour).format('h A');
      },

      minuteLabel(minute) {
        // Zero pad
        return ('00' + minute).substr(-2, 2);
      },

      fiveMinuteCountToMinutes(fiveMinuteCount) {
        return (fiveMinuteCount - 1) * 5;
      }
    }
  }
</script>
