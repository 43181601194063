<template lang="pug">
a.btn.btn-default(@click="goToEdit()")
  i.fa.fa-pencil
  template(v-if="title")
    |
    |
    | {{ title }}
</template>

<script>
  import { storyRoute } from '../../../utils/route_helpers.js'

  export default {
    props: {
      storyId: {
        type: Number,
        required: true
      },

      title: {
        type: String,
        required: false
      }
    },

    methods: {
      goToEdit() {
        this.$router.push(storyRoute({
          storyRoute: 'editStory',
          issueRoute: 'editIssueStory',
          pageRoute: 'editPageStory',
          storyId: this.storyId,
          router: this.$router
        }))
      }
    }
  }
</script>
