<template lang="pug">
input.form-control(type="number" v-model="inputValue" @blur="blur")
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    }
  },

  computed: {
    inputValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val ? Number(val) : null) }
    }
  },

  methods: {
    blur() {
      this.$emit('blur')
    }
  }
}
</script>

<style scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
