<template lang="pug">
.story-select
  .panel.panel-default
    .panel-heading
      .input-group
        input.form-control(type="search" placeholder="Search" v-model="search")
        span.input-group-addon
          i.fa.fa-search

    ul.story-list.list-group
      li.list-group-item(
        v-bind:class="{active: (story == selected)}"
        v-for="story in stories"
        :key="story.id"
        v-on:click="select(story)"
      )
        strong {{ story.name }}
        div(v-html="story.byline")

    div.panel-footer(v-if="$slots.footer")
      slot(name="footer")
</template>

<script>
  import axios from 'axios'

  const PER_PAGE = 3;

  export default {
    props: {
      value: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        stories: [],
        search: ''
      };
    },

    computed: {
      selected() {
        return this.stories.find(s => s.id === this.value)
      }
    },

    watch: {
      search() {
        this.loadStories()
      }
    },

    mounted() {
      // TODO: eventually it would be good to load the story from $props.value, but
      // we don't need it yet, and may never need it, so we'll cross that road when
      // we get to it.
      this.loadStories()
    },

    methods: {
      loadStories() {
        // TODO: we should really debounce this
        axios.get('/api/internal/stories', {
          params: {'q[text_cont]': this.search, per_page: PER_PAGE}
        }).then(({ data }) => {
          this.stories = data.stories
        });
      },

      select(story) {
        // For component v-model support
        this.$emit('input', story.id)
      }
    }
  }
</script>
