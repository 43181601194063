import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  styleMaps: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ styleMaps }) {
    return styleMaps
  },

  forId: ({ styleMaps }) => (id) => {
    return styleMaps.find(styleMap => styleMap.id === id)
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  ADD_STYLE_MAP(state, styleMap) {
    return state.styleMaps.push(styleMap)
  },

  UPDATE_STYLE_MAP(state, styleMap) {
    state.styleMaps = state.styleMaps.map(sMap => {
      return sMap.id === styleMap.id ? styleMap : sMap
    })
  },

  DELETE_STYLE_MAP(state, styleMapId) {
    state.styleMaps = state.styleMaps.filter(sMap => {
      return sMap.id !== styleMapId
    })
  }
}

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get('/api/internal/style_maps')
      .then(response => {
        commit('SET_STYLE_MAPS', response.data.style_maps)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  },

  async create({ commit, dispatch }, styleMap) {
    return axios.post(
      '/api/internal/style_maps',
      { style_map: styleMap }
    ).then(response => {
      commit('ADD_STYLE_MAP', response.data.style_map)
      return response
    }).catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  async update({ commit, dispatch }, styleMap) {
    return axios.patch(
      `/api/internal/style_maps/${styleMap.id}`,
      { style_map: styleMap }
    ).then(response => {
      commit('UPDATE_STYLE_MAP', response.data.style_map)
      return response
    }).catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  async delete({ commit, dispatch }, styleMapId) {
    return axios.delete(
      `/api/internal/style_maps/${styleMapId}`
    ).then(response => {
      commit('DELETE_STYLE_MAP', styleMapId)
      return response
    }).catch((err) => {
      dispatch('messages/smartAdd', err, { root: true })
    })
  },

  async fetchOnce({ dispatch, state: { fetchInitiated } }) {
    if (fetchInitiated) return Promise.resolve()

    dispatch('fetch')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
