<template lang="pug">
JwPagination(
  :items="pages"
  :pageSize="1"
  :initialPage="current"
  :maxPages="maxPages"
  @changePage="onChangePage"
  ref="jwPagination"
)
</template>

<script>
  import JwPagination from 'jw-vue-pagination'

  // NOTE: jw-pagination is meant to work on an array of objects, but for our
  // purposes it's easier for us to work with page numbers. So we work around
  // it by building out an array of page numbers and tell it to do just 1 item
  // per page.
  export default {
    components: {
      JwPagination,
    },

    props: {
      current: {
        type: Number,
        default: 1
      },
      per: {
        type: Number,
        default: 1
      },
      totalRecords: {
        type: Number,
        default: 0
      },
      maxPages: {
        type: Number,
        default: 10
      }
    },

    computed: {
      pageCount() {
        return Math.ceil(this.totalRecords / this.per)
      },

      pages() {
        return Array.from({length: this.pageCount}, (_, i) => i + 1)
      }
    },

    watch: {
      current(newPage) {
        /*
          jw-pagination doesn't watch for initialPage changes, so we need to
          hook in at a lower level when the page changes without it's knowledge.
          It would normally do this automatically when the "items" changed, but
          becuse we use page numbers as items, that doesn't always happen reliably.
        */
        this.$refs.jwPagination.setPage(newPage);
      }
    },

    methods: {
      onChangePage(newPageArray) {
        const page = newPageArray[0] || 1;

        // JwPagination can throw out changePage event fairly liberally, so
        // check to see if the page has actually changed.
        if(page === this.current) return;

        this.$emit('change', newPageArray[0])
      }
    }
  }
</script>
