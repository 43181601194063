import axios from 'axios'
import { make } from 'vuex-pathify'

// helpers
const getDefaultState = () => {
  return {
    squeue: {
      id: null,
      title: '',
      slug: '',
      system_record: false,
      enabled: true,
      list_order: 0,
      color: null
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state)
}

// mutations
const mutations = {
  ...make.mutations(state),

  RESET(state) {
    Object.assign(state, { ...getDefaultState() })
  }
}

// actions
const actions = {
  ...make.actions(state),

  async fetch({ dispatch }, { squeueId }) {
    return axios
      .get(`/api/internal/squeues/${squeueId}`)
      .then(response => {
        dispatch('setSqueue', response.data.squeue)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  },

  async save({ state: { squeue }, dispatch }) {
    if (squeue.id) {
      return dispatch('update')
    } else {
      return dispatch('create')
    }
  },

  async create({ state: { squeue }, getters, commit, dispatch }) {
    return axios
      .post('/api/internal/squeues/', { squeue })
      .then(({ data }) => {
        commit('SET_SQUEUE', data.squeue)
        dispatch('messages/addNotice', 'Section status created', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  async update({ state: { squeue }, commit, dispatch }) {
    return axios
      .patch(
        `/api/internal/squeues/${squeue.id}`,
        { squeue }
      )
      .then(({ data }) => {
        commit('SET_SQUEUE', data.squeue)
        dispatch('messages/addNotice', 'Section status saved', { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
        throw err
      })
  },

  async destroy({ state: { squeue: { id } }, commit, dispatch }) {
    return axios.delete(`/api/internal/squeues/${id}`).then(() => {
      commit('RESET')
      dispatch('messages/addNotice', 'Section status deleted', { root: true })
    }).catch(err => {
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
