<template lang="pug">
LoaderOverlay(:loading="loading")
  LocationForm
</template>

<script>
  import { mapStores } from 'pinia'
  import { useLocationStore } from '../../../stores/location'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import LocationForm from '../shared/form.vue'

  export default {
    components: {
      LoaderOverlay,
      LocationForm
    },

    props: {
      locationId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      ...mapStores(useLocationStore)
    },

    async mounted() {
      this.loading = true

      const { locationId } = this
      this.locationStore.fetch({ locationId })

      this.loading = false
    }
  }
</script>

