<template lang="pug">
div
  span.action-icon(v-if="actionIcon")
    i.fa.text-muted(:class="[`fa-${actionIcon}`]" @click="actionClicked")

  template(v-if="multiLine && html")
    .panel.panel-default
      .panel-body
        slot
          div(v-html="value")

  template(v-if="multiLine && !html")
    .panel.panel-default
      .panel-body
        slot {{ value }}

  template(v-if="!multiLine && html")
    .vue-labelled-value-value
      slot
        div(v-html="value")

  template(v-if="!multiLine && !html")
    .vue-labelled-value-value
      slot {{ value }}
</template>

<script>
  export default {
    props: {
      value: {
        required: false // not required to allow null
      },
      multiLine: {
        type: Boolean,
        default: false
      },
      html: {
        type: Boolean,
        default: false
      },
      actionIcon: {
        type: String
      }
    },

    methods: {
      actionClicked() {
        this.$emit('action-clicked')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vue-labelled-value-value {
    border-bottom: 1px solid #e3e3e3;
    min-height: 20px;
  }

  .action-icon {
    float: right;
    cursor: pointer;
  }
</style>
