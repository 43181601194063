<template lang="pug">
aside
  LabelledValue(label="Issue" :value="page.issue.name")
  LabelledValue(label="Slug" :value="page.slug")
  LabelledEditableValue(label="Notes" v-model="notes" :multi-line="true" @blur="save")
</template>

<script>
  import { sync } from 'vuex-pathify'
  import LabelledValue from '../../../components/shared/labelled_value.vue'
  import LabelledEditableValue from '../../../components/shared/labelled_editable_value.vue'

  export default {
    components: {
      LabelledValue,
      LabelledEditableValue
    },

    computed: {
      page: sync('page/page'),
      notes: sync('page/page@notes')
    },

    methods: {
      save() {
        this.$store.dispatch('page/update')
      }
    }
  }
</script>
