import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  // Subsite data is separated by publication
  publications: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ publications }) {
    return publications
  },

  forPublication: ({ publications }) => (publication_id) => {
    const publication = publications.find((pub) => pub.id === publication_id)

    return publication ? publication.subsites : []
  },

  forPublicationAsObject: ({}, { forPublication }) => (publication_id) => {
    const subsites = forPublication(publication_id)

    let obj = {}
    subsites.forEach(subsite => {
      obj[subsite.town_name] = subsite.town_id
    })
    return obj
  }
}

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get('/api/internal/community_q/subsites')
      .then(({ data: { publications } }) => {
        commit('SET_PUBLICATIONS', publications)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true})
      })
  },

  async fetchOnce({ dispatch, state: { fetchInitiated } }) {
    if (fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
