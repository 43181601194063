<template lang="pug">
div
  header
    .pull-right
      a.btn.btn-default(href="/admin/sections/new") New Print Section
    h1 Print Sections

  table.table.table-striped
    thead
      tr
        th Title
        th Code
        th System?
        th Enabled?
        th.text-right Sort
    draggable(tag="tbody" v-model="taxonomies" v-on:change="save" handle=".handle")
      template(v-for="section in taxonomies")
        tr(v-bind:key="section.id")
          td
            a(:href="`/admin/sections/${section.id}/edit`" v-text="section.title")
          td
            code(v-text="section.slug")
          td
            i(:class="checkboxClass(section.system_record)")
          td
            i(:class="checkboxClass(section.enabled)")
          td.text-right
            button.handle.btn.btn-default.btn-sm(style="cursor: move")
              i.fa.fa-sort
</template>

<script>
import OrderedTaxonomy from '../../mixins/ordered_taxonomy'

export default {
  mixins: [OrderedTaxonomy],

  props: {
    taxonomyName: {
      default: 'sections'
    }
  }
}
</script>
