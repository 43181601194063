<template lang="pug">
DropdownCheckboxes(
  :label="label"
  :name="name"
  :options="squeueOptions"
  :value="value"
  @input="update"
  :loading="loading"
)
</template>

<script>
  import DropdownCheckboxes from '../dropdown_checkboxes.vue'

  export default {
    components: {
      DropdownCheckboxes
    },

    props: {
      label: {
        type: String,
        default() { return 'Status' }
      },

      name: {
        type: String,
        default() { return 'q[squeue_id_in][]' }
      },

      value: {
        type: Array,
        default() { return [] }
      }
    },

    computed: {
      squeueOptions() {
        return this.$store.getters['squeues/enabledAsObject'];
      },

      loading() {
        return this.$store.getters['squeues/all'].length === 0;
      }
    },

    mounted() {
      this.$store.dispatch('squeues/fetch');
    },

    methods: {
      update(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
</script>
