<template lang="pug">
div
  | Viewing
  |
  strong {{ numberOfFirstRecordOnPage }}
  |
  | -
  |
  strong {{ numberOfLastRecordOnPage }}
  |
  | of
  |
  strong {{ totalRecords }}
  |
  | items found
</template>

<script>
  export default {
    props: {
      current: {
        type: Number,
        default: 0
      },
      per: {
        type: Number,
        default: 0
      },
      totalRecords: {
        type: Number,
        default: 0
      }
    },

    computed: {
      numberOfFirstRecordOnPage() {
        if(this.totalRecords < 1) return 0;

        return ((this.current - 1) * this.per) + 1;
      },

      numberOfLastRecordOnPage() {
        return Math.min(this.current * this.per, this.totalRecords);
      }
    }
  }
</script>
