<template lang="pug">
div
  template(v-if="files.length === 0")
    .alert.alert-info No files were found in this folder

  .row.file-grid
    FileDropOverlay(@drop="uploadNewFiles")
      File(v-for="file in orderedFiles" :file="file" :key="file.name")

      template(v-if="showNewFolder")
        .col-sm-6.col-md-4.col-sg-3
          .thumbnail
            button.btn.btn-link.btn-lg.show-on-hover.cancel(v-on:click="cancelNewFolder()")
              i.fa.fa-ban.text-warning

            .thumb.file.text-center.text-muted
              i.fa.fa-folder-o

            form(v-on:submit.prevent="createNewFolder()")
              .input-group
                input.form-control(type="text" placeholder="Folder name" autofocus v-model="newFolderName")
                .btn.btn-success.input-group-addon(v-on:click="createNewFolder()")
                  i.fa.fa-save

      .clearfix

  .row.buttons.text-right
    label.btn.btn-default.btn-file(v-on:change="newFilesHandler")
      Loader(v-if="loading" :stacked="false")
      i.fa.fa-file-o(v-if="!loading")
      input(type="file" multiple)
      |
      | New Files
    |
    |
    button.btn.btn-default(v-on:click="addNewFolder()" :disabled="this.showNewFolder")
      i.fa.fa-folder-o
      |
      | New Folder
</template>

<script>
  import pLimit from 'p-limit'
  import { mapStores, mapState } from 'pinia'
  import { useFilesStore } from '../../stores/files'

  import File from '../files/file.vue'
  import FileDropOverlay from '../../components/shared/file_drop_overlay.vue'
  import Loader from '../../components/shared/loader.vue'

  const DIRECTORY_TYPE = 'directory'

  export default {
    components: {
      File,
      FileDropOverlay,
      Loader
    },

    props: {
      resourceGlobalId: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        showNewFolder: false,
        newFolderName: '',
        loading: false
      }
    },

    computed: {
      ...mapStores(useFilesStore),
      ...mapState(useFilesStore, [
        'files',
        'orderedFiles',
        'currentPath'
      ]),
    },

    async created() {
      await this.filesStore.resetCurrentPath()
      await this.filesStore.fetch(this.resourceGlobalId)
    },

    methods: {
      addNewFolder() {
        this.showNewFolder = true
      },

      cancelNewFolder() {
        this.showNewFolder = false
        this.newFolderName = ''
      },

      createNewFolder() {
        let folderPath = `${this.currentPath}/${this.newFolderName}`

        this.filesStore.createFolder(folderPath).then(() => {
          this.cancelNewFolder()
        })
      },

      newFilesHandler({ target: { files }}) {
        this.uploadNewFiles(Array.from(files))
      },

      uploadNewFiles(files) {
        this.loading = true
        const limit = pLimit(1)

        return Promise.all(
          files.map(file =>
            limit(() => this.filesStore.uploadFile({ file, filePath: `${this.currentPath}/${file.name}` }) )
          )
        ).then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
