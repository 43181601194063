import { defineStore } from 'pinia'

export const useStoryFileDropStore = defineStore('storyFileDrop', {
  state: () => ({
    file: null
  }),

  getters: {
    exists: ({ file }) => !!file
  }
})
