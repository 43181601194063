<template lang="pug">
li.list-group-item.placement
  slot
  CheckboxIcon(:checked="!!(issueId || pageId)")
  |
  |
  strong(v-if="issueId || pageId") {{ publicationName }}
  span.text-muted(v-else) None selected
  |
  |
  template(v-if="issueId")
    i.fa.fa-angle-right
    |
    |
    i.fa.fa-newspaper-o
    |
    | {{ issueTitle }}
    |
    |
  template(v-if="pageId")
    i.fa.fa-angle-right
    |
    |
    i.fa.fa-file-o
    |
    |
    | {{ pageTitle }}
</template>

<script>
  import { mapGetters } from 'vuex'
  import CheckboxIcon from '../../shared/checkbox_icon.vue'

  export default {
    components: {
      CheckboxIcon
    },

    props: {
      placement: {
        type: Object,
        required: true
      }
    },

    mounted() {
      this.$store.dispatch('issues/fetchOnce')

      if(this.issueId) {
        this.$store.dispatch('pages/fetchOnce', { issueId: this.issueId })
      }
    },

    computed: {
      ...mapGetters('issues', { issueForId: 'forId'} ),
      ...mapGetters('pages', { pageForId: 'forId'} ),
      publicationName() { return this.issue?.publication?.name },
      issueId() { return this.placement.issue_id },
      issue() { return this.issueForId(this.issueId) },
      issueTitle() { return this.issue?.title },
      page() { return this.pageForId(this.pageId) },
      pageId() { return this.placement.page_id },
      pageTitle() { return this.page?.title }
    }
  }
</script>
