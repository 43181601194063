<template lang="pug">
div
  header
    .pull-right
      router-link.btn.btn-default(to="/admin/squeues/new") New Section Status
    h1 Section Statuses

  table.table.table-striped
    thead
      tr
        th.color
        th Title
        th Code
        th Auto web export
        th System?
        th Enabled?
        th.text-right Sort
    draggable(tag="tbody" v-model="taxonomies" v-on:change="save" handle=".handle")
      template(v-for="squeue in taxonomies")
        tr(v-bind:key="squeue.id")
          td.color(:style="{ 'background-color': squeue.color }")
          td
            router-link(:to="`/admin/squeues/${squeue.id}/edit`")
              | {{ squeue.title }}
          td
            code(v-text="squeue.slug")
          td
            i.fa(:class="[triggerWebExportIcon(squeue)]" :title="triggerWebExportTitle(squeue)")
            |
            | {{ triggerWebExportText(squeue) }}
          td
            i(:class="checkboxClass(squeue.system_record)")
          td
            i(:class="checkboxClass(squeue.enabled)")
          td.text-right
            button.handle.btn.btn-default.btn-sm(style="cursor: move")
              i.fa.fa-sort
</template>

<script>
import OrderedTaxonomy from '../../../../components/mixins/ordered_taxonomy'

export default {
  mixins: [OrderedTaxonomy],

  props: {
    taxonomyName: {
      default: 'squeues'
    }
  },

  methods: {
    triggerWebExportIcon({ trigger_web_export }) {
      switch(trigger_web_export) {
        case 'publish_immediately':
          return 'fa-eye'
        case 'export_unpublished':
          return 'fa-eye-slash'
        default:
          return 'fa-ban'
      }
    },

    triggerWebExportText({ trigger_web_export }) {
      switch(trigger_web_export) {
        case 'publish_immediately':
          return 'Published'
        case 'export_unpublished':
          return 'Unpublished'
        default:
          return 'Off'
      }
    },

    triggerWebExportTitle({ trigger_web_export }) {
      return trigger_web_export.replace('_', ' ')
    }
  }
}
</script>

<style lang="scss" scoped>
  th, td {
    vertical-align: middle !important;

    &.color {
      padding: 2px;
    }
  }
</style>
