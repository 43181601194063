<template lang="pug">
div
  h1 Issue

  LoaderOverlay(:loading="loading")
    form
      .row
        .col-md-6
          .form-group
            Label(text="Slug")
            Input(v-model="slug")
          .form-group
            Label(text="Title")
            Input(v-model="title")

        .col-md-6
          .form-group
            PublicationField(v-model="publication.id" :required="true")
          .form-group
            Label(text="Publish Date")
            DateField(v-model="publish_on")

      .row
        .col-md-12
          .form-group
            Label(text="Notes")
            TextareaInput(v-model="notes")

      .row
        .col-md-12
          button.btn.btn-primary(@click.prevent="save") Save
          router-link.btn.btn-link.text-muted(to="/issues/") cancel
</template>

<script>
  import { sync } from 'vuex-pathify'

  import DateField from '../../../components/shared/date_field.vue'
  import Input from '../../../components/shared/input.vue'
  import Label from '../../../components/shared/label.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import PublicationField from '../../../components/shared/publication_field.vue'
  import TextareaInput from '../../../components/shared/textarea_input.vue'

  export default {
    components: {
      DateField,
      Input,
      Label,
      LoaderOverlay,
      PublicationField,
      TextareaInput
    },

    data() {
      return {
        loading: false
      }
    },

    computed: {
      ...sync('issue/issue@', [
        'id',
        'publication',
        'title',
        'slug',
        'publish_on',
        'notes'
      ]),
    },

    methods: {
      async save() {
        this.loading = true

        this.$store.dispatch('issue/save').then(() => {
          this.loading = false
          this.$router.push({ name: 'issues' });
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>
