<template lang="pug">
section.well.well-sm.top-section
  .main-options
    .form-inline
      .pull-right
        button.btn.btn-default(
          type="button"
          :disabled="!anyQueries"
          ref="clearButton"
          data-toggle="tooltip"
          data-placement="top"
          title="Clear search"
          @click="clear"
        )
          i.fa.fa-undo
        |
        |
        StorySearchButton
        |
        |
        button.btn.btn-prominent.btn-sm(type="button" @click="toggleAdvanced()")
          | Advanced
          |
          i.fa(:class="{'fa-caret-down': !showAdvanced, 'fa-caret-up': showAdvanced}")

      label(for="text_cont") Search:
      |
      |
      input.form-control.input-sm(type="search" name="q[text_cont]" v-model="text_cont")
      |
      |
      SortOptions
      |
      |
      UserCheckboxes(v-model="user_id_in")
      |
      |
      SectionCheckboxes(v-model="story_sections_section_id_in")
      |
      |
      PublicationCheckboxes(v-model="story_publications_publication_id_in")
      |
      |
      SqueueCheckboxes(v-model="squeue_id_in")

  .advanced-options(ref="advanced" v-show="showAdvanced")
    hr
    .row
      .col-sm-4
        .form-group
          label(for="slug_cont") Slug contains
          input.form-control.input-sm(type="search" name="q[slug_cont]" v-model="slug_cont")

        .from-group
          label Pub date
          .row
            .col-sm-6
              DateField(name="q[pub_date_gteq]" v-model="pub_date_gteq" placeholder="From")
            .col-sm-6
              DateField(name="q[pub_date_lteq]" v-model="pub_date_lteq" placeholder="To")

      .col-sm-4
        .form-group
          label(for="byline_cont") Byline contains
          input.form-control.input-sm(type="search" name="q[byline_cont]" v-model="byline_cont")

        .form-group
          label(for="pub_date_within") Pub date within
          .input-group
            input.form-control.input-sm(type="number" name="q[pub_date_within]" v-model="pub_date_within")
            span.input-group-addon days
          p.help-block Select a positive or negative number of days

      .col-sm-4
        .form-group
          label(for="content_cont") Content contains
          input.form-control.input-sm(type="search" name="q[content_cont]" v-model="content_cont")

        .form-group
          label(for="custom_folders_path_eq") File storage location
          input.form-control.input-sm(type="search" name="q[custom_folders_path_eq]" v-model="custom_folders_path_eq")
</template>

<script>
  import { mapGetters } from 'vuex'
  import { sync } from 'vuex-pathify'
  import DateField from '../../shared/date_field.vue'
  import PublicationCheckboxes from '../../shared/publication_checkboxes.vue'
  import SectionCheckboxes from '../../shared/section_checkboxes.vue'
  import SortOptions from './sort_options.vue'
  import SqueueCheckboxes from '../../shared/squeue_checkboxes.vue'
  import StorySearchButton from './story_search_button.vue'
  import UserCheckboxes from '../../shared/user_checkboxes.vue'

  export default {
    components: {
      DateField,
      PublicationCheckboxes,
      SectionCheckboxes,
      SortOptions,
      SqueueCheckboxes,
      StorySearchButton,
      UserCheckboxes
    },

    data() {
      return {
        showAdvanced: false
      };
    },

    computed: {
      ...mapGetters('storySearch', ['anyQueries']),
      ...sync('storySearch/search@*')
    },

    async mounted() {
      if(this.$store.getters['storySearch/anyAdvancedQueries']) {
        this.showAdvanced = true;
      }
    },

    async updated() {
      if (this.$refs.clearButton) {
        jQuery(this.$refs.clearButton).tooltip()
      }
    },

    methods: {
      toggleAdvanced() {
        this.showAdvanced = !this.showAdvanced;
      },

      clear() {
        this.$store.commit('storySearch/RESET')
        this.$store.dispatch('stories/search')
      }
    }
  }
</script>
