import axios from 'axios'
import qs from 'qs'
import { make } from 'vuex-pathify'
import PaginationStorage from '../../utils/pagination_storage'
import { snakeKeyed } from '../../utils/params_helpers'
import { getDefaultPaginationState } from '../shared/pagination'

// Updates the query string in the browser bar
const pushState = (queryString) => {
  if (`?${queryString}` == window.location.search) return

  const { history, document, location } = window
  history.pushState(
    {},
    document.title,
    `${location.origin}${location.pathname}?${queryString}`
  )
}

// initial state
const state = {
  ...getDefaultPaginationState(),
  stories: []
}

// getters
const getters = {
  ...make.getters(state),
  ids: state => state.stories.map(story => story.id),

  byId: ({ stories }) => (storyId) => {
    return stories.find(({ id }) => id == storyId)
  },

  containsId: state => id => state.stories.some(story => story.id === id),
}

// mutations
const mutations = {
  ...make.mutations(state),

  SET_STORY(state, story) {
    state.stories = state.stories.map(stry => {
      if (stry.id === story.id) {
        return Object.assign({}, stry, story)
      }
      return stry
    })
  }
}

// actions
const actions = {
  ...make.actions(state),

  async fetch(
    { dispatch },
    { query, page = undefined, perPage = undefined, newDefaultPerPage = undefined }
  ) {

    let pageStore = new PaginationStorage({ page, perPage, newDefaultPerPage })
    let queryParams = { q: query, ...snakeKeyed(pageStore.queryParams) }

    const queryString = qs.stringify(
      queryParams,
      { arrayFormat: 'brackets', skipNull: true }
    )

    return new Promise((resolve, reject) => {
      axios
        .get(`/api/internal/stories.json?${queryString}`)
        .then(({ data }) => {
          dispatch('setStories', data.stories)
          dispatch('setPage', data.meta.page)
          resolve(queryString)
        })
        .catch(err => {
          console.error(err)
          dispatch('messages/addError', err, { root: true })
          reject(err)
        })
    })
  },

  async search({ dispatch, commit, rootGetters }) {
    commit('storySearch/SAVE_TO_WINDOW_STORE', null, { root: true })

    return dispatch(
      'fetch',
      { query: rootGetters['storySearch/forQueryParams'] }
    ).then(
      queryString => { pushState(queryString) }
    )
  },

  async searchPage({ dispatch, rootGetters }, { page, perPage, newDefaultPerPage }) {
    let store = new PaginationStorage({ page, perPage, newDefaultPerPage })
    store.save()

    return dispatch(
      'fetch',
      {
        query: rootGetters['storySearch/forQueryParams'],
        ...store.queryParams
      }
    ).then(
      queryString => { pushState(queryString) }
    )
  },

  async reload({ dispatch, state }) {
    return dispatch('searchPage', { page: state.page.current })
  },

  async markPublished({ dispatch }, { id }) {
    return axios.patch(`/api/internal/stories/${id}/publish`).then(() => {
      dispatch('messages/addNotice', 'Story published', { root: true })
    })
  },

  async updateStory({ commit, dispatch, getters }, { story }) {
    // If this story isn't one of the ones we have in the current result set, just ignore it.
    if(!getters['containsId'](story.id)) return

    commit('SET_STORY', Object.assign({}, story, { changed: true }))
  },

  async updateSqueue({ commit, dispatch }, { id, squeueId }) {
    return axios.patch(`/api/internal/stories/${id}`, {
      story: { squeue_id: squeueId }
    }).then(({ data: { story }}) => {
      commit('SET_STORY', story)
      dispatch('messages/addNotice', 'Status changed', { root: true })
    })
  },

  async clone({}, { id }) {
    return axios.post(`/api/internal/stories/${id}/clone`).then(
      ({ data }) => data
    )
  },

  async destroy({ dispatch }, { id }) {
    return axios.delete(`/api/internal/stories/${id}`).then(() => {
      dispatch('messages/addNotice', 'Story spiked', { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
