<template lang="pug">
tr
  td.publish_on
    router-link(:to="`/issues/${issue.id}`")
      DateValue(:value="issue.publish_on")
  td.title {{ issue.title }}
  td.slug {{ issue.slug }}
  td.publication {{ issue.publication.name }}
  td.story_count {{ issue.story_count }}
  td.current
    CheckBox(v-if="!saving" :value="issue.current" @input="setCurrent")
    Loader(v-if="saving" :stacked="false")
    |
    |
    i.fa.fa-warning.text-danger(
      v-if="outdatedCurrent"
      ref="outdatedWarning"
      data-toggle="tooltip"
      data-placement="top"
      title="This issue is marked current but the publish date has passed"
    )
  td.actions
    .btn-group.pull-right
      router-link.btn.btn-default(:to="`/issues/${issue.id}/edit`")
        i.fa.fa-pencil.text-muted
      DeleteButton(@click="destroy" :loading="deleting")
</template>

<script>
  import { nextTick } from 'vue'

  import CheckBox from '../../../components/shared/checkbox.vue'
  import DateValue from '../../../components/shared/date_value.vue'
  import DeleteButton from '../../../components/shared/delete_button.vue'
  import Loader from '../../../components/shared/loader.vue'

  export default {
    components: {
      CheckBox,
      DateValue,
      DeleteButton,
      Loader
    },

    props: {
      issue: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        saving: false,
        deleting: false
      }
    },

    computed: {
      outdatedCurrent() {
        const { current, publish_on_iso8601 } = this.issue
        let today = new Date()
        today.setHours(0, 0, 0, 0)

        return current && new Date(publish_on_iso8601) < today
      }
    },

    mounted() {
      jQuery(this.$refs.outdatedWarning).tooltip()
    },

    updated() {
      jQuery(this.$refs.outdatedWarning).tooltip()
    },

    methods: {
      async setCurrent(current) {
        this.saving = true

        const { id } = this.issue
        await this.$store.dispatch('issues/toggleCurrent', { id, current })

        this.saving = false
      },

      destroy() {
        this.deleting = true

        // Use nextTick so that the loading state will render
        nextTick(() => {
          this.$store.dispatch('issues/destroy', { id: this.issue.id }).then(() => {
            this.deleting = false
          })
        })
      }
    }
  }
</script>
