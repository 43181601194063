<template lang="pug">
i.fa.fa-circle-o-notch.fa-spin(:class="cssClasses")
</template>

<script>
  module.exports = {
    props: {
      stacked: {
        type: Boolean,
        default: () => true
      },
      size: {
        type: String,
        default: () => null
      }
    },

    computed: {
      cssClasses() {
        return {
          'fa-stack-1x': this.stacked,
          'fa-lg': (this.size === 'lg'),
          'fa-2x': (this.size === '2x'),
          'fa-3x': (this.size === '3x'),
          'fa-4x': (this.size === '4x'),
          'fa-5x': (this.size === '5x')
          }
      }
    }
  }
</script>

