// Returns just the file pathCompleteExtname, without the filename
const filePath = (fullFilePath) => {
  if (fullFilePath != null) {
    const parts = String(fullFilePath).split('/')
    parts.pop()

    return `${parts.join('/')}/`
  }
}

// Filter the file name from the path. Removes the unix timestamp prefix for display
const mediaFileName = (filePath) => {
  if (!filePath) return null

  const filename = String(filePath).split('/').pop()

  // 10 digit unix timestamps cover 2001 to 2286, which is plenty good enough for me.
  return filename.replace(/^[0-9]{10}_/, '')
}

// Returns just the extension from a filename of full file path
const fileExtension = (filePath) => {
  if (!filePath) return null

  return String(filePath).split('.').pop()
}

export {
  filePath,
  mediaFileName,
  fileExtension
}
