import { defineStore } from 'pinia'
import { pickBy } from 'lodash'

export const useStoryBatchOptionsStore = defineStore('storyBatchOptions', {
  state: () => ({
    selectedStoryIds: [],
    pubDate: '',
    userId: '',
    sectionId: '',
    squeueId: '',
    webCategoryId: ''
  }),

  getters: {
    checked: ({ selectedStoryIds }) => (id) => { return selectedStoryIds.includes(id) },

    storyData({ pubDate, userId, sectionId, squeueId, webCategoryId }) {
      return pickBy(
        {
          pub_date: pubDate,
          user_id: userId,
          section_id: sectionId,
          squeue_id: squeueId,
          web_category_id: webCategoryId
        }
      )
    },

    anyChanges() {
      return Object.keys(this.storyData).length > 0
    },

    selectedStoriesCount({ selectedStoryIds }) {
      return selectedStoryIds.length
    },

    anySelectedStories() {
      return this.selectedStoriesCount > 0
    },
  },

  actions: {
    set(key, value) {
      value ? this.check(key) : this.uncheck(key)
    },

    check(key) {
      if(this.selectedStoryIds.includes(key)) return

      this.selectedStoryIds.push(key)
    },

    uncheck(key) {
      this.selectedStoryIds = this.selectedStoryIds.filter(id => id !== key)
    },

    uncheckAll() {
      this.selectedStoryIds = []
    }
  },
})
