import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

// Private Helper Functions //

const resourcePath = (base64ResourceGlobalId) => {
  if (!base64ResourceGlobalId) { throw 'A resourceGlobalID is required' }

  return `/api/internal/resources/${base64ResourceGlobalId}`
}

const resourceFilesPath = (base64ResourceGlobalId, filePath) => {
  return `${resourcePath(base64ResourceGlobalId)}/files/${filePath}`
}

const resourceFoldersPath = (base64ResourceGlobalId, folderPath) => {
  return `${resourcePath(base64ResourceGlobalId)}/folders/${folderPath}`
}

export const useFilesStore = defineStore('files', {
  state: () => ({
    // All file data must start within a resource's storage path
    resourceGlobalId: null,
    currentPathParts: [],
    selectedFile: null,
    files: []
  }),

  getters: {
    orderedFiles({ files }) {
      return files.sort((a, b) => {
        // Conveniently, the two types are "directory" and "file" which works
        // out alphabetically since we want the directories sorted first. And
        // since no directory should ever hold a duplicate file or directory
        // names, we should never have to check for the two being equal.
        return (`${a.type}-${a.name}` > `${b.type}-${b.name}` ? 1 : -1)
      })
    },

    currentPath({ currentPathParts }) {
      return currentPathParts.join('/')
    },

    resourceFilesPath() {
      return resourceFilesPath(this.base64ResourceGlobalId, this.currentPath)
    },

    base64ResourceGlobalId({ resourceGlobalId }) {
      if(!resourceGlobalId) { return null }

      return btoa(resourceGlobalId)
    }
  },

  actions: {
    async resetCurrentPath() {
      this.currentPathParts = []
    },

    async goBack() {
      const newParts = [...this.currentPathParts]
      newParts.pop()

      this.currentPathParts = newParts

      return this.fetch()
    },

    async goDownTo(directoryName) {
      this.currentPathParts.push(directoryName)

      return this.fetch()
    },

    async goTo(newPathParts) {
      this.currentPathParts = newPathParts

      return this.fetch()
    },

    async fetch(resourceGlobalId) {
      if(resourceGlobalId) { this.resourceGlobalId = resourceGlobalId }

      const path = resourceFilesPath(this.base64ResourceGlobalId, this.currentPath)

      return axios
        .get(path)
        .then(({ data: { files }}) => {
          this.files = files
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    },

    async createFolder(folderPath) {
      const path = resourceFoldersPath(this.base64ResourceGlobalId, folderPath)

      return axios
        .post(path)
        .then(({ data: { files }}) => {
          this.files = files
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    },

    async uploadFile({ filePath, file }) {
      let base64ResourceGlobalId = this.base64ResourceGlobalId
      if (!base64ResourceGlobalId) {
        throw 'A resourceGlobalID is required'
      }

      const path = resourceFilesPath(this.base64ResourceGlobalId, filePath)

      let formData = new FormData()
      formData.append('file', file)

      return axios
        .post(path, formData)
        .then(({ data: { files }}) => {
          this.files = files
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    },

    async destroy(filePath) {
      const path = resourceFilesPath(this.base64ResourceGlobalId, filePath)

      return axios
        .delete(path)
        .then(({ data: { files }}) => {
          this.files = files
        })
        .catch(err => {
          console.error(err)
          store.dispatch('messages/addError', err)
        })
    }
  }
})
