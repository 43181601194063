<template lang="pug">
div
  h1 My Settings
  hr

  form(@submit.prevent="save()")
    LoaderOverlay(:loading="loading")
      .row
        .col-md-8
          h2 Contact Info

          .form-group
            Label(text="First name")
            TextInput(v-model="user.first_name")

          .form-group
            Label(text="Last name")
            TextInput(v-model="user.last_name")

          .form-group
            Label(text="Email")
            TextInput(v-model="user.email")

          .form-group
            Label(text="Phone extension")
            NumberInput(v-model="user.ext")


          .form-group
            Label(text="Remote phone")
            TextInput(v-model="user.remote_phone")

          hr

          h2 Security

          .form-group
            Label(text="Password (twice)")
            TextInput(v-model="user.password" type="password")
            TextInput(v-model="user.password_confirmation" type="password")

        .col-md-4
          h2 Preferences

          .form-group
            Label(text="Byline")
            TextInput(v-model="user.byline")

          .form-group(v-if="hasManyPublications")
            PublicationField(
              label="Default publication"
              v-model="user.default_publication_id"
              :required="false"
            )

          .form-group
            Label(text="NewsroomQ files location")
            .input-group
              span.input-group-addon
                i.fa.fa-hdd-o
              TextInput(v-model="user.local_file_path" placeholder="/Volumes/PUBLIC/our.newsroomq.com/")
            p.help-block This should be the path to the directory where you access the NewsroomQ media files. Leaving this blank will disable links to folders.

          hr

          h2 Email Me When
          LabelledCheckbox(
            label="I'm assigned a story"
            v-model="user.assignment_notifications"
          )

      .row
        .col-md-12
          hr

          button.btn.btn-primary Save
</template>

<script>
  import { mapGetters } from 'vuex'
  import { mapStores, mapWritableState } from 'pinia'
  import { useCurrentUserStore } from '../../../stores/current_user'

  import Label from '../../../components/shared/label.vue'
  import LabelledCheckbox from '../../../components/shared/labelled_checkbox.vue'
  import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
  import PublicationField from '../../../components/shared/publication_field.vue'
  import NumberInput from '../../../components/shared/number_input.vue'
  import SelectInput from '../../../components/shared/select_input.vue'
  import TextInput from '../../../components/shared/text_input.vue'

  export default {
    components: {
      Label,
      LabelledCheckbox,
      LoaderOverlay,
      PublicationField,
      NumberInput,
      SelectInput,
      TextInput
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      ...mapGetters('publications', { publications: 'all' }),
      ...mapStores(useCurrentUserStore),
      ...mapWritableState(useCurrentUserStore, {
        user: 'currentUser'
      }),

      hasManyPublications() {
        return this.publications.length > 1
      }
    },

    async created() {
      await this.currentUserStore.fetch()
      await this.$store.dispatch('publications/fetchOnce')

      this.loading = false
    },

    methods: {
      save() {
        const { user } = this

        this.currentUserStore.update(user)
      }
    }
  }
</script>
