<template lang="pug">
input(
  type="checkbox"
  :value="uniqueKey"
  v-model="checked"
  @change="onChange($event.target.value)"
  @click="onClick($event.target.checked)"
)
</template>

<script>
  export default {
    props: {
      uniqueKey: {
        required: false
      },

      value: {
        type: Boolean,
        required: false
      }
    },

    data() {
      return {
        checkProxy: false
      };
    },

    computed: {
      checked: {
        get() { return this.value },
        set(val) { this.checkedProxy = val }
      },
    },

    methods: {
      onChange() {
        this.$emit('input', this.checkedProxy);
      },

      onClick(checked) {
        this.$emit('click', checked);
      }
    }
  }
</script>
