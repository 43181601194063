<template lang="pug">
.form-inline
  SelectInput(
    :value="value || defaultLength"
    @input="changePerPage"
    :options="options"
  )
</template>

<script>
  import { uniq } from 'lodash';
  import SelectInput from './select_input'

  const LENGTHS = [10, 25, 50, 100];
  const DEFAULT_LENGTH = LENGTHS[0];

  export default {
    components: {
      SelectInput
    },

    props: {
      value: {
        type: Number,
        required: false
      }
    },

    computed: {
      availableLengths() {
        return uniq(
          [...LENGTHS, this.value, this.siteDefaultLength]
        ).map(
          num => parseInt(num)
        ).filter(
          num => num > 0
        ).sort(
          (a, b) => a - b
        );
      },

      options() {
        const entries = this.availableLengths.map((len) => {
          return [this.labelFor(len), len];
        })

        return Object.fromEntries(entries);
      },

      defaultLength() {
        return DEFAULT_LENGTH;
      },

      siteDefaultLength() {
        return this.$store.getters['settings/perPageDefault'];
      }
    },

    mounted() {
      this.$store.dispatch('settings/fetchOnce');
    },

    methods: {
      changePerPage(newValue) {
        this.$emit('input', parseInt(newValue));
      },

      labelFor(length) {
        if (length === this.value) {
          return `Showing ${length} per page`;
        } else {
          return `Show ${length} per page`;
        }
      }
    }
  }
</script>
