<template lang="pug">
li.list-group-item(:class="styles")
  Checkbox.right-check(v-model="checked")
  i.fa.fa-newspaper-o
  |
  | {{ issue.title }}
</template>

<script>
  import Checkbox from '../../../shared/checkbox.vue'

  export default {
    components: {
      Checkbox
    },

    props: {
      value: {
        type: Object, // placement object
        required: true
      },
      issue: {
        type: Object,
        required: true
      }
    },

    computed: {
      placement() { return this.value },
      styles() {
        return {
          'list-group-item-info': (this.placement.page_id && this.checked),
          active: (!this.placement.page_id && this.checked)
        }
      },
      checked: {
        get() { return this.issue.id === this.placement.issue_id },
        set(val) {
          const issue_id = (val ? this.issue.id : null)

          this.$emit(
            'input',
            {
              ...this.placement,
              issue_id,
              page_id: null,
              _destroy: !issue_id
            }
          )
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .right-check {
    float: right;
    cursor: pointer;
  }
</style>
