import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  users: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ users }) {
    return users
  },

  allAsObject({ users }) {
    let obj = {}
    users.forEach(user => { obj[user.name] = user.id })
    return obj
  },

  forId: ({ users }) => (id) => {
    return users.find(user => user.id === id)
  }
}

// mutations

const mutations = make.mutations(state)

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get('/api/internal/users')
      .then(response => {
        commit('SET_USERS', response.data.users)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  },

  async fetchOnce({ dispatch, state: { fetchInitiated } }) {
    if (fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
