<template lang="pug">
div
  SingleFileLinkedTextField(v-model="inputValue" :input-class="inputClass" :read-only="readOnly")
  .row.buttons-row
    .col-sm-6
      slot
    .col-sm-6(v-if="perFieldFileMode")
      .btn-group.pull-right
        button.btn.btn-default.btn-sm(type="button" :class="{ active: editHereActive }" v-on:click="editHere()")
          i.fa.fa-pencil-square-o
          |
          | Edit Here
        label.btn.btn-default.btn-sm.pull-left(type="button" :class="{ active: editFileActive }" v-on:click="editFile()")
          i.fa.fa-file-text-o
          |
          | Edit File
          input(type="file" v-on:change="fileChosen($event)" style="display: none")
</template>

<script>
  import FileContentLinkedInput from '../../mixins/file_content_linked_input'
  import SingleFileLinkedTextField from './single_file_linked_text_field.vue'

  export default {
    mixins: [FileContentLinkedInput],

    components: {
      SingleFileLinkedTextField
    },

    props: {
      inputClass: {
        type: String,
        default: null
      }
    },

    methods: {
      contentFileSuccessHandler(data) {
        this.fileContent = data.content
        this.inputValue = data.content
        this.useFileContent = true
      }
    }
  }
</script>

<style>
  /*
    FIXME: this style block seems to be causing a `Cannot read property 'extend'
    of undefined` error until `shakapacker-dev-server` is restarted
  */

  .buttons-row {
    margin-top: 5px;
  }
</style>
