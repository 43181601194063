<template lang="pug">
.row
  .col-md-2

  .col-md-8
    h1 New Section Status

    Form(:squeue="squeue")

  .col-md-2
</template>

<script>
  import { sync } from 'vuex-pathify'

  import Form from '../shared/form.vue'

  export default {
    components: {
      Form
    },

    computed: {
      squeue: sync('squeue/squeue')
    },

    mounted() {
      this.$store.commit('squeue/RESET')
    }
  }
</script>
