<template lang="pug">
div
  header
    h1 Style Maps

  template(v-if="styleMaps.length === 0 && !showNewForm")
    .alert.alert-info No style maps were found
  template(v-else)
    table.table.table-striped
      thead
        tr
          th Name
          th Default
          th.text-right Actions
      tbody
        row(v-for="styleMap in styleMaps" :style-map="styleMap" :key="styleMap.id")

      tfoot(v-if="showNewForm")
        tr.form-group
          td
            input.form-control(type="text" v-model="form.name")
          td
            label
              input(type="checkbox" v-model="form.default")
              |
              | Default
          td.text-right
            button.btn.btn-success(@click="create()")
              i.fa.fa-save
              |
              | Save
            |
            |
            button.btn.btn-default(@click="showNewForm = false")
              i.fa.fa-ban
              |
              | Cancel


  div.text-right
    a.btn.btn-default.btn-success(
      :disabled="showNewForm"
      @click="showNewForm = true"
    )
      i.fa.fa-plus
      |
      | New Style Map
</template>

<script>
  import { sync } from 'vuex-pathify';
  import Row from './row.vue';

  export default {
    components: {
      Row
    },

    data() {
      return {
        showNewForm: false,
        form: {
          name: '',
          default: false
        }
      }
    },

    computed: {
      styleMaps: sync('styleMaps/styleMaps')
    },

    mounted() {
      this.$store.dispatch('styleMaps/fetchOnce');
    },

    methods: {
      create() {
        this.$store.dispatch('styleMaps/create', this.form).then(() => {
          this.showNewForm = false;
          this.form.name = '';
          this.form.default = false;
          this.$store.dispatch('styleMaps/fetch');
        })
      }
    }
  }
</script>
