import { defineStore } from 'pinia'
import axios from 'axios'
import { xor } from 'lodash'

import { getDefaultPaginationState } from '../store/shared/pagination'

export const useMediaSelectingStore = defineStore('mediaSelecting', {
  state: () => ({
    ...getDefaultPaginationState(),
    show: false,
    mediumIds: [],
    searchResults: {}
  }),

  getters: {
    selected({ media, selectedMediaIds }) {
      return media.filter((medium) => selectedMediaIds.includes(medium.id) )
    }
  },

  actions: {
    toggle(mediumId) {
      this.mediumIds = xor(this.mediumIds, [mediumId])
    },

    async search({ search, page, perPage, filterTypes }) {
      this.mediumIds = []

      return axios.get('/api/internal/media', {
        params: { search, page, per_page: perPage, types: filterTypes }
      }).then(({ data: { media, meta: { page }} }) => {
        this.searchResults = media
        this.page = page
      })
    }
  }
})
