<template lang="pug">
div
  header
    .pull-right
      router-link.btn.btn-default(to="/issues/new") New Issue
    h1 Issues

  LoaderOverlay(:loading="loading")
    template(v-if="issues.length < 1")
      .alert.alert-info No issues were found

    template(v-if="issues.length > 0")
      table.table.table-striped
        thead
          tr
            th.publish_on Publish Date
            th.title Title
            th.slug Slug
            th.publication Publication
            th.story_count Stories
            th.current Current
            th.actions &nbsp;
        tbody
          Row(v-for="issue in issues" :key="issue.id" :issue="issue")

      Pagination(
        :current="page.current"
        :per="page.per"
        :total-records="page.total_records"
        @change="changePage"
      )
</template>

<script>
import { sync } from 'vuex-pathify'

import LoaderOverlay from '../../../components/shared/loader_overlay.vue'
import Pagination from '../../../components/shared/pagination.vue'
import Row from './row.vue'

export default {
  components: {
    LoaderOverlay,
    Pagination,
    Row
  },

  data() {
    return {
      loading: true
    }
  },

  computed: {
    issues: sync('issues/issues'),
    page: sync('issues/page')
  },

  created() {
    this.$store.dispatch('issues/fetch').then(() => {
      this.loading = false
    })
  },

  methods: {
    changePage(page) {
      this.$store.dispatch('issues/fetch', { page })
    }
  }
}
</script>
