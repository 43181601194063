<template lang="pug">
article.edit
  CommunityQPublishModal
  StoryEmailModal

  form(v-on:submit.prevent="saveButtonAction")

    nav.story-header.container.sticky-top
      .row
        .col-md-6
          h1
            | Edit Story

        .col-md-6.text-right
          .btn-group
            button.btn.btn-success(type="button" :disabled="lockedToUser" v-on:click="saveButtonAction")
              i.fa(:class="saveButtonClass")
              |
              | {{ saveButtonText }}

            a.btn.btn-prominent.btn-info(@click="goBack()")
              i.fa.fa-arrow-left
              |
              | Back

            template(v-if="exportable")
              MoreButton(
                :story-id="storyId"
                :story-name="name"
                :without="['mark-published', 'duplicate', 'delete', 'divider']"
                :locked="lockedToUser"
              )

            template(v-if="!spiked")
              button.btn.btn-danger(type="button" :disabled="lockedToUser" @click.prevent="spikeStory")
                i.fa.fa-trash

      .row
        .col-md-6
          p.server-path
            i.fa.fa-hdd-o
            |
            |
            a(v-if="openFolderUrl" :href="openFolderUrl")
              | {{ contentPath }}
            span(v-else)
              | {{ contentPath }}
        .col-md-6.text-right
          p.updated-at(title='Last modified')
            i.fa.fa-clock-o
            |
            | {{ lastUpdated }}
    LockedOverlay(:story-id="storyId")
      .row
        .col-md-7
          LoaderOverlay(:loading="loading")
            .form-group(:class="{ 'has-error': hasError['slug'] }")
              label.control-label
                | Slug
                |
                span.required *
              Input(v-model="slug")

            .form-group
              label.control-label Overline
              SingleFileLinkedTextField(v-model="overline")

            .form-group
              label.control-label Headline
              FileContentLinkedField(content-for="headline" v-model="headline" class="headline-container" input-class="input-lg")

            .form-group
              label.control-label Deck head
              SingleFileLinkedTextField(v-model="dropHead")

            BylineField(v-model="byline")

          LoaderOverlay(:loading="loading")
            .form-group
              br
              FileContentLinkedWysiwyg(content-for="content" v-model="content" label="Story Body" :loading="loading")

          .form-group
            label.control-label Tag line
            RichtextEditor(v-model="tag" style="simple" height="100" :loading="loading" classes='form-control-sm')

          LoaderOverlay(:loading="loading")
            .panel.panel-default
              .panel-heading
                .row
                  .col-md-6
                    h4(style="margin-top: 7px") Breakout Box
                  .col-md-6.text-right
                    ShowHideButton.btn-sm(v-model="showInfobox")
              .panel-body(v-show="showInfobox")
                .form-group
                  label.control-label Headline
                  Input.input-lg(v-model="infoboxHeadline")
                .form-group
                  FileContentLinkedWysiwyg(content-for="infobox_text" v-model="infoboxText" label="Content" :height="150" :loading="loading")

            PullQuote(:story-id="storyId")

            .panel.panel-default
              .panel-heading
                .row
                  .col-md-6
                    h4(style="margin-top: 7px") Web
                  .col-md-6.text-right
                    ShowHideButton.btn-sm(v-model="showCommunityQ")
              .panel-body(v-show="showCommunityQ")
                .form-group
                  label.checkbox-inline
                    input(type="checkbox" v-model="featured")
                    |
                    | Featured
                  label.checkbox-inline
                    input(type="checkbox" v-model="ispublic")
                    |
                    | Free
                  label.checkbox-inline
                    input(type="checkbox" v-model="commentsDisabled")
                    |
                    | Comments disabled
                hr

                .form-group
                  label.control-label Alt web headline
                  Input.input-lg(v-model="webHead")

                .form-group
                  label.control-label Slug
                  Input(v-model="webSlug")

                .form-group
                  label.control-label Web lead or summary
                  TextareaInput(name="story[web_summary]" v-model="webSummary" rows="4")

                .form-group
                  label.control-label Web story content
                  RichtextEditor(name="story[web_body]" v-model="webBody" style="simple" height="150" :loading="loading" classes="form-control-lg")

                .form-group
                  label.control-label Keywords
                  Input(v-model="keywords")
                StoryPublications(:story-id="storyId")


        .col-md-5
          LoaderOverlay(:loading="loading")
            MediaIndex(:story-id="storyId")

            hr

          LoaderOverlay(:loading="loading")
            label.control-label Export Mode
            .btn-group.btn-group-justified
              .btn-group
                button.btn.btn-default(ref="fileModePerField" type="button" :class="{active: perFieldFileMode}" v-on:click="fileMode = 'per_field'" data-toggle="tooltip" data-placement="top" title="A separate file for each content field")
                  | File Per Field
              .btn-group
                button.btn.btn-default(ref="fileModeSingle" type="button" :class="{active: singleFileMode}" v-on:click="fileMode = 'single'" data-toggle="tooltip" data-placement="top" title="One file contains content for multiple fields")
                  | Single File

            div(v-if="singleFileMode" style="margin-top: 5px;")
              div(v-if="singleFilePresent")
                .input-group
                  span.input-group-addon
                    i.fa.fa-file-text
                  Input(:value="singleFile.medium.filename" :disabled="true")
                  span.input-group-btn
                    label.btn.btn-info(type="button")
                      i.fa.fa-cloud-upload
                      input(type="file" v-on:change="singleFileChosen($event)" style="display: none")

              div(v-else)
                .btn-group.new-single-file
                  button.btn.btn-success(type="button" v-if="masterTemplate" @click="generateNewSingleFile = true" :class="{ active: generateNewSingleFile}")
                    i.fa.fa-file-text
                    |
                    | Generate File
                  label.btn.btn-info(type="button"  @click="generateNewSingleFile = false" :class="{ active: !generateNewSingleFile}")
                    i.fa.fa-cloud-upload
                    |
                    | Attach File
                    input(type="file" v-on:change="singleFileChosen($event)" style="display: none")

            hr

            .row
              SqueueField.col-md-6(v-model="squeueId" :required="true")

              UserField.col-md-6(v-model="userId" :default="currentUser.id")

              SectionsField.col-md-6(v-model="sectionIds")

              WebCategoriesField.col-md-6(v-model="webCategoryIds")

          LoaderOverlay(:loading="loading")
            hr

            .form-group
              label.control-label Issues
              Placements(:story-id="storyId")

            .form-group
              label.control-label Pub date
              .form-inline
                DateField(v-model="pubDate")

            hr

            Locations(:story-id="storyId")

            hr

            .form-group
              label.control-label File storage locations
              .input-group
                .input-group-addon
                  i.fa.fa-hdd-o
                Input(:placeholder="contentPath || primaryContentPath" :readonly="true")
                .input-group-addon
                  i.fa.fa-lock
              CustomFolders(:story-id="storyId")

            hr

            .form-group
              label.control-label Notes
              TextareaInput(v-model="notes")

            template(v-if="currentUser.show_advert_fields")
              hr

              .form-group
                label.control-label Twitter Feed
                Input(v-model="twitterFeed")
              .form-group
                label.control-label Tweet
                Input(v-model="tweet")
              .form-group
                label.control-label Google Search Ad
                Input(v-model="googleSearchAd")
              .form-group
                label.control-label Outbrain Ad
                Input(v-model="outbrainAd")

              hr

            .form-group
              label.checkbox-inline
                input(type="checkbox" v-model="freelance")
                |
                | Freelance

              hr

            .form-group
              AutoExportSelectionsField
</template>

<script>
  // Utils
  import axios from 'axios';
  import { isEmpty } from 'lodash'
  import Vuex from 'vuex'
  import { sync } from 'vuex-pathify';
  import { mapStores, mapState } from 'pinia'
  import { useCurrentUserStore } from '../../../stores/current_user'
  import { storyRoute } from '../../../utils/route_helpers.js'
  import { stateFingerprint } from '../../../utils/state_helpers.js'
  import { formatDateTime } from '../../../utils/time_helpers.js'

  // Vue Components
  import AutoExportSelectionsField from './auto_export_selections_field.vue'
  import BylineField from './byline_field.vue'
  import CommunityQPublishModal from '../../community_q/publish_modal.vue'
  import CustomFolders from './custom_folders.vue'
  import DateField from '../../shared/date_field.vue'
  import FileContentLinkedField from './file_content_linked_field.vue'
  import FileContentLinkedWysiwyg from './file_content_linked_wysiwyg.vue'
  import Input from '../../shared/input.vue'
  import LoaderOverlay from '../../shared/loader_overlay.vue'
  import Locations from './locations.vue'
  import LockedOverlay from './locked_overlay.vue'
  import Placements from './placements/index.vue'
  import PullQuote from './pull_quote.vue'
  import RichtextEditor from '../../../components/shared/richtext_editor'
  import MediaIndex from './media/index.vue'
  import SectionsField from './sections_field.vue'
  import ShowHideButton from '../../show_hide_button.vue'
  import SingleFileLinkedTextField from './single_file_linked_text_field.vue'
  import SqueueField from '../../shared/squeue_field.vue'
  import StoryEmailModal from '../../stories/email_modal.vue'
  import StoryPublications from './story_publications.vue'
  import TextareaInput from '../../shared/textarea_input.vue'
  import UserField from './user_field.vue'
  import WebCategoriesField from './web_categories_field.vue'
  import MoreButton from '../actions/more_button.vue'

  const BASE64_URL_MATCHER = /^data:[^;]*;base64,.+/
  const UNLOAD_MSG = 'Sure you want to leave? Your changes will be lost.'

  export default {
    components: {
      AutoExportSelectionsField,
      BylineField,
      CommunityQPublishModal,
      CustomFolders,
      DateField,
      FileContentLinkedField,
      FileContentLinkedWysiwyg,
      Input,
      LoaderOverlay,
      Locations,
      LockedOverlay,
      MoreButton,
      Placements,
      PullQuote,
      RichtextEditor,
      MediaIndex,
      SectionsField,
      ShowHideButton,
      SingleFileLinkedTextField,
      SqueueField,
      StoryEmailModal,
      StoryPublications,
      TextareaInput,
      UserField,
      WebCategoriesField
    },

    props: {
      storyId: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        showMoreHeadlines: false,
        showInfobox: true,
        showCommunityQ: true,
        hasError: {},
        warnOnLeave: true,
        saveButtonHover: false,
        saving: false,
        recentlySaved: false,
        generateNewSingleFile: true,
        loading: true
      };
    },

    computed: {
      ...Vuex.mapState(
        'story', ['id', 'locker']
      ),
      ...Vuex.mapGetters(
        'story',
        [ 'lockedToUser' ]
      ),
      ...Vuex.mapGetters(
        'story', [
          'spiked',
          'contentPath',
          'openFolderUrl',
          'singleFileMode',
          'perFieldFileMode',
          'cleanFingerprint',
          'isDirty',
          'updatedAt',
        ]
      ),
      ...Vuex.mapGetters(
        'settings', ['masterTemplate']
      ),
      ...mapStores(useCurrentUserStore),
      ...mapState(useCurrentUserStore, ['currentUser']),

      name() {
        return this.headline ? this.headline : this.slug;
      },

      exportable() {
        return this.saved && !this.spiked
      },

      saveButtonText() {
        if(this.saving) {
          return 'Saving'
        } else if(this.recentlySaved) {
          return 'Done';
        } else {
          return 'Save';
        }
      },

      saveButtonClass() {
        return {
          "fa-spinner": this.saving,
          "fa-spin": this.saving,
          "fa-check": this.recentlySaved,
          "fa-save": (!this.recentlySaved)
        };
      },

      primaryContentPath() {
        // NOTE: this should match HasFolder::BASE_CONTENT_PATH in Ruby
        const baseContentPath = 'content';
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // in JS 0 == Jan
        let day = date.getDate();

        return `${baseContentPath}/${year}/${month}/${day}`;
      },

      singleFile() {
        return this.$store.getters['storyMedia/byContentFor']('multiple_fields');
      },

      singleFilePresent() {
        return !!(this.singleFile && (this.singleFile.id || this.singleFile.medium.url));
      },

      currentUserIsLocker() {
        if(!this.currentUser?.id) return false

        return this.currentUser.id === this.locker?.id
      },

      lastUpdated() {
        return formatDateTime(this.updatedAt);
      },

      // Vuex helpers //
      // TODO: DRY these up
      id: {
        get() { return this.$store.getters['story/id'] },
        set(val) { this.$store.commit('story/SET_ID', val) }
      },

      slug: sync('story/slug'),
      headline: sync('story/headline'),
      userId: sync('story/user_id'),
      squeueId: sync('story/squeue_id'),

      pubDate: {
        get() { return this.$store.getters['story/pubDate'] },
        // Convert null to "" because nulls will be ignored and we won't
        // be able to ever clear out the pubDate.
        set(val) { this.$store.commit('story/UPDATE_PUB_DATE', val || '') }
      },

      dropHead: sync('story/drop_head'),
      webHead: sync('story/web_head'),
      overline: sync('story/overline'),
      byline: sync('story/byline'),
      credits: sync('story/credits'),
      endnote: sync('story/endnote'),
      keywords: sync('story/keywords'),
      sectionIds: sync('story/section_ids'),
      notes: sync('story/notes'),
      webCategoryIds: sync('story/web_category_ids'),
      notes: sync('story/notes'),
      featured: sync('story/featured'),
      ispublic: sync('story/ispublic'),
      commentsDisabled: sync('story/comments_disabled'),
      freelance: sync('story/freelance'),
      content: sync('story/content'),
      webSummary: sync('story/web_summary'),
      webSlug: sync('story/web_slug'),
      webBody: sync('story/web_body'),
      tag: sync('story/tag'),
      infoboxHeadline: sync('story/infobox_headline'),
      infoboxText: sync('story/infobox_text'),
      editingAttachments: sync('story/editingAttachments'),
      saved: sync('story/saved'),

      fileMode: {
        get() { return this.$store.getters['story/fileMode'] },
        set(val) {
          this.$store.commit('story/UPDATE_FILE_MODE', val);

          // Also delete or undelete the single file that is powering the story
          if((val == 'single' || val == 'per_field') && this.singleFilePresent) {
            this.$store.commit(
              'storyMedia/updateDestroy',
              {
                key: this.singleFile.use_as_content_for,
                value: (val === 'per_field')
              }
            );
          }
        }
      },

      twitterFeed: sync('story/twitter_feed'),
      tweet: sync('story/tweet'),
      googleSearchAd: sync('story/google_search_ad'),
      outbrainAd: sync('story/outbrain_ad')
    },

    watch: {
      // Automatically show the infobox section if it has any content
      infoboxHeadline(val) { if(val) { this.showInfobox = true } },
      infoboxText(val) { if(val) { this.showInfobox = true } },

      // Automatically show the CommunityQ section if it has any content
      webHead(val) { if(val) { this.showCommunityQ = true } },
      webSummary(val) { if(val) { this.showCommunityQ = true } },
      webBody(val) { if(val) { this.showCommunityQ = true } },
      webCategoryIds(val) { if(!isEmpty(val)) { this.showCommunityQ = true } },
      webSlug(val) { if(!isEmpty(val)) { this.showCommunityQ = true } },
      keywords(val) { if(val) { this.showCommunityQ = true  }},

      // TODO: DRY this up between here and the storyMedium watcher in FileContentLinkedInput
      singleFile(storyMedium) {
        // TODO: this should probably centralized somewhere, maybe in Vuex
        if(!storyMedium) {
          this.fileContent = '';
        } else {
          const medium = storyMedium.medium;

          let postParams = new FormData();
          postParams.append('filename', medium.filename);

          if(BASE64_URL_MATCHER.test(medium.url)) {
            postParams.append('base64_url', medium.url);
          } else if(medium.url) {
            postParams.append('path', medium.url);
          } else {
            postParams.append('file', medium.file);
          }

          // TODO: generate this URL from a rails helper, if possible
          axios.post('/api/internal/content_file', postParams).then(({ data }) => {
            this.singleContentFileSuccessHandler(data);
          }).catch(err => {
            this.$store.dispatch('messages/addError', error);
          })
        }
      }
    },

    // This function is a Vue Router hook
    beforeRouteLeave(_to, _from, next) {
      const leave = (!this.askBeforeLeave() || confirm(UNLOAD_MSG))

      if(leave) {
        if(this.currentUserIsLocker) {
          this.$store.dispatch('story/unlock', { id: this.storyId })
        }

        next()
      } else {
        next(false)
      }
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.beforeUnloadHandler)
      window.addEventListener('beforeunload', this.unloadHandler)
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.unloadHandler)
      window.removeEventListener('beforeunload', this.beforeUnloadHandler)
    },

    async mounted() {
      this.$store.commit('story/RESET');

      if(this.storyId) {
        this.id = Number(this.storyId);
        await this.$store.dispatch('story/fetch', { id: this.storyId });
      } else {
        // If it's a new story, default the pub date to today
        this.pubDate = new Date();

        const { params: { issueId, pageId } } = this.$router.currentRoute
        if(pageId) {
          this.addPage({ issueId, pageId })
        } else if(issueId) {
          this.addIssue({ issueId })
        }
      }

      await this.currentUserStore.fetchOnce()

      jQuery([this.$refs.fileModePerField, this.$refs.fileModeSingle]).tooltip();

      this.loading = false
    },

    methods: {
      ...Vuex.mapActions('storyPlacements', ['addIssue', 'addPage']),

      singleFileChosen(event) {
        const fileInput = event.target
        const file = fileInput.files[0]

        this.$store.dispatch(
          'storyMedia/create',
          { file: file, useAsContentFor: 'multiple_fields' }
        )
      },

      singleContentFileSuccessHandler(data) {
        this.byline = data['byline'] || '';
        // TODO: fix content sometimes being HTML and sometimes benig plain
        this.content = data['html_content'] || data['content'] || '';
        this.credits = data['credits'] || '';
        this.headline = data['headline'] || '';
        this.overline = data['overline'] || '';
        this.dropHead = data['drop_head'] || '';
        this.infoboxText = data['infobox_text'] || '';
      },

      saveButtonAction(e) {
        if(this.saving) {
          // do nothing
        } else if (this.recentlySaved) {
          this.hasError = {};
          this.goBack();
        } else {
          this.save(e);
        }
      },

      save(e) {
        this.markAsSaving()

        return Promise.all([
          this.$store.dispatch('story/save'),
          this.$store.dispatch('storyPublications/updateAll'),
          (this.editingAttachments ? this.$store.dispatch('storyMedia/save'): null)
        ]).then(() => {
          this.markAsSaved()

          this.$store.dispatch('messages/addNotice', 'Story saved')
        }).catch(err => {
          if(e) e.preventDefault()
          this.saving = false
          this.$store.dispatch('messages/smartAdd', err, {root: true})
        })
      },

      markAsSaving() {
        this.$store.dispatch('messages/addNotice', 'Saving...')
        this.saving = true
      },

      markAsSaved() {
        this.hasError = {}

        this.editingAttachments = false

        // Change the "Save" button to "Done"
        this.recentlySaved = true
        this.saving = false

        // Swap the "Done" button back to "Save" in a few seconds
        setTimeout(() => { this.recentlySaved = false }, 3000)
      },

      goBack() {
        this.$router.push(storyRoute({
          storyRoute: 'stories',
          issueRoute: 'issue',
          pageRoute: 'issuePage',
          router: this.$router
        }))
      },

      spikeStory() {
        if(confirm(`Sure you want to spike "${this.name}"?`)) {
          this.warnOnLeave = false;

          axios.delete(
            `/api/internal/stories/${this.id}`
          ).then(() => { this.goBack() });
        }
      },

      askBeforeLeave() {
        const currentPrint = stateFingerprint(this.$store.getters['story/exceptFingerprintOmitted'])
        const dirty = (currentPrint !== this.cleanFingerprint)

        return (dirty && this.warnOnLeave)
      },

      beforeUnloadHandler(e) {
        if(this.askBeforeLeave()) {
          e.returnValue = UNLOAD_MSG;
          return UNLOAD_MSG;
        }

        return null;
      },

      unloadHandler() {
        if(!this.currentUserIsLocker) return

        this.$store.dispatch('story/unlockOnLeave')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-group.new-single-file {
    display: flex;

    .btn {
      flex: 1;
    }
  }

  .sticky-top {
    position: sticky;
    top: 0px;
    z-index: 3;
    background-color: white;
    padding-top: 1em;
  }
</style>
