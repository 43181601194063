<template lang="pug">
component.vue-label-root(v-bind:is="tag")
  | {{ text }}
</template>

<script>
  export default {
    props: {
      tag: {
        type: String,
        default: 'label'
      },
      text: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vue-label-root {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    user-select: none;
  }
</style>
