import axios from 'axios'
import { defineStore } from 'pinia'
import store from '../store'

const STORY_SOURCE_TYPE = 'Story'
const MEDIUM_SOURCE_TYPE = 'Medium'

export const useExportFormatsStore = defineStore('exportFormats', {
  state: () => ({
    exportFormats: [],
    fetchInitiated: false,
  }),

  getters: {
    forStories({ exportFormats }) {
      return exportFormats.filter(({ source_type }) => source_type == STORY_SOURCE_TYPE)
    },

    forMedia({ exportFormats }) {
      return exportFormats.filter(({ source_type }) => source_type == MEDIUM_SOURCE_TYPE)
    },

    allAsObject({ exportFormats }) {
      return exportFormats.reduce((obj, exportFormat) => {
        obj[exportFormat.name] = exportFormat.id
        return obj
      }, {})
    },

    forIds: ({ exportFormats }) => (ids) => {
      return exportFormats.filter(exportFormat => ids.includes(exportFormat.id))
    },

    fieldsOnlyAsObject({ exportFormats }) {
      return exportFormats.filter(
        format => !format.master_template
      ).reduce((obj, exportFormat) => {
        obj[exportFormat.name] = exportFormat.id
        return obj
      }, {})
    }
  },

  actions: {
    async fetch() {
      this.fetchInitiated = true

      return axios.get('/api/internal/export_formats')
        .then(response => {
          this.exportFormats = response.data.export_formats
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    },

    async fetchOnce() {
      if (this.fetchInitiated) return Promise.resolve()

      this.fetch()
    },
  },
})
