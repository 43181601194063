// Smart route to a story page based of the current route level
const storyRoute = ({ storyId, storyRoute, issueRoute, pageRoute, router }) => {
  const { params: { issueId, pageId } } = router.currentRoute
  const params = { params: { issueId, pageId, storyId } }

  if(pageId && pageRoute) {
    return { name: pageRoute, ...params }
  } else if(issueId && issueRoute) {
    return { name: issueRoute, ...params }
  } else {
    return { name: storyRoute, ...params }
  }
}

export {
  storyRoute
}
