<template lang="pug">
table.table.table-striped
  thead
    tr
      th.text-center Favorite
      th Title
      th Query
      th Default
      th(v-if="hotQueues") Hot queue
      th.actions
  tbody
    Row(
      v-for="storySavedSearch in storySavedSearchesStore.storySavedSearches"
      :story-saved-search="storySavedSearch"
      :key="storySavedSearch.id"
    )
</template>

<script>
  import { sync } from 'vuex-pathify';
  import { mapStores } from 'pinia'
  import { useCurrentUserStore } from '../../stores/current_user'
  import { useStorySavedSearchesStore } from '../../stores/story_saved_searches'

  import Row from './row.vue'

  export default {
    components: {
      Row
    },

    computed: {
      ...mapStores(
        useCurrentUserStore,
        useStorySavedSearchesStore
      ),
      hotQueues: sync('settings/hotQueues')
    },

    async created() {
      // Get the hotQueues setting
      await this.$store.dispatch('settings/fetchOnce')
      // Get the user's default_story_saved_search_id
      await this.currentUserStore.fetchOnce()
    }
  }
</script>
