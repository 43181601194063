<template lang="pug">
.flie-drop-overlay
  div(v-if="fileHover" @drop.prevent="overlayDropHandler" :class="cssClasses")
    .wrapper.text-center
      h1.indicator
        span.fa-stack.fa-lg
          i.fa.fa-cloud.fa-stack-2x
          i.fa.fa-arrow-up.fa-stack-1x.fa-inverse(v-if="!uploading")
          Loader.fa-inverse(v-if="uploading")
        br
        | Drop Files Here
      slot.description(name="description")
  slot
</template>

<script>
  import Loader from './loader.vue'

  export default {
    components: {
      Loader
    },

    data() {
      return {
        uploading: false,
        fileHover: false,
        dragCounter: 0
      }
    },

    mounted() {
      document.body.addEventListener('dragenter', this.dragEnterHandler)
      document.body.addEventListener('dragleave', this.dragLeaveHandler)
      document.body.addEventListener('dragover', this.noOp)
      document.body.addEventListener('drop', this.bodyDropHandler)
    },

    unmounted() {
      document.body.removeEventListener('dragenter', this.dragEnterHandler)
      document.body.removeEventListener('dragleave', this.dragLeaveHandler)
      document.body.removeEventListener('dragover', this.noOp)
      document.body.removeEventListener('drop', this.bodyDropHandler)
    },

    computed: {
      cssClasses() {
        if(!this.fileHover) return {}

        return { "show-drop-overlay": true }
      }
    },

    methods: {
      dragEnterHandler(e) {
        // preventDefault is needed to make drop detection work
        e.preventDefault()
        if (this.dragCounter++ === 0) { this.$data.fileHover = true }
      },

      noOp(e) {
        // preventDefault is needed to make drop detection work
        e.preventDefault()
      },

      dragLeaveHandler(e) {
        // preventDefault is needed to make drop detection work
        e.preventDefault()
        if (--this.dragCounter === 0) { this.$data.fileHover = false }
      },

      bodyDropHandler() {
        // This is here to handle the case where a file is dropped on the page,
        // but not on this component. If it was dropped on this component,
        // uploading would be true and reset() would be called after the upload
        // completes.
        if(!this.uploading) this.reset()
      },

      reset() {
        this.fileHover = false
        this.uploading = false
        this.dragCounter = 0
      },

      async overlayDropHandler(event) {
        const files = Array.from(event.dataTransfer.files)
        this.uploading = true

        await this.$listeners.drop?.(files)

        this.reset()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .flie-drop-overlay {
    position: relative;

    .show-drop-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
    }

    .wrapper {
      opacity: 0.7;
    }
  }

</style>
