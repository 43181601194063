<template lang="pug">
select.form-control(
  :value="value"
  @change="change($event.target.value)"
)
  option(value="" v-text="blankOptionName" v-if="blankOptionName")
  option(v-for="(value, name) in options" :value="value" v-text="name")
</template>

<script>
  export default {
    props: {
      value: null,
      // Format: { label: 'value' }
      options: {
        type: Object,
        default: () => { {} }
      },
      blankOptionName: {
        type: String,
        required: false
      }
    },

    methods: {
      change(value) {
        // Value comes across as a string because it comes from the HTML attribute value.
        // So we find the matching entry to get the right data type.
        const realValue =
          Object.values(this.options).find(
            val => String(val) === value
          )

        this.$emit('input', realValue);
      }
    }
  }
</script>
