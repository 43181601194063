<template lang="pug">
LoaderOverlay(:loading="loading")
  img(:class="imgClass" :src="src" :alt="alt" @load="onLoad")
</template>

<script>
  import LoaderOverlay from './loader_overlay.vue'

  export default {
    components: {
      LoaderOverlay
    },

    props: {
      src: {
        type: String,
        required: true
      },

      alt: {
        type: String,
        default: () => ''
      },

      imgClass: {
        type: String,
        required: false
      }
    },

    data() {
      return {
        loading: true
      }
    },

    watch: {
      src() {
        this.loading = true
      }
    },

    methods: {
      onLoad() {
        this.loading = false
      }
    }
  }
</script>
